@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blocklyEditableText[data-argument-type="date"] text,
g[data-argument-type="date"] text {
    fill: #FFFFFF;
}

.blocklyDatePicker .goog-date-picker-date.goog-date-picker-today {
    color: #03dac6 !important;
}

.blocklyDatePicker .goog-date-picker-date.goog-date-picker-selected {
    background: #03dac6 !important;
    color: white !important;
}

.search_bar-container {
    position: absolute;
    width: 200px;
    height: 48px;
    background-color: #16191D !important;
    z-index: 47;
    border-right: 1pt solid #2C333A;
    border-bottom: 1pt solid #2C333A;
    padding: 8px 16px 8px 12px;
    box-sizing: border-box;
}

.search_bar-container #search-bar {
    font-family: 'SourceSansProRegular';
    font-size: 14px;
    width: calc(100% - 32px - 50px);
    background-color: #16191D !important;
    height: 30px;
    padding: 0px 12px 0px 36px !important;
    outline: none;
    color: #E6EAF0;
    border: 1px solid #2C333A;
    border-radius: 6px;
}

.search_bar-container #search-bar:focus {
    color: #E6EAF0;
    border-color: #3884FF;
}
.search_bar-container #search-bar::-webkit-input-placeholder {
    color: #9FADBC;
}
.search_bar-container #search-bar::-moz-placeholder {
    color: #9FADBC;
}
.search_bar-container #search-bar:-ms-input-placeholder {
    color: #9FADBC;
}
.search_bar-container #search-bar::-ms-input-placeholder {
    color: #9FADBC;
}
.search_bar-container #search-bar::placeholder {
    color: #9FADBC;
}

.search_bar-container input:-internal-autofill-selected,
.search_bar-container input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px rgb(42, 43, 46) inset;
    -webkit-text-fill-color: #AAA !important;
    border: 1px solid rgb(42, 43, 46);
}

.search-category text {
    color: #AAA;
    font-size: 21px;
    font-weight: 700;
}

.search-icon-container {
    position: absolute;
    z-index: 999;
    left: 22px;
    top: 14px;
}

.search-icon-container > span:before {
    color: #9FADBC !important;
    font-size: 20px;
}

.search-box-container {
    width: calc(100% - 24px - 12px);
    height: 32px;
    border: 1px solid #2C333A;
    border-radius: 6px;
}

.expand-collapse-container {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 999;
    height: 24px;
    cursor: pointer;
}

.expand-collapse-container > span {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #3884FF;
    text-align: center;
    line-height: 24px !important;
    display: block;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
    -webkit-transition: 0.2s ease-in-out transform;
    transition: 0.2s ease-in-out transform;
}

.expand-collapse-container > span:before {
    color: #3884FF !important;
}

.expand-collapse-container.collapsed {
    right: 11px;
}

.expand-collapse-container.collapsed > span {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
.ErrorMessage{
    position:absolute;
    color:white;
    z-index: 41; /* in front of toolbox */
    margin:0 35%;
    width:30%;
    border-radius: 5px;
    top:0%;
    display: none;
    text-align: center;
    padding:5px;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    box-shadow: -2px 2px 2px lightgrey;

}

.ErrorMessage button{
    border-radius: 5px;
    border:none;
    padding:5px;
    margin:5px;
}

.enter{
    opacity: 0;
    display:block;
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%);
}

.enter-done{
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    top:5%;
    background-color: rgb(130,130,130);
    display:block;
}

.exit{
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    top:5%;
    background-color: rgb(130,130,130);
    display:block;
}

.exit-done{
    opacity: 0;
    top:0%;
    display:block;
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
@font-face {
    font-family: 'SourceSansProSemibold';
    src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot);
    src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot) format('embedded-opentype'),
    url(/static/media/sourcesanspro-semibold.66d6f332.woff) format('woff'),
    url(/static/media/sourcesanspro-semibold.5404fd04.ttf) format('truetype'),
    url(/static/media/sourcesanspro-semibold.80529202.svg#SourceSansProSemibold) format('svg');
}

#delete-block-dialog-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(79, 79, 79, 0.9);
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#delete-block-dialog-wrapper {
    width: 600px;
    height: 300px;
    border: 4px solid #2C2D2D;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
}

.delete-block-dialog-header {
    position: relative;
    background: #2C2D2D;
    color: white;
    text-align: center;
    height: 52px;
}

.delete-block-dialog-header h4 {
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    font-size: 20px;
    margin-top: 0;
    line-height: 50px;
    text-align: left;
    padding-left: 1em;
}

.delete-block-dialog-body {
    flex-grow: 1;
    justify-content: space-between;
    background: #2C2D2D;
    border-top: 1px solid #444444;
    padding: 20px;
    font-size: 1.1em;
    color: white;
}

.delete-block-dialog-footer {
    background: #2C2D2D;
    min-height: 60px;
}

.delete-block-dialog-footer .btn-cancel,
.delete-block-dialog-footer .btn-continue {
    background-color: #004ECC;
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    border: none;
    color: #E6EAF0;
    cursor: pointer;
    outline: none;
    padding: 5px;
    margin: 12px 18px;
    border-radius: 0.25rem;
    float: right;
    text-transform: uppercase;
    font-size: 14px;
    width: 150px;
    height: 36px;
    letter-spacing: 0.25px;
}

.delete-block-dialog-footer .btn-cancel {
    color: #8695A7;
    border: 1px solid hsla(0, 0%, 0%, 0.15);
    background-color: unset;
    float: right;
    margin-right: 1rem;
    width: 84px;
}

.delete-block-dialog-footer .btn-cancel:hover {
    color: #9FADBC;
}

.delete-block-dialog-footer .btn-continue:hover {
    background-color: #0C5FE4;
}


.toolboxTooltip {
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  position: absolute;
  background-color: #16191D;
  color: #E6EAF0;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, sans-serif;
  font-size: 8pt;
  padding:5px;
  left:34px;
  border-radius: 4px;
  padding:0px;
  height:0px;
  -webkit-transition: height 0.15s ease;
  transition: height 0.15s ease;
  z-index: 99;
  border: 1px solid #2C333A;
  box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, .15);
}
.toolboxTooltip::before {
   content: '';
   height: 8px;
   width: 8px;
   background: #16191D;
   position: absolute;
   top: 10px;
   border-top: 1px solid #2C333A;
   border-left: 1px solid #2C333A;
   margin-left: -15px;
   -webkit-transform: rotate(-45deg);
           transform: rotate(-45deg);
 }

.toolboxTooltip.open {
  padding: 7px 10px;
  height: auto;
  -webkit-transition: height 0.15s ease;
  transition: height 0.15s ease;
}

.actions-container {
  height: 50px;
  width: 100%;
  background: #054d7d;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 98;
}

.actions-container .btn,
.actions-container i {
  margin-right: 10px;
}

.actions-container i {
  color: white;
}

.actions #workspaceMountDiv {
  height: calc(100% - 50px);
}

.blocklyMainBackground {
  stroke-width: 0;
  fill: url(#backgroundimagepattern) !important;
}

.blocklyToolboxDiv {
  border-left: none !important;
}


.scrollbar-thumb{
  background-color: #454E59 !important;
}

.scrollbar-track{
  background-color: transparent !important;
}

.blocklyScrollbarHandle {
  fill: #454E59 !important;
}

.magnet-zone {
  pointer-events: none;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.32);
  transition: -webkit-transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.32);
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.32);
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.32), -webkit-transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.32);
  position: absolute;
  /* text-align: center; */
  bottom: 10px;
  padding: 10px 20px;
  left: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000;
          perspective: 1000;
  /* transform: translate3d(0,0,0); */
  /* transform: translate(calc(-50% + 90px), 200%) translateZ(0); */
}

.magnet-zone.overlap .magnet {
  -webkit-transform: scale(1.1) translateZ(0);
          transform: scale(1.1) translateZ(0);
}

.touching .circle {
  -webkit-transform: scale(0.9) translateZ(0);
          transform: scale(0.9) translateZ(0);
}

.moving .magnet-zone {
  -webkit-transform: translate(-113%, -30%) translateZ(0);
          transform: translate(-113%, -30%) translateZ(0);
}

.magnet {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 5px 8px rgba(0,0,0,0.3);
  position: relative;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.15);
  transition: -webkit-transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.15);
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.15);
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.15), -webkit-transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.15);
  -webkit-transform: scale(0.8) translateZ(0);
          transform: scale(0.8) translateZ(0);
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.magnet:after {
  -webkit-mask: url("/images/trash-icon.svg") no-repeat center;
          mask: url("/images/trash-icon.svg") no-repeat center;
  background-color: #5B6670;
  content: '';
  height: 25px;
  width: 25px;
  position: absolute;
}

.magnet.hover {
  background-color: #E32722;
}

.magnet.hover:after {
  background-color: white;
  -webkit-mask: url("/images/trash-open.svg") no-repeat center;
          mask: url("/images/trash-open.svg") no-repeat center;
}

.icon-save.hover {
  background-color: #E32722;
}

.icon-save.hover:after {
  background-color: white;
}

.moving-save .icon-save {
  -webkit-transform: translate(226%, -90%) translateZ(0);
          transform: translate(226%, -90%) translateZ(0);
}

.save-region {
  display: block;
  margin-top: 100px;
}

.save-dialog {
  z-index: 100;
  margin: 0 auto;
  width: 500px;
  height: 200px;
  background: red;
  position: absolute;
  display: none;
  left: 0;
  right: 0;
  box-shadow: 5px 5px 5px #BBB;
  border: 1px solid #054d7d;
}

#blocklyDiv {
  height: 200px;
  width: 300px;
  border: 1px solid darkred;
}

.delete-block-icon {
  -webkit-mask: url(/static/media/delete.40689dcf.svg) no-repeat center;
          mask: url(/static/media/delete.40689dcf.svg) no-repeat center;
  background-color: #5B6670;
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  cursor: pointer;
}

.action-buttons-container {
  position: absolute;
  right: 32px;
  width: 48px;
  height: auto;
  padding: 0px 12px 12px;
  box-sizing: border-box;
  background-color: #16191D;
  color: #E6EAF0;
  border: 1px solid #2C333A;
  box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .action-buttons-container .icon {
    height: 24px;
    width: 24px;
    display: block;
    margin-top: 12px;
    font-size: 22px;
    cursor: pointer;
    text-align: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .action-buttons-container .icon.disabled {
      cursor: default;
      opacity: 0.3;
      filter: alpha(opacity=30); }

.tooltip {
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, sans-serif;
  font-size: 8pt;
  position: absolute;
  background-color: #16191D;
  color: #E6EAF0;
  border: 1px solid #2C333A;
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  margin-right: 15px;
  box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, 0.15); }
  .tooltip::before {
    content: '';
    height: 8px;
    width: 8px;
    background: #16191D;
    position: absolute;
    right: 0px;
    top: 10px;
    border-top: 1px solid #2C333A;
    border-left: 1px solid #2C333A;
    margin-right: -5px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }

.action-buttons-separator {
  width: 24px;
  height: 1px;
  background: #2C333A;
  margin: 8px 0px;
  display: block; }
  .action-buttons-separator + span {
    margin-top: 0px !important; }

@font-face {
  font-family: 'SourceSansProSemibold';
  src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot);
  src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot) format("embedded-opentype"), url(/static/media/sourcesanspro-semibold.66d6f332.woff) format("woff"), url(/static/media/sourcesanspro-semibold.5404fd04.ttf) format("truetype"), url(/static/media/sourcesanspro-semibold.80529202.svg#SourceSansProSemibold) format("svg"); }

.modal-buttons-container {
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  background: white;
  height: 36px; }
  .modal-buttons-container .container-left {
    margin-left: 20px;
    flex: 1 1; }
  .modal-buttons-container .container-right {
    margin-right: 20px; }
  .modal-buttons-container .text-button {
    background: #1EAD8A;
    color: white;
    border: 0px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 22px;
    font-family: "SourceSansProSemibold", Arial, "Times New Roman", Verdana;
    display: inline;
    padding: 5px;
    width: 84px;
    height: 36px;
    border-radius: 3px;
    text-align: center; }
    .modal-buttons-container .text-button:hover {
      background: #0B705D; }
    .modal-buttons-container .text-button-cancel {
      color: black;
      background: white;
      width: 60px;
      padding: 5px; }
      .modal-buttons-container .text-button-cancel:hover {
        background: white; }
    .modal-buttons-container .text-button:focus {
      outline: none; }
  .modal-buttons-container a {
    text-decoration: none; }
    .modal-buttons-container a:first-of-type {
      margin-right: 30px; }

@font-face {
  font-family: 'SourceSansProSemibold';
  src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot);
  src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot) format("embedded-opentype"), url(/static/media/sourcesanspro-semibold.66d6f332.woff) format("woff"), url(/static/media/sourcesanspro-semibold.5404fd04.ttf) format("truetype"), url(/static/media/sourcesanspro-semibold.80529202.svg#SourceSansProSemibold) format("svg"); }

#save-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #16191D;
  color: #E6EAF0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center; }

#save-modal-container button:disabled {
  opacity: 0.63;
  pointer-events: none; }

.modal-wrapper {
  max-width: 800px;
  max-height: 550px;
  width: calc(100% - 100px);
  height: calc(100% - 30px);
  border: 1px solid #2C333A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  background: #16191D;
  color: #E6EAF0; }
  .modal-wrapper .container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; }
  .modal-wrapper-expanded {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; }

.modal-header {
  position: relative;
  display: block;
  min-height: 90px;
  max-height: 90px;
  text-align: left;
  padding-top: 6px;
  padding-left: 35px; }

.modal-header h4 {
  font-family: "SourceSansProSemibold", Arial, "Times New Roman", Verdana;
  font-size: 20px;
  margin-top: 0;
  line-height: 50px;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  padding-left: 35px; }

.modal-header .icon-active {
  width: 23px;
  height: 23px;
  margin: 30px auto;
  text-align: center;
  background-color: #004ECC;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='-6 -5 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23141e25;%7D%3C/style%3E%3C/defs%3E%3Cpath d='M19.68,2,3.4,18.28,2,26l7.72-1.4L26,8.32Zm4,6.32-13.95,14-4-4,14-13.95ZM4.82,19.7,8.3,23.18,4.05,24Z'/%3E%3C/svg%3E");
  display: inline-block; }

.modal-header .icon {
  width: 23px;
  height: 23px;
  margin: 30px auto;
  text-align: center;
  background: #16191D;
  border-radius: 50%;
  display: inline-block;
  color: #A8B2B7; }

.modal-header .text-description {
  color: #939393;
  font-size: 14px;
  margin: 10px;
  display: inline-block; }

.modal-header .text-description-active {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
  display: inline-block; }

.modal-header .straight-line {
  color: #444444;
  font-size: 14px;
  margin-right: 10px;
  display: inline-block; }

.modal-body {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.keywords-div, .description-div {
  width: 100%; }

#tagsArea {
  flex-grow: 1;
  padding: 15px 15px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
  border-top: 1px solid #2C333A; }
  #tagsArea .is-invalid ~ .invalid-feedback {
    display: block; }

#tagsArea p {
  font-size: 1.1em;
  margin: 10px 0; }

#tagsArea .footnote {
  font-size: 0.8em; }

#tagsArea input:not(.react-tagsinput-input),
#tagsArea textarea {
  font-size: 16px;
  font-family: inherit;
  padding: 6px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px; }

#tagsArea .react-tagsinput {
  width: 100%;
  height: 76px;
  box-sizing: border-box;
  border: 1px solid #2C333A;
  border-radius: 4px;
  padding: 6px;
  padding-top: 4px;
  padding-bottom: 0;
  font-family: inherit;
  overflow: auto;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #16191D; }
  #tagsArea .react-tagsinput--focused {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

#tagsArea .react-tagsinput-input {
  font-size: 16px;
  font-family: inherit;
  margin-bottom: 0;
  padding: 0;
  width: auto;
  white-space: nowrap;
  color: #E6EAF0; }

#tagsArea .react-tagsinput-tag {
  border-width: 1px;
  background-color: #16191D;
  border-radius: 2px;
  border: #2C333A;
  color: #E6EAF0;
  display: inline-block;
  font-family: inherit;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px; }

#tagsArea .react-tagsinput-remove {
  border-left: 1px solid #2C333A;
  margin-left: 4px;
  color: #E6EAF0; }
  #tagsArea .react-tagsinput-remove:hover, #tagsArea .react-tagsinput-remove:focus {
    color: #E6EAF0;
    text-decoration: none; }

#tagsArea #block-description {
  height: 88px;
  background-color: #16191D;
  color: #E6EAF0;
  border: 1px solid #2C333A; }

#tagsArea .col-md-6:first-of-type {
  padding-left: 0; }

#tagsArea .col-md-6:last-of-type {
  padding-right: 0; }

#tagsArea .row {
  padding-left: 5px;
  padding-right: 5px; }
  #tagsArea .row:first-of-type {
    margin-bottom: 10px; }

#tagsArea #libraries-dropdown {
  width: 100%;
  height: 40px;
  z-index: 10;
  outline: none; }
  #tagsArea #libraries-dropdown.is-invalid .inner__control {
    border: 1px solid #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    #tagsArea #libraries-dropdown.is-invalid .inner__control .inner__dropdown-indicator {
      visibility: hidden; }

#tagsArea #libraries-dropdown .inner__control {
  /*height: 40px;*/
  border: 1px solid #2C333A;
  border-radius: 4px;
  background-color: #16191D;
  color: #E6EAF0; }
  #tagsArea #libraries-dropdown .inner__control--is-focused {
    color: #E6EAF0;
    background-color: #16191D;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  #tagsArea #libraries-dropdown .inner__control--menu-is-open {
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25); }

#tagsArea #libraries-dropdown .inner__value-container,
#tagsArea #libraries-dropdown .inner__indicators {
  height: 38px; }

#tagsArea #libraries-dropdown .inner__placeholder {
  margin-top: 5px;
  line-height: 1em;
  top: 35%;
  font-size: 14px; }

#tagsArea #libraries-dropdown .inner__single-value {
  position: relative;
  height: calc(100% - 6px);
  color: #E6EAF0; }

#tagsArea #libraries-dropdown .inner__input {
  /*height: 38px;*/
  color: #E6EAF0; }

#tagsArea #libraries-dropdown .inner__menu {
  /*height: 38px;*/
  color: #E6EAF0; }

#tagsArea #libraries-dropdown .inner__input #react-select-2-input {
  /*height: 38px;*/ }

/* Save to library inner menu */
#tagsArea .css-1n7v3ny-option {
  background-color: #16191D; }

#tagsArea .css-26l3qy-menu {
  background-color: #16191D;
  border: 1px solid #2C333A;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

/* End */
#tagsArea textarea {
  font-family: inherit;
  height: 60px; }

#tagsArea select {
  font-family: inherit;
  font-size: 1em; }

#tagsArea #block-name-div {
  height: 60px;
  margin-bottom: auto; }

#tagsArea #block-name {
  height: 40px;
  background-color: #16191D;
  border-color: #2C333A;
  border-width: 1px;
  color: #E6EAF0; }
  #tagsArea #block-name.is-invalid {
    border: 1px solid #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

#tagsArea #save-to-library {
  height: 60px; }

.modal-body #saveBlockWorkspace {
  height: 100%;
  flex-grow: 1; }

.modal-body #saveBlockParams {
  background: #E6EAF0;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 150px;
  height: 100%; }

.save-button-flex-container {
  flex: 1 1;
  border-top: 1px solid #2C333A;
  margin-top: -1px;
  margin-left: 0;
  margin-right: 0;
  font-size: 1.1em;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #16191D;
  color: #E6EAF0; }
  .save-button-flex-container img {
    width: 60px; }

.save-button-flex-container:hover {
  background-color: #939393;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none; }

.save-button-flex-container:active {
  border: 1px solid #2C333A;
  background-color: #e9ebee;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none; }

.save-button-flex-container-content {
  position: relative;
  top: 60%;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%); }

.save-button-flex-container-content > .save-button-bold {
  font-size: 14px;
  line-height: 1.3em; }

.save-button-flex-container-content > p {
  margin-top: 0;
  margin-bottom: 0; }

.save-button-flex-container-content > img {
  margin-bottom: 5px;
  border-radius: 7px; }

#blocklyArea {
  height: 0; }

#scriptArea {
  height: 0;
  background: unset;
  border-top: 1px solid #2C333A; }
  #scriptArea .monaco-editor {
    height: 100% !important;
    width: 100% !important; }

.modal-header h3 {
  color: #E6EAF0;
  float: left;
  margin: 0;
  padding: 0; }

.modal-header .expand-icon-container {
  z-index: 10000;
  position: relative;
  float: right;
  margin-top: 4px;
  margin-right: 12px;
  padding: 3px;
  display: block;
  cursor: pointer; }

.modal-footer {
  min-height: 60px;
  padding: 0;
  border-top: 1px solid #2C333A;
  background: #16191D; }

.modal-footer .modal-buttons-container {
  background: unset; }

.modal-footer .modal-buttons-container .text-button {
  background-color: #004ECC; }
  .modal-footer .modal-buttons-container .text-button:hover {
    background-color: #0C5FE4; }

.modal-footer .modal-buttons-container .text-button-cancel {
  background-color: unset !important;
  width: 84px;
  color: #8695A7; }
  .modal-footer .modal-buttons-container .text-button-cancel:hover {
    color: #9FADBC; }

.close-modal-btn {
  color: #E6EAF0;
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin: 0; }

.close-modal-btn:hover {
  color: black; }

.btn-cancel, .btn-continue, .btn-continue-stepThree, .btn-back {
  background: coral;
  border: none;
  color: #E6EAF0;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  padding: 10px;
  border-radius: 0.25rem;
  text-transform: uppercase; }

.back-drop {
  background-color: rgba(48, 49, 48, 0.42);
  height: 100%;
  position: fixed;
  -webkit-transition: all 1.3s;
  transition: all 1.3s;
  width: 100%; }

.open-modal-btn {
  margin: 15px;
  padding: 10px;
  font-weight: bold; }

.keyword-tag {
  color: #E6EAF0;
  background-color: #054d7d;
  margin-right: 10px;
  padding: 5px 8px;
  border-radius: 10px;
  white-space: nowrap; }

.keyword-tag > a {
  margin-left: 5px; }

.keyword-tag > a:hover {
  cursor: pointer; }

#tags-wrapper {
  margin-bottom: 1.5em;
  width: 750px;
  line-height: 2.5em;
  word-wrap: break-word; }

.delete-input-icon {
  -webkit-filter: invert(27%) sepia(77%) saturate(10%) hue-rotate(345deg) brightness(95%) contrast(83%);
          filter: invert(27%) sepia(77%) saturate(10%) hue-rotate(345deg) brightness(95%) contrast(83%);
  height: 26px;
  width: 26px;
  padding-bottom: 25px;
  display: inline;
  cursor: pointer; }

.delete-input-icon-container {
  position: absolute; }

.delete-input-icon-container:hover .delete-input-icon-link:hover {
  cursor: pointer; }

@media screen and (max-height: 560px) {
  .save-button-flex-container img {
    height: 25px;
    width: 32px; }
  #tagsArea .react-tagsinput {
    height: 76px; }
  #tagsArea #block-description {
    height: 76px; }
  .keywords-div {
    width: 100%; }
  .description-div {
    width: 100%; } }

@media screen and (max-height: 560px) and (max-width: 768px) {
  .keywords-div, .description-div {
    width: 100%;
    padding: 0; } }

@media screen and (max-height: 450px) {
  .save-button-flex-container-content {
    vertical-align: middle; }
  .save-button-flex-container-content > .save-button-bold {
    font-size: 11px; } }

@media (max-width: 768px) {
  #tagsArea .col-md-6 {
    padding-left: 0;
    padding-right: 0; }
  #tagsArea .react-tagsinput {
    height: 76px; }
  #tagsArea #block-description {
    height: 64px; }
  #tagsArea .react-tagsinput-input {
    width: 80px; }
  #blocklyArea {
    display: flex;
    flex-direction: column; }
    #blocklyArea #saveBlockParams {
      height: 120px;
      width: 100%;
      bottom: 0;
      display: flex;
      flex-direction: row; }
      #blocklyArea #saveBlockParams .save-button-flex-container {
        flex: 1 1;
        width: 100%; }
    #blocklyArea #saveBlockWorkspace {
      width: 100%;
      flex-grow: 1; } }
  @media screen and (max-width: 768px) and (max-height: 560px) {
    #blocklyArea #saveBlockParams {
      height: 80px; }
    #blocklyArea #saveBlockParams p {
      font-size: 12px; }
    #blocklyArea .keywords-div, #blocklyArea .description-div {
      width: 100%;
      padding: 0; } }

.toast {
  left: calc(50% - 175px);
  background-color: white !important; }

#undoRedo {
  position: absolute;
  z-index: 10000;
  top: 488px !important; }
  #undoRedo p {
    margin-top: 7px;
    margin-bottom: 7px; }
  #undoRedo .undoRedoIcon {
    width: 16px;
    height: 16px;
    color: #5B6670;
    cursor: pointer; }

@font-face {
    font-family: 'SourceSansProSemibold';
    src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot);
    src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot) format('embedded-opentype'),
    url(/static/media/sourcesanspro-semibold.66d6f332.woff) format('woff'),
    url(/static/media/sourcesanspro-semibold.5404fd04.ttf) format('truetype'),
    url(/static/media/sourcesanspro-semibold.80529202.svg#SourceSansProSemibold) format('svg');
}

#delete-library-dialog-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(79, 79, 79, 0.9);
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#delete-library-dialog-wrapper {
    width: 600px;
    height: 300px;
    border: 4px solid #2C2D2D;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
}

.delete-library-dialog-header {
    position: relative;
    background: #2C2D2D;
    color: white;
    text-align: center;
    height: 52px;
}

.delete-library-dialog-header h4 {
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    font-size: 20px;
    margin-top: 0;
    line-height: 50px;
    text-align: left;
    padding-left: 1em;
}

.delete-library-dialog-body {
    flex-grow: 1;
    justify-content: space-between;
    background: #2C2D2D;
    border-top: 1px solid #444444;
    padding: 20px;
    font-size: 1.1em;
    color: white;
}

.delete-library-dialog-footer {
    background: #2C2D2D;
    min-height: 60px;
}

.delete-library-dialog-footer .btn-cancel,
.delete-library-dialog-footer .btn-continue {
    background-color: #004ECC;
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    border: none;
    color: #E6EAF0;
    cursor: pointer;
    outline: none;
    padding: 5px;
    margin: 12px 18px;
    border-radius: 0.25rem;
    float: right;
    text-transform: uppercase;
    font-size: 14px;
    width: 150px;
    height: 36px;
    letter-spacing: 0.25px;
}

.delete-library-dialog-footer .btn-cancel {
    color: #8695A7;
    border: 1px solid hsla(0, 0%, 0%, 0.15);
    background-color: unset;
    float: right;
    margin-right: 1rem;
    width: 84px;
}

.delete-library-dialog-footer .btn-cancel:hover {
    color: #9FADBC;
}

.delete-library-dialog-footer .btn-continue {
    background-color: #0C5FE4;
}

@font-face {
  font-family: 'SourceSansProSemibold';
  src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot);
  src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot) format("embedded-opentype"), url(/static/media/sourcesanspro-semibold.66d6f332.woff) format("woff"), url(/static/media/sourcesanspro-semibold.5404fd04.ttf) format("truetype"), url(/static/media/sourcesanspro-semibold.80529202.svg#SourceSansProSemibold) format("svg"); }

#save-block-type-error-dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(79, 79, 79, 0.9);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center; }

#save-block-type-error-dialog-wrapper {
  width: 800px;
  height: 550px;
  border: 4px solid #2C333A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px; }

.save-block-type-error-dialog-header {
  position: relative;
  background: #16191D;
  color: #E6EAF0;
  text-align: center;
  height: 52px; }

.save-block-type-error-dialog-header h4 {
  font-family: "SourceSansProSemibold", Arial, "Times New Roman", Verdana;
  font-size: 20px;
  margin-top: 0;
  line-height: 50px;
  text-align: left;
  padding-left: 1em; }

.save-block-type-error-dialog-body {
  flex-grow: 1;
  justify-content: space-between;
  background: #16191D;
  padding: 20px;
  font-size: 16px;
  color: #E6EAF0;
  border-top: 1px solid #2C333A; }

.save-block-type-error-dialog-body img {
  display: block;
  width: 75%;
  margin: 0 auto; }

.save-block-type-error-dialog-body #block-type-description {
  color: #E6EAF0;
  text-align: center; }

p#block-type-description {
  margin-bottom: 0; }

.save-block-type-error-dialog-body #block-type-description #block-type-description-space {
  width: 6em;
  display: inline-block; }

.save-block-type-error-dialog-footer {
  background: #16191D;
  min-height: 60px; }

.save-block-type-error-dialog-footer .btn-OK {
  background-color: #004ECC;
  font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
  border: none;
  color: #E6EAF0;
  cursor: pointer;
  outline: none;
  padding: 5px;
  margin: 12px 18px;
  border-radius: 0.25rem;
  float: right;
  text-transform: uppercase;
  font-size: 14px;
  width: 84px;
  height: 36px;
  letter-spacing: 0.25px; }

.save-block-type-error-dialog-footer .btn-OK:hover {
  background-color: #0C5FE4; }

@media screen and (max-height: 600px) {
  #save-block-type-error-dialog-container {
    height: 100vh; }
  #save-block-type-error-dialog-wrapper {
    height: 90%;
    overflow-y: hidden; }
  .save-block-type-error-dialog-body {
    padding-top: 10px;
    padding-bottom: 0; }
  .save-block-type-error-dialog-body img {
    width: calc(130vh - 200px); }
  .save-block-type-error-dialog-body #block-type-description #block-type-description-space {
    width: 15vh; } }

@media screen and (max-height: 500px) {
  .save-block-type-error-dialog-body {
    padding-top: 0;
    padding-bottom: 0; } }

@media screen and (max-height: 450px) {
  .save-block-type-error-dialog-body img {
    width: 400px; }
  .save-block-type-error-dialog-body {
    overflow-y: scroll; } }

@font-face {
  font-family: 'SourceSansProSemibold';
  src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot);
  src: url(/static/media/sourcesanspro-semibold.5a78c43a.eot) format("embedded-opentype"), url(/static/media/sourcesanspro-semibold.66d6f332.woff) format("woff"), url(/static/media/sourcesanspro-semibold.5404fd04.ttf) format("truetype"), url(/static/media/sourcesanspro-semibold.80529202.svg#SourceSansProSemibold) format("svg"); }

#save-rest-block-error-dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(79, 79, 79, 0.9);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center; }

#save-rest-block-error-dialog-wrapper {
  width: 550px;
  height: 230px;
  border: 1px solid #2C333A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px; }

.save-rest-block-error-dialog-header {
  height: 3.125rem;
  background: #16191D;
  color: #E6EAF0;
  text-align: center; }
  .save-rest-block-error-dialog-header h4 {
    margin: 0;
    line-height: 50px;
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    text-transform: uppercase; }

.save-rest-block-error-dialog-body {
  flex-grow: 1;
  justify-content: space-between;
  background: #16191D;
  padding: 20px;
  font-size: 16px;
  color: #E6EAF0;
  border-top: 1px solid #2C333A; }

.save-rest-block-error-dialog-footer {
  background: #16191D;
  min-height: 60px; }

.save-rest-block-error-dialog-footer .btn-OK {
  background-color: #004ECC;
  font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
  border: none;
  color: #E6EAF0;
  cursor: pointer;
  outline: none;
  padding: 5px;
  margin: 12px 18px;
  border-radius: 0.25rem;
  float: right;
  text-transform: uppercase;
  font-size: 14px;
  width: 84px;
  height: 36px;
  letter-spacing: 0.25px; }
  .save-rest-block-error-dialog-footer .btn-OK:hover {
    background-color: #0C5FE4; }

.modal-header.small {
  position: relative;
  display: block;
  min-height: 50px;
  max-height: 50px;
  background: #16191D;
  color: #E6EAF0;
  text-align: left;
  padding-top: 6px;
  padding-left: 35px; }

.quill-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin-bottom: 10px; }
  .quill-container .quill {
    background-color: #2C333A; }
  .quill-container .ql-toolbar.ql-snow {
    border-color: #2C333A;
    background-color: #21272B;
    flex-shrink: 0; }
  .quill-container .ql-container.ql-snow {
    border: none;
    flex: 1 1;
    overflow: hidden;
    display: flex;
    flex-direction: column; }
  .quill-container .ql-toolbar.ql-snow .ql-picker-label {
    color: #E6EAF0; }
  .quill-container .ql-toolbar.ql-snow .ql-stroke {
    stroke: #E6EAF0; }
  .quill-container .ql-toolbar.ql-snow .ql-fill {
    fill: #E6EAF0; }
  .quill-container .ql-editor {
    color: #E6EAF0;
    flex: 1 1;
    overflow-y: auto; }
  .quill-container .ql-editor.ql-blank::before {
    color: #E6EAF0;
    opacity: 0.6; }
  .quill-container .ql-tooltip {
    background-color: #21272B;
    color: #E6EAF0; }

.cancel-button {
  background-color: #16191D;
  border: none;
  color: #E6EAF0;
  font-size: 14;
  cursor: pointer; }

.cancel-button:hover {
  color: white; }

.save-button {
  background-color: #596573;
  border: none;
  border-radius: 3px;
  color: #E6EAF0;
  padding: 6px 15px;
  font-size: 14;
  cursor: pointer; }

.save-button-saving {
  background-color: #004ECC;
  border: none;
  border-radius: 3px;
  color: #E6EAF0;
  padding: 6px 15px;
  font-size: 14;
  cursor: pointer; }

.save-button-saving:hover {
  background-color: #0066FF; }

.popup-modal {
  z-index: 1;
  align-items: center;
  justify-content: center;
  background-color: #16191D;
  border-radius: 5px;
  display: flex;
  flex-direction: column; }

.container-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #E6EAF0;
  box-sizing: border-box; }

.popup-header {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1;
  padding: 15px; }

.header-div {
  flex-shrink: 0;
  margin-left: 15px; }

.close-icon {
  cursor: pointer;
  color: #E6EAF0;
  padding: 5px; }

.quill-container-div {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: calc(100% - 60px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column; }

.react-quill {
  height: calc(100% - 42px);
  display: flex;
  flex-direction: column;
  cursor: text; }

.label-div {
  display: flex;
  margin-top: 15px; }

.popup-label {
  font-size: 14px;
  color: #E6EAF0;
  margin-top: 2px; }

.editing-div {
  display: flex;
  margin-left: auto;
  grid-gap: 8px;
  gap: 8px; }

.custom-toolbar:not(.has-selection) .ql-link {
  display: none !important; }

.custom-toolbar.has-selection .ql-link {
  display: inline-block !important; }

#documentation {
    position: relative;
    height: 100%;
    /*min-width: 1380px;*/
    width: 100%;
}

#documentation h3{
    color:#0157aa
}
#documentation h2{
    font-size: 1.2em
}
#documentation .imageContainer img{
    border:1pt solid grey;
    margin: 10px;
    width: 100%;
}

#documentation .imageContainer{
    text-align: center;
    position:relative;
    background-color: #f9f9f9;
}

#documentation .imageContainer .img-sm {
    width: 250px;
}

#documentation .imageContainer .img-md {
    width: 280px;
}

#documentation .imageContainer .img-lg {
    width: 330px;
}

#documentation .imageContainer .img-xl {
    width: 400px;
}

#documentation .imageContainer .img-xxl {
    width: 4500px;
}

#documentation #title{
    display:block;
    position: fixed;
    height:60px;
    width:30%;
    background-color: #044d7c;
    color:white;
    margin:0;
    padding:0px;
    z-index: 9999;
}

#documentation #content{
    top:50px;
    display: flex;
    height: 100%;
}

#documentation #contents{
    top:50px;
    position: fixed;
    background-color: lightgray;
    width:30%;
    float:left;
    height:100%;
}

#documentation #material{
    top:50px;
    bottom:0px;
    /*left:240px;*/
    right:0px;
    position:absolute;
    padding: 0 20px;
    box-sizing: border-box;
    overflow:scroll;
    width:70%;
}

#documentation .section {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    width: calc(100% + 380px);
}

#documentation .img-right-align .imageContainer{
    right: -150px;
    display: flex;
}

#documentation .documentation-container > div {
    /*padding-top: 50px;*/
}

body,
#root,
.content-container,
.workspace,
#workspaceMountDiv {
  height: 100%;
  width: 100%;
  position: absolute; }

.scrollable {
  overflow: scroll; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

.hv-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* Blockly Custom HTML Blocks */
.customHtmlInputContainer {
  display: block;
  /* top: 175.55px; */
  /* direction: ltr; */
  width: 100%;
  height: calc(100% - 10px);
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  border-radius: 4.5px;
  border-color: #ff3355;
  left: 406.705px;
  -webkit-transition: box-shadow 0.25s ease 0s;
  transition: box-shadow 0.25s ease 0s;
  box-shadow: rgba(255, 255, 255, 0.3) 0px 0px 0px 4px;
  margin: 5px 0; }

.customHtmlInput {
  border: none;
  font-family: inherit;
  height: 100%;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  color: #575E75;
  font-weight: 500;
  border-radius: 4.5px;
  -webkit-transition: font-size 0.25s ease 0s;
  transition: font-size 0.25s ease 0s;
  font-size: 16pt; }

.customHtmlInputRemove {
  -webkit-mask: url(/images/trash-open.svg);
          mask: url(/images/trash-open.svg);
  position: absolute;
  background: #FF661A;
  -webkit-mask-repeat: no-repeat;
  width: 28px;
  height: 28px;
  top: -50px;
  left: 50%;
  margin-left: -12px;
  cursor: pointer;
  z-index: 1232321232321; }

.blocklyDraggable foreignObject {
  overflow: visible; }

.blocklyTreeRoot {
  padding: 0px !important; }

.blocklyTreeRoot div[aria-level='2'] .blocklyTreeLabel {
  padding-left: 15px; }

.blocklyTreeRow::after {
  -webkit-transition: -webkit-transform 0.15s ease;
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.blocklyTreeRoot div[aria-expanded=true] > .blocklyTreeRow::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.blocklyTreeIcon.inner-category {
  margin-left: 20px; }

.blocklyTreeIcon.inner-category::after {
  background: #151e25;
  height: 1.4rem;
  width: 1.4rem;
  font-size: 10px;
  color: white;
  border-radius: 50%;
  content: attr(data-normals);
  content: attr(data-initials);
  display: block; }

.bootstrap-override {
  /*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */ }
  .bootstrap-override :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
  .bootstrap-override *,
  .bootstrap-override *::before,
  .bootstrap-override *::after {
    box-sizing: border-box; }
  .bootstrap-override html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .bootstrap-override article, .bootstrap-override aside, .bootstrap-override figcaption, .bootstrap-override figure, .bootstrap-override footer, .bootstrap-override header, .bootstrap-override hgroup, .bootstrap-override main, .bootstrap-override nav, .bootstrap-override section {
    display: block; }
  .bootstrap-override body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff; }
  .bootstrap-override [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important; }
  .bootstrap-override hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible; }
  .bootstrap-override h1, .bootstrap-override h2, .bootstrap-override h3, .bootstrap-override h4, .bootstrap-override h5, .bootstrap-override h6 {
    margin-top: 0;
    margin-bottom: 0.5rem; }
  .bootstrap-override p {
    margin-top: 0;
    margin-bottom: 1rem; }
  .bootstrap-override abbr[title],
  .bootstrap-override abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none; }
  .bootstrap-override address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  .bootstrap-override ol,
  .bootstrap-override ul,
  .bootstrap-override dl {
    margin-top: 0;
    margin-bottom: 1rem; }
  .bootstrap-override ol ol,
  .bootstrap-override ul ul,
  .bootstrap-override ol ul,
  .bootstrap-override ul ol {
    margin-bottom: 0; }
  .bootstrap-override dt {
    font-weight: 700; }
  .bootstrap-override dd {
    margin-bottom: .5rem;
    margin-left: 0; }
  .bootstrap-override blockquote {
    margin: 0 0 1rem; }
  .bootstrap-override b,
  .bootstrap-override strong {
    font-weight: bolder; }
  .bootstrap-override small {
    font-size: 80%; }
  .bootstrap-override sub,
  .bootstrap-override sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  .bootstrap-override sub {
    bottom: -.25em; }
  .bootstrap-override sup {
    top: -.5em; }
  .bootstrap-override a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent; }
    .bootstrap-override a:hover {
      color: #0056b3;
      text-decoration: underline; }
  .bootstrap-override a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none; }
    .bootstrap-override a:not([href]):not([class]):hover {
      color: inherit;
      text-decoration: none; }
  .bootstrap-override pre,
  .bootstrap-override code,
  .bootstrap-override kbd,
  .bootstrap-override samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }
  .bootstrap-override pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar; }
  .bootstrap-override figure {
    margin: 0 0 1rem; }
  .bootstrap-override img {
    vertical-align: middle;
    border-style: none; }
  .bootstrap-override svg {
    overflow: hidden;
    vertical-align: middle; }
  .bootstrap-override table {
    border-collapse: collapse; }
  .bootstrap-override caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom; }
  .bootstrap-override th {
    text-align: inherit; }
  .bootstrap-override label {
    display: inline-block;
    margin-bottom: 0.5rem; }
  .bootstrap-override button {
    border-radius: 0; }
  .bootstrap-override button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .bootstrap-override input,
  .bootstrap-override button,
  .bootstrap-override select,
  .bootstrap-override optgroup,
  .bootstrap-override textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  .bootstrap-override button,
  .bootstrap-override input {
    overflow: visible; }
  .bootstrap-override button,
  .bootstrap-override select {
    text-transform: none; }
  .bootstrap-override [role="button"] {
    cursor: pointer; }
  .bootstrap-override select {
    word-wrap: normal; }
  .bootstrap-override button,
  .bootstrap-override [type="button"],
  .bootstrap-override [type="reset"],
  .bootstrap-override [type="submit"] {
    -webkit-appearance: button; }
  .bootstrap-override button:not(:disabled),
  .bootstrap-override [type="button"]:not(:disabled),
  .bootstrap-override [type="reset"]:not(:disabled),
  .bootstrap-override [type="submit"]:not(:disabled) {
    cursor: pointer; }
  .bootstrap-override button::-moz-focus-inner,
  .bootstrap-override [type="button"]::-moz-focus-inner,
  .bootstrap-override [type="reset"]::-moz-focus-inner,
  .bootstrap-override [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  .bootstrap-override input[type="radio"],
  .bootstrap-override input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0; }
  .bootstrap-override textarea {
    overflow: auto;
    resize: vertical; }
  .bootstrap-override fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  .bootstrap-override legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }
  .bootstrap-override progress {
    vertical-align: baseline; }
  .bootstrap-override [type="number"]::-webkit-inner-spin-button,
  .bootstrap-override [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  .bootstrap-override [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  .bootstrap-override [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  .bootstrap-override ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  .bootstrap-override output {
    display: inline-block; }
  .bootstrap-override summary {
    display: list-item;
    cursor: pointer; }
  .bootstrap-override template {
    display: none; }
  .bootstrap-override [hidden] {
    display: none !important; }
  .bootstrap-override h1, .bootstrap-override h2, .bootstrap-override h3, .bootstrap-override h4, .bootstrap-override h5, .bootstrap-override h6,
  .bootstrap-override .h1, .bootstrap-override .h2, .bootstrap-override .h3, .bootstrap-override .h4, .bootstrap-override .h5, .bootstrap-override .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2; }
  .bootstrap-override h1, .bootstrap-override .h1 {
    font-size: 2.5rem; }
  .bootstrap-override h2, .bootstrap-override .h2 {
    font-size: 2rem; }
  .bootstrap-override h3, .bootstrap-override .h3 {
    font-size: 1.75rem; }
  .bootstrap-override h4, .bootstrap-override .h4 {
    font-size: 1.5rem; }
  .bootstrap-override h5, .bootstrap-override .h5 {
    font-size: 1.25rem; }
  .bootstrap-override h6, .bootstrap-override .h6 {
    font-size: 1rem; }
  .bootstrap-override .lead {
    font-size: 1.25rem;
    font-weight: 300; }
  .bootstrap-override .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2; }
  .bootstrap-override .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .bootstrap-override .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .bootstrap-override .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .bootstrap-override hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .bootstrap-override small,
  .bootstrap-override .small {
    font-size: 80%;
    font-weight: 400; }
  .bootstrap-override mark,
  .bootstrap-override .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  .bootstrap-override .list-unstyled {
    padding-left: 0;
    list-style: none; }
  .bootstrap-override .list-inline {
    padding-left: 0;
    list-style: none; }
  .bootstrap-override .list-inline-item {
    display: inline-block; }
    .bootstrap-override .list-inline-item:not(:last-child) {
      margin-right: 0.5rem; }
  .bootstrap-override .initialism {
    font-size: 90%;
    text-transform: uppercase; }
  .bootstrap-override .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem; }
  .bootstrap-override .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d; }
    .bootstrap-override .blockquote-footer::before {
      content: "\2014\00A0"; }
  .bootstrap-override .img-fluid {
    max-width: 100%;
    height: auto; }
  .bootstrap-override .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto; }
  .bootstrap-override .figure {
    display: inline-block; }
  .bootstrap-override .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }
  .bootstrap-override .figure-caption {
    font-size: 90%;
    color: #6c757d; }
  .bootstrap-override code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word; }
    a > .bootstrap-override code {
      color: inherit; }
  .bootstrap-override kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem; }
    .bootstrap-override kbd kbd {
      padding: 0;
      font-size: 100%;
      font-weight: 700; }
  .bootstrap-override pre {
    display: block;
    font-size: 87.5%;
    color: #212529; }
    .bootstrap-override pre code {
      font-size: inherit;
      color: inherit;
      word-break: normal; }
  .bootstrap-override .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll; }
  .bootstrap-override .container,
  .bootstrap-override .container-fluid,
  .bootstrap-override .container-sm,
  .bootstrap-override .container-md,
  .bootstrap-override .container-lg,
  .bootstrap-override .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    .bootstrap-override .container, .bootstrap-override .container-sm {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .bootstrap-override .container, .bootstrap-override .container-sm, .bootstrap-override .container-md {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .bootstrap-override .container, .bootstrap-override .container-sm, .bootstrap-override .container-md, .bootstrap-override .container-lg {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .bootstrap-override .container, .bootstrap-override .container-sm, .bootstrap-override .container-md, .bootstrap-override .container-lg, .bootstrap-override .container-xl {
      max-width: 1140px; } }
  .bootstrap-override .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .bootstrap-override .no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .bootstrap-override .no-gutters > .col,
    .bootstrap-override .no-gutters > [class*="col-"] {
      padding-right: 0;
      padding-left: 0; }
  .bootstrap-override .col-1, .bootstrap-override .col-2, .bootstrap-override .col-3, .bootstrap-override .col-4, .bootstrap-override .col-5, .bootstrap-override .col-6, .bootstrap-override .col-7, .bootstrap-override .col-8, .bootstrap-override .col-9, .bootstrap-override .col-10, .bootstrap-override .col-11, .bootstrap-override .col-12, .bootstrap-override .col,
  .bootstrap-override .col-auto, .bootstrap-override .col-sm-1, .bootstrap-override .col-sm-2, .bootstrap-override .col-sm-3, .bootstrap-override .col-sm-4, .bootstrap-override .col-sm-5, .bootstrap-override .col-sm-6, .bootstrap-override .col-sm-7, .bootstrap-override .col-sm-8, .bootstrap-override .col-sm-9, .bootstrap-override .col-sm-10, .bootstrap-override .col-sm-11, .bootstrap-override .col-sm-12, .bootstrap-override .col-sm,
  .bootstrap-override .col-sm-auto, .bootstrap-override .col-md-1, .bootstrap-override .col-md-2, .bootstrap-override .col-md-3, .bootstrap-override .col-md-4, .bootstrap-override .col-md-5, .bootstrap-override .col-md-6, .bootstrap-override .col-md-7, .bootstrap-override .col-md-8, .bootstrap-override .col-md-9, .bootstrap-override .col-md-10, .bootstrap-override .col-md-11, .bootstrap-override .col-md-12, .bootstrap-override .col-md,
  .bootstrap-override .col-md-auto, .bootstrap-override .col-lg-1, .bootstrap-override .col-lg-2, .bootstrap-override .col-lg-3, .bootstrap-override .col-lg-4, .bootstrap-override .col-lg-5, .bootstrap-override .col-lg-6, .bootstrap-override .col-lg-7, .bootstrap-override .col-lg-8, .bootstrap-override .col-lg-9, .bootstrap-override .col-lg-10, .bootstrap-override .col-lg-11, .bootstrap-override .col-lg-12, .bootstrap-override .col-lg,
  .bootstrap-override .col-lg-auto, .bootstrap-override .col-xl-1, .bootstrap-override .col-xl-2, .bootstrap-override .col-xl-3, .bootstrap-override .col-xl-4, .bootstrap-override .col-xl-5, .bootstrap-override .col-xl-6, .bootstrap-override .col-xl-7, .bootstrap-override .col-xl-8, .bootstrap-override .col-xl-9, .bootstrap-override .col-xl-10, .bootstrap-override .col-xl-11, .bootstrap-override .col-xl-12, .bootstrap-override .col-xl,
  .bootstrap-override .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  .bootstrap-override .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bootstrap-override .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .bootstrap-override .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .bootstrap-override .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .bootstrap-override .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .bootstrap-override .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .bootstrap-override .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .bootstrap-override .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .bootstrap-override .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .bootstrap-override .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .bootstrap-override .col-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bootstrap-override .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .bootstrap-override .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .bootstrap-override .col-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bootstrap-override .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .bootstrap-override .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .bootstrap-override .col-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bootstrap-override .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .bootstrap-override .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .bootstrap-override .col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bootstrap-override .order-first {
    order: -1; }
  .bootstrap-override .order-last {
    order: 13; }
  .bootstrap-override .order-0 {
    order: 0; }
  .bootstrap-override .order-1 {
    order: 1; }
  .bootstrap-override .order-2 {
    order: 2; }
  .bootstrap-override .order-3 {
    order: 3; }
  .bootstrap-override .order-4 {
    order: 4; }
  .bootstrap-override .order-5 {
    order: 5; }
  .bootstrap-override .order-6 {
    order: 6; }
  .bootstrap-override .order-7 {
    order: 7; }
  .bootstrap-override .order-8 {
    order: 8; }
  .bootstrap-override .order-9 {
    order: 9; }
  .bootstrap-override .order-10 {
    order: 10; }
  .bootstrap-override .order-11 {
    order: 11; }
  .bootstrap-override .order-12 {
    order: 12; }
  .bootstrap-override .offset-1 {
    margin-left: 8.33333%; }
  .bootstrap-override .offset-2 {
    margin-left: 16.66667%; }
  .bootstrap-override .offset-3 {
    margin-left: 25%; }
  .bootstrap-override .offset-4 {
    margin-left: 33.33333%; }
  .bootstrap-override .offset-5 {
    margin-left: 41.66667%; }
  .bootstrap-override .offset-6 {
    margin-left: 50%; }
  .bootstrap-override .offset-7 {
    margin-left: 58.33333%; }
  .bootstrap-override .offset-8 {
    margin-left: 66.66667%; }
  .bootstrap-override .offset-9 {
    margin-left: 75%; }
  .bootstrap-override .offset-10 {
    margin-left: 83.33333%; }
  .bootstrap-override .offset-11 {
    margin-left: 91.66667%; }
  @media (min-width: 576px) {
    .bootstrap-override .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .bootstrap-override .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap-override .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap-override .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .bootstrap-override .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap-override .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    .bootstrap-override .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .bootstrap-override .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .bootstrap-override .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .bootstrap-override .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .bootstrap-override .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap-override .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .bootstrap-override .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .bootstrap-override .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap-override .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .bootstrap-override .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .bootstrap-override .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .bootstrap-override .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .bootstrap-override .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .bootstrap-override .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap-override .order-sm-first {
      order: -1; }
    .bootstrap-override .order-sm-last {
      order: 13; }
    .bootstrap-override .order-sm-0 {
      order: 0; }
    .bootstrap-override .order-sm-1 {
      order: 1; }
    .bootstrap-override .order-sm-2 {
      order: 2; }
    .bootstrap-override .order-sm-3 {
      order: 3; }
    .bootstrap-override .order-sm-4 {
      order: 4; }
    .bootstrap-override .order-sm-5 {
      order: 5; }
    .bootstrap-override .order-sm-6 {
      order: 6; }
    .bootstrap-override .order-sm-7 {
      order: 7; }
    .bootstrap-override .order-sm-8 {
      order: 8; }
    .bootstrap-override .order-sm-9 {
      order: 9; }
    .bootstrap-override .order-sm-10 {
      order: 10; }
    .bootstrap-override .order-sm-11 {
      order: 11; }
    .bootstrap-override .order-sm-12 {
      order: 12; }
    .bootstrap-override .offset-sm-0 {
      margin-left: 0; }
    .bootstrap-override .offset-sm-1 {
      margin-left: 8.33333%; }
    .bootstrap-override .offset-sm-2 {
      margin-left: 16.66667%; }
    .bootstrap-override .offset-sm-3 {
      margin-left: 25%; }
    .bootstrap-override .offset-sm-4 {
      margin-left: 33.33333%; }
    .bootstrap-override .offset-sm-5 {
      margin-left: 41.66667%; }
    .bootstrap-override .offset-sm-6 {
      margin-left: 50%; }
    .bootstrap-override .offset-sm-7 {
      margin-left: 58.33333%; }
    .bootstrap-override .offset-sm-8 {
      margin-left: 66.66667%; }
    .bootstrap-override .offset-sm-9 {
      margin-left: 75%; }
    .bootstrap-override .offset-sm-10 {
      margin-left: 83.33333%; }
    .bootstrap-override .offset-sm-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 768px) {
    .bootstrap-override .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .bootstrap-override .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap-override .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap-override .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .bootstrap-override .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap-override .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    .bootstrap-override .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .bootstrap-override .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .bootstrap-override .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .bootstrap-override .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .bootstrap-override .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap-override .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .bootstrap-override .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .bootstrap-override .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap-override .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .bootstrap-override .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .bootstrap-override .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .bootstrap-override .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .bootstrap-override .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .bootstrap-override .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap-override .order-md-first {
      order: -1; }
    .bootstrap-override .order-md-last {
      order: 13; }
    .bootstrap-override .order-md-0 {
      order: 0; }
    .bootstrap-override .order-md-1 {
      order: 1; }
    .bootstrap-override .order-md-2 {
      order: 2; }
    .bootstrap-override .order-md-3 {
      order: 3; }
    .bootstrap-override .order-md-4 {
      order: 4; }
    .bootstrap-override .order-md-5 {
      order: 5; }
    .bootstrap-override .order-md-6 {
      order: 6; }
    .bootstrap-override .order-md-7 {
      order: 7; }
    .bootstrap-override .order-md-8 {
      order: 8; }
    .bootstrap-override .order-md-9 {
      order: 9; }
    .bootstrap-override .order-md-10 {
      order: 10; }
    .bootstrap-override .order-md-11 {
      order: 11; }
    .bootstrap-override .order-md-12 {
      order: 12; }
    .bootstrap-override .offset-md-0 {
      margin-left: 0; }
    .bootstrap-override .offset-md-1 {
      margin-left: 8.33333%; }
    .bootstrap-override .offset-md-2 {
      margin-left: 16.66667%; }
    .bootstrap-override .offset-md-3 {
      margin-left: 25%; }
    .bootstrap-override .offset-md-4 {
      margin-left: 33.33333%; }
    .bootstrap-override .offset-md-5 {
      margin-left: 41.66667%; }
    .bootstrap-override .offset-md-6 {
      margin-left: 50%; }
    .bootstrap-override .offset-md-7 {
      margin-left: 58.33333%; }
    .bootstrap-override .offset-md-8 {
      margin-left: 66.66667%; }
    .bootstrap-override .offset-md-9 {
      margin-left: 75%; }
    .bootstrap-override .offset-md-10 {
      margin-left: 83.33333%; }
    .bootstrap-override .offset-md-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 992px) {
    .bootstrap-override .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .bootstrap-override .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap-override .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap-override .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .bootstrap-override .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap-override .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    .bootstrap-override .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .bootstrap-override .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .bootstrap-override .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .bootstrap-override .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .bootstrap-override .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap-override .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .bootstrap-override .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .bootstrap-override .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap-override .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .bootstrap-override .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .bootstrap-override .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .bootstrap-override .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .bootstrap-override .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .bootstrap-override .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap-override .order-lg-first {
      order: -1; }
    .bootstrap-override .order-lg-last {
      order: 13; }
    .bootstrap-override .order-lg-0 {
      order: 0; }
    .bootstrap-override .order-lg-1 {
      order: 1; }
    .bootstrap-override .order-lg-2 {
      order: 2; }
    .bootstrap-override .order-lg-3 {
      order: 3; }
    .bootstrap-override .order-lg-4 {
      order: 4; }
    .bootstrap-override .order-lg-5 {
      order: 5; }
    .bootstrap-override .order-lg-6 {
      order: 6; }
    .bootstrap-override .order-lg-7 {
      order: 7; }
    .bootstrap-override .order-lg-8 {
      order: 8; }
    .bootstrap-override .order-lg-9 {
      order: 9; }
    .bootstrap-override .order-lg-10 {
      order: 10; }
    .bootstrap-override .order-lg-11 {
      order: 11; }
    .bootstrap-override .order-lg-12 {
      order: 12; }
    .bootstrap-override .offset-lg-0 {
      margin-left: 0; }
    .bootstrap-override .offset-lg-1 {
      margin-left: 8.33333%; }
    .bootstrap-override .offset-lg-2 {
      margin-left: 16.66667%; }
    .bootstrap-override .offset-lg-3 {
      margin-left: 25%; }
    .bootstrap-override .offset-lg-4 {
      margin-left: 33.33333%; }
    .bootstrap-override .offset-lg-5 {
      margin-left: 41.66667%; }
    .bootstrap-override .offset-lg-6 {
      margin-left: 50%; }
    .bootstrap-override .offset-lg-7 {
      margin-left: 58.33333%; }
    .bootstrap-override .offset-lg-8 {
      margin-left: 66.66667%; }
    .bootstrap-override .offset-lg-9 {
      margin-left: 75%; }
    .bootstrap-override .offset-lg-10 {
      margin-left: 83.33333%; }
    .bootstrap-override .offset-lg-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 1200px) {
    .bootstrap-override .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .bootstrap-override .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap-override .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap-override .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .bootstrap-override .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap-override .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    .bootstrap-override .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .bootstrap-override .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .bootstrap-override .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .bootstrap-override .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .bootstrap-override .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap-override .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .bootstrap-override .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .bootstrap-override .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap-override .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .bootstrap-override .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .bootstrap-override .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .bootstrap-override .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .bootstrap-override .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .bootstrap-override .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap-override .order-xl-first {
      order: -1; }
    .bootstrap-override .order-xl-last {
      order: 13; }
    .bootstrap-override .order-xl-0 {
      order: 0; }
    .bootstrap-override .order-xl-1 {
      order: 1; }
    .bootstrap-override .order-xl-2 {
      order: 2; }
    .bootstrap-override .order-xl-3 {
      order: 3; }
    .bootstrap-override .order-xl-4 {
      order: 4; }
    .bootstrap-override .order-xl-5 {
      order: 5; }
    .bootstrap-override .order-xl-6 {
      order: 6; }
    .bootstrap-override .order-xl-7 {
      order: 7; }
    .bootstrap-override .order-xl-8 {
      order: 8; }
    .bootstrap-override .order-xl-9 {
      order: 9; }
    .bootstrap-override .order-xl-10 {
      order: 10; }
    .bootstrap-override .order-xl-11 {
      order: 11; }
    .bootstrap-override .order-xl-12 {
      order: 12; }
    .bootstrap-override .offset-xl-0 {
      margin-left: 0; }
    .bootstrap-override .offset-xl-1 {
      margin-left: 8.33333%; }
    .bootstrap-override .offset-xl-2 {
      margin-left: 16.66667%; }
    .bootstrap-override .offset-xl-3 {
      margin-left: 25%; }
    .bootstrap-override .offset-xl-4 {
      margin-left: 33.33333%; }
    .bootstrap-override .offset-xl-5 {
      margin-left: 41.66667%; }
    .bootstrap-override .offset-xl-6 {
      margin-left: 50%; }
    .bootstrap-override .offset-xl-7 {
      margin-left: 58.33333%; }
    .bootstrap-override .offset-xl-8 {
      margin-left: 66.66667%; }
    .bootstrap-override .offset-xl-9 {
      margin-left: 75%; }
    .bootstrap-override .offset-xl-10 {
      margin-left: 83.33333%; }
    .bootstrap-override .offset-xl-11 {
      margin-left: 91.66667%; } }
  .bootstrap-override .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529; }
    .bootstrap-override .table th,
    .bootstrap-override .table td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6; }
    .bootstrap-override .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6; }
    .bootstrap-override .table tbody + tbody {
      border-top: 2px solid #dee2e6; }
  .bootstrap-override .table-sm th,
  .bootstrap-override .table-sm td {
    padding: 0.3rem; }
  .bootstrap-override .table-bordered {
    border: 1px solid #dee2e6; }
    .bootstrap-override .table-bordered th,
    .bootstrap-override .table-bordered td {
      border: 1px solid #dee2e6; }
    .bootstrap-override .table-bordered thead th,
    .bootstrap-override .table-bordered thead td {
      border-bottom-width: 2px; }
  .bootstrap-override .table-borderless th,
  .bootstrap-override .table-borderless td,
  .bootstrap-override .table-borderless thead th,
  .bootstrap-override .table-borderless tbody + tbody {
    border: 0; }
  .bootstrap-override .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }
  .bootstrap-override .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075); }
  .bootstrap-override .table-primary,
  .bootstrap-override .table-primary > th,
  .bootstrap-override .table-primary > td {
    background-color: #b8daff; }
  .bootstrap-override .table-primary th,
  .bootstrap-override .table-primary td,
  .bootstrap-override .table-primary thead th,
  .bootstrap-override .table-primary tbody + tbody {
    border-color: #7abaff; }
  .bootstrap-override .table-hover .table-primary:hover {
    background-color: #9fcdff; }
    .bootstrap-override .table-hover .table-primary:hover > td,
    .bootstrap-override .table-hover .table-primary:hover > th {
      background-color: #9fcdff; }
  .bootstrap-override .table-secondary,
  .bootstrap-override .table-secondary > th,
  .bootstrap-override .table-secondary > td {
    background-color: #d6d8db; }
  .bootstrap-override .table-secondary th,
  .bootstrap-override .table-secondary td,
  .bootstrap-override .table-secondary thead th,
  .bootstrap-override .table-secondary tbody + tbody {
    border-color: #b3b7bb; }
  .bootstrap-override .table-hover .table-secondary:hover {
    background-color: #c8cbcf; }
    .bootstrap-override .table-hover .table-secondary:hover > td,
    .bootstrap-override .table-hover .table-secondary:hover > th {
      background-color: #c8cbcf; }
  .bootstrap-override .table-success,
  .bootstrap-override .table-success > th,
  .bootstrap-override .table-success > td {
    background-color: #c3e6cb; }
  .bootstrap-override .table-success th,
  .bootstrap-override .table-success td,
  .bootstrap-override .table-success thead th,
  .bootstrap-override .table-success tbody + tbody {
    border-color: #8fd19e; }
  .bootstrap-override .table-hover .table-success:hover {
    background-color: #b1dfbb; }
    .bootstrap-override .table-hover .table-success:hover > td,
    .bootstrap-override .table-hover .table-success:hover > th {
      background-color: #b1dfbb; }
  .bootstrap-override .table-info,
  .bootstrap-override .table-info > th,
  .bootstrap-override .table-info > td {
    background-color: #bee5eb; }
  .bootstrap-override .table-info th,
  .bootstrap-override .table-info td,
  .bootstrap-override .table-info thead th,
  .bootstrap-override .table-info tbody + tbody {
    border-color: #86cfda; }
  .bootstrap-override .table-hover .table-info:hover {
    background-color: #abdde5; }
    .bootstrap-override .table-hover .table-info:hover > td,
    .bootstrap-override .table-hover .table-info:hover > th {
      background-color: #abdde5; }
  .bootstrap-override .table-warning,
  .bootstrap-override .table-warning > th,
  .bootstrap-override .table-warning > td {
    background-color: #ffeeba; }
  .bootstrap-override .table-warning th,
  .bootstrap-override .table-warning td,
  .bootstrap-override .table-warning thead th,
  .bootstrap-override .table-warning tbody + tbody {
    border-color: #ffdf7e; }
  .bootstrap-override .table-hover .table-warning:hover {
    background-color: #ffe8a1; }
    .bootstrap-override .table-hover .table-warning:hover > td,
    .bootstrap-override .table-hover .table-warning:hover > th {
      background-color: #ffe8a1; }
  .bootstrap-override .table-danger,
  .bootstrap-override .table-danger > th,
  .bootstrap-override .table-danger > td {
    background-color: #f5c6cb; }
  .bootstrap-override .table-danger th,
  .bootstrap-override .table-danger td,
  .bootstrap-override .table-danger thead th,
  .bootstrap-override .table-danger tbody + tbody {
    border-color: #ed969e; }
  .bootstrap-override .table-hover .table-danger:hover {
    background-color: #f1b0b7; }
    .bootstrap-override .table-hover .table-danger:hover > td,
    .bootstrap-override .table-hover .table-danger:hover > th {
      background-color: #f1b0b7; }
  .bootstrap-override .table-light,
  .bootstrap-override .table-light > th,
  .bootstrap-override .table-light > td {
    background-color: #fdfdfe; }
  .bootstrap-override .table-light th,
  .bootstrap-override .table-light td,
  .bootstrap-override .table-light thead th,
  .bootstrap-override .table-light tbody + tbody {
    border-color: #fbfcfc; }
  .bootstrap-override .table-hover .table-light:hover {
    background-color: #ececf6; }
    .bootstrap-override .table-hover .table-light:hover > td,
    .bootstrap-override .table-hover .table-light:hover > th {
      background-color: #ececf6; }
  .bootstrap-override .table-dark,
  .bootstrap-override .table-dark > th,
  .bootstrap-override .table-dark > td {
    background-color: #c6c8ca; }
  .bootstrap-override .table-dark th,
  .bootstrap-override .table-dark td,
  .bootstrap-override .table-dark thead th,
  .bootstrap-override .table-dark tbody + tbody {
    border-color: #95999c; }
  .bootstrap-override .table-hover .table-dark:hover {
    background-color: #b9bbbe; }
    .bootstrap-override .table-hover .table-dark:hover > td,
    .bootstrap-override .table-hover .table-dark:hover > th {
      background-color: #b9bbbe; }
  .bootstrap-override .table-active,
  .bootstrap-override .table-active > th,
  .bootstrap-override .table-active > td {
    background-color: rgba(0, 0, 0, 0.075); }
  .bootstrap-override .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075); }
    .bootstrap-override .table-hover .table-active:hover > td,
    .bootstrap-override .table-hover .table-active:hover > th {
      background-color: rgba(0, 0, 0, 0.075); }
  .bootstrap-override .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55; }
  .bootstrap-override .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .bootstrap-override .table-dark {
    color: #fff;
    background-color: #343a40; }
    .bootstrap-override .table-dark th,
    .bootstrap-override .table-dark td,
    .bootstrap-override .table-dark thead th {
      border-color: #454d55; }
    .bootstrap-override .table-dark.table-bordered {
      border: 0; }
    .bootstrap-override .table-dark.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05); }
    .bootstrap-override .table-dark.table-hover tbody tr:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.075); }
  @media (max-width: 575.98px) {
    .bootstrap-override .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .bootstrap-override .table-responsive-sm > .table-bordered {
        border: 0; } }
  @media (max-width: 767.98px) {
    .bootstrap-override .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .bootstrap-override .table-responsive-md > .table-bordered {
        border: 0; } }
  @media (max-width: 991.98px) {
    .bootstrap-override .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .bootstrap-override .table-responsive-lg > .table-bordered {
        border: 0; } }
  @media (max-width: 1199.98px) {
    .bootstrap-override .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .bootstrap-override .table-responsive-xl > .table-bordered {
        border: 0; } }
  .bootstrap-override .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .bootstrap-override .table-responsive > .table-bordered {
      border: 0; }
  .bootstrap-override .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .form-control {
        -webkit-transition: none;
        transition: none; } }
    .bootstrap-override .form-control::-ms-expand {
      background-color: transparent;
      border: 0; }
    .bootstrap-override .form-control:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #495057; }
    .bootstrap-override .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .bootstrap-override .form-control::-webkit-input-placeholder {
      color: #6c757d;
      opacity: 1; }
    .bootstrap-override .form-control::-moz-placeholder {
      color: #6c757d;
      opacity: 1; }
    .bootstrap-override .form-control:-ms-input-placeholder {
      color: #6c757d;
      opacity: 1; }
    .bootstrap-override .form-control::-ms-input-placeholder {
      color: #6c757d;
      opacity: 1; }
    .bootstrap-override .form-control::placeholder {
      color: #6c757d;
      opacity: 1; }
    .bootstrap-override .form-control:disabled, .bootstrap-override .form-control[readonly] {
      background-color: #e9ecef;
      opacity: 1; }
  .bootstrap-override input[type="date"].form-control,
  .bootstrap-override input[type="time"].form-control,
  .bootstrap-override input[type="datetime-local"].form-control,
  .bootstrap-override input[type="month"].form-control {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
  .bootstrap-override select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff; }
  .bootstrap-override .form-control-file,
  .bootstrap-override .form-control-range {
    display: block;
    width: 100%; }
  .bootstrap-override .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }
  .bootstrap-override .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5; }
  .bootstrap-override .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5; }
  .bootstrap-override .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
    .bootstrap-override .form-control-plaintext.form-control-sm, .bootstrap-override .form-control-plaintext.form-control-lg {
      padding-right: 0;
      padding-left: 0; }
  .bootstrap-override .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  .bootstrap-override .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  .bootstrap-override select.form-control[size], .bootstrap-override select.form-control[multiple] {
    height: auto; }
  .bootstrap-override textarea.form-control {
    height: auto; }
  .bootstrap-override .form-group {
    margin-bottom: 1rem; }
  .bootstrap-override .form-text {
    display: block;
    margin-top: 0.25rem; }
  .bootstrap-override .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
    .bootstrap-override .form-row > .col,
    .bootstrap-override .form-row > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px; }
  .bootstrap-override .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }
  .bootstrap-override .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
    .bootstrap-override .form-check-input[disabled] ~ .form-check-label,
    .bootstrap-override .form-check-input:disabled ~ .form-check-label {
      color: #6c757d; }
  .bootstrap-override .form-check-label {
    margin-bottom: 0; }
  .bootstrap-override .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
    .bootstrap-override .form-check-inline .form-check-input {
      position: static;
      margin-top: 0;
      margin-right: 0.3125rem;
      margin-left: 0; }
  .bootstrap-override .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745; }
  .bootstrap-override .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem; }
  .was-validated .bootstrap-override:valid ~ .valid-feedback,
  .was-validated .bootstrap-override:valid ~ .valid-tooltip, .bootstrap-override.is-valid ~ .valid-feedback,
  .bootstrap-override.is-valid ~ .valid-tooltip {
    display: block; }
  .was-validated .bootstrap-override .form-control:valid, .bootstrap-override .form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated .bootstrap-override .form-control:valid:focus, .bootstrap-override .form-control.is-valid:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .bootstrap-override textarea.form-control:valid, .bootstrap-override textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .was-validated .bootstrap-override .custom-select:valid, .bootstrap-override .custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated .bootstrap-override .custom-select:valid:focus, .bootstrap-override .custom-select.is-valid:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .bootstrap-override .form-check-input:valid ~ .form-check-label, .bootstrap-override .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }
  .was-validated .bootstrap-override .form-check-input:valid ~ .valid-feedback,
  .was-validated .bootstrap-override .form-check-input:valid ~ .valid-tooltip, .bootstrap-override .form-check-input.is-valid ~ .valid-feedback,
  .bootstrap-override .form-check-input.is-valid ~ .valid-tooltip {
    display: block; }
  .was-validated .bootstrap-override .custom-control-input:valid ~ .custom-control-label, .bootstrap-override .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745; }
    .was-validated .bootstrap-override .custom-control-input:valid ~ .custom-control-label::before, .bootstrap-override .custom-control-input.is-valid ~ .custom-control-label::before {
      border-color: #28a745; }
  .was-validated .bootstrap-override .custom-control-input:valid:checked ~ .custom-control-label::before, .bootstrap-override .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57; }
  .was-validated .bootstrap-override .custom-control-input:valid:focus ~ .custom-control-label::before, .bootstrap-override .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .bootstrap-override .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .bootstrap-override .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #28a745; }
  .was-validated .bootstrap-override .custom-file-input:valid ~ .custom-file-label, .bootstrap-override .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745; }
  .was-validated .bootstrap-override .custom-file-input:valid:focus ~ .custom-file-label, .bootstrap-override .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .bootstrap-override .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545; }
  .bootstrap-override .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem; }
  .was-validated .bootstrap-override:invalid ~ .invalid-feedback,
  .was-validated .bootstrap-override:invalid ~ .invalid-tooltip, .bootstrap-override.is-invalid ~ .invalid-feedback,
  .bootstrap-override.is-invalid ~ .invalid-tooltip {
    display: block; }
  .was-validated .bootstrap-override .form-control:invalid, .bootstrap-override .form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated .bootstrap-override .form-control:invalid:focus, .bootstrap-override .form-control.is-invalid:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .bootstrap-override textarea.form-control:invalid, .bootstrap-override textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .was-validated .bootstrap-override .custom-select:invalid, .bootstrap-override .custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated .bootstrap-override .custom-select:invalid:focus, .bootstrap-override .custom-select.is-invalid:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .bootstrap-override .form-check-input:invalid ~ .form-check-label, .bootstrap-override .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }
  .was-validated .bootstrap-override .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .bootstrap-override .form-check-input:invalid ~ .invalid-tooltip, .bootstrap-override .form-check-input.is-invalid ~ .invalid-feedback,
  .bootstrap-override .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .was-validated .bootstrap-override .custom-control-input:invalid ~ .custom-control-label, .bootstrap-override .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545; }
    .was-validated .bootstrap-override .custom-control-input:invalid ~ .custom-control-label::before, .bootstrap-override .custom-control-input.is-invalid ~ .custom-control-label::before {
      border-color: #dc3545; }
  .was-validated .bootstrap-override .custom-control-input:invalid:checked ~ .custom-control-label::before, .bootstrap-override .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d; }
  .was-validated .bootstrap-override .custom-control-input:invalid:focus ~ .custom-control-label::before, .bootstrap-override .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .bootstrap-override .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .bootstrap-override .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dc3545; }
  .was-validated .bootstrap-override .custom-file-input:invalid ~ .custom-file-label, .bootstrap-override .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545; }
  .was-validated .bootstrap-override .custom-file-input:invalid:focus ~ .custom-file-label, .bootstrap-override .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .bootstrap-override .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
    .bootstrap-override .form-inline .form-check {
      width: 100%; }
    @media (min-width: 576px) {
      .bootstrap-override .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0; }
      .bootstrap-override .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0; }
      .bootstrap-override .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle; }
      .bootstrap-override .form-inline .form-control-plaintext {
        display: inline-block; }
      .bootstrap-override .form-inline .input-group,
      .bootstrap-override .form-inline .custom-select {
        width: auto; }
      .bootstrap-override .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0; }
      .bootstrap-override .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0; }
      .bootstrap-override .form-inline .custom-control {
        align-items: center;
        justify-content: center; }
      .bootstrap-override .form-inline .custom-control-label {
        margin-bottom: 0; } }
  .bootstrap-override .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .btn {
        -webkit-transition: none;
        transition: none; } }
    .bootstrap-override .btn:hover {
      color: #212529;
      text-decoration: none; }
    .bootstrap-override .btn:focus, .bootstrap-override .btn.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .bootstrap-override .btn.disabled, .bootstrap-override .btn:disabled {
      opacity: 0.65; }
    .bootstrap-override .btn:not(:disabled):not(.disabled) {
      cursor: pointer; }
  .bootstrap-override a.btn.disabled,
  .bootstrap-override fieldset:disabled a.btn {
    pointer-events: none; }
  .bootstrap-override .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .bootstrap-override .btn-primary:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc; }
    .bootstrap-override .btn-primary:focus, .bootstrap-override .btn-primary.focus {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
    .bootstrap-override .btn-primary.disabled, .bootstrap-override .btn-primary:disabled {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
    .bootstrap-override .btn-primary:not(:disabled):not(.disabled):active, .bootstrap-override .btn-primary:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf; }
      .bootstrap-override .btn-primary:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .bootstrap-override .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .bootstrap-override .btn-secondary:hover {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62; }
    .bootstrap-override .btn-secondary:focus, .bootstrap-override .btn-secondary.focus {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62;
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
    .bootstrap-override .btn-secondary.disabled, .bootstrap-override .btn-secondary:disabled {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
    .bootstrap-override .btn-secondary:not(:disabled):not(.disabled):active, .bootstrap-override .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-secondary.dropdown-toggle {
      color: #fff;
      background-color: #545b62;
      border-color: #4e555b; }
      .bootstrap-override .btn-secondary:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .bootstrap-override .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .bootstrap-override .btn-success:hover {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34; }
    .bootstrap-override .btn-success:focus, .bootstrap-override .btn-success.focus {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34;
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
    .bootstrap-override .btn-success.disabled, .bootstrap-override .btn-success:disabled {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
    .bootstrap-override .btn-success:not(:disabled):not(.disabled):active, .bootstrap-override .btn-success:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-success.dropdown-toggle {
      color: #fff;
      background-color: #1e7e34;
      border-color: #1c7430; }
      .bootstrap-override .btn-success:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-success:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .bootstrap-override .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .bootstrap-override .btn-info:hover {
      color: #fff;
      background-color: #138496;
      border-color: #117a8b; }
    .bootstrap-override .btn-info:focus, .bootstrap-override .btn-info.focus {
      color: #fff;
      background-color: #138496;
      border-color: #117a8b;
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
    .bootstrap-override .btn-info.disabled, .bootstrap-override .btn-info:disabled {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8; }
    .bootstrap-override .btn-info:not(:disabled):not(.disabled):active, .bootstrap-override .btn-info:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-info.dropdown-toggle {
      color: #fff;
      background-color: #117a8b;
      border-color: #10707f; }
      .bootstrap-override .btn-info:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-info:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .bootstrap-override .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .bootstrap-override .btn-warning:hover {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00; }
    .bootstrap-override .btn-warning:focus, .bootstrap-override .btn-warning.focus {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00;
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
    .bootstrap-override .btn-warning.disabled, .bootstrap-override .btn-warning:disabled {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
    .bootstrap-override .btn-warning:not(:disabled):not(.disabled):active, .bootstrap-override .btn-warning:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-warning.dropdown-toggle {
      color: #212529;
      background-color: #d39e00;
      border-color: #c69500; }
      .bootstrap-override .btn-warning:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-warning:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .bootstrap-override .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .bootstrap-override .btn-danger:hover {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130; }
    .bootstrap-override .btn-danger:focus, .bootstrap-override .btn-danger.focus {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
    .bootstrap-override .btn-danger.disabled, .bootstrap-override .btn-danger:disabled {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545; }
    .bootstrap-override .btn-danger:not(:disabled):not(.disabled):active, .bootstrap-override .btn-danger:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-danger.dropdown-toggle {
      color: #fff;
      background-color: #bd2130;
      border-color: #b21f2d; }
      .bootstrap-override .btn-danger:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-danger:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .bootstrap-override .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .bootstrap-override .btn-light:hover {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5; }
    .bootstrap-override .btn-light:focus, .bootstrap-override .btn-light.focus {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5;
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
    .bootstrap-override .btn-light.disabled, .bootstrap-override .btn-light:disabled {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    .bootstrap-override .btn-light:not(:disabled):not(.disabled):active, .bootstrap-override .btn-light:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-light.dropdown-toggle {
      color: #212529;
      background-color: #dae0e5;
      border-color: #d3d9df; }
      .bootstrap-override .btn-light:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-light:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .bootstrap-override .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .bootstrap-override .btn-dark:hover {
      color: #fff;
      background-color: #23272b;
      border-color: #1d2124; }
    .bootstrap-override .btn-dark:focus, .bootstrap-override .btn-dark.focus {
      color: #fff;
      background-color: #23272b;
      border-color: #1d2124;
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
    .bootstrap-override .btn-dark.disabled, .bootstrap-override .btn-dark:disabled {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
    .bootstrap-override .btn-dark:not(:disabled):not(.disabled):active, .bootstrap-override .btn-dark:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-dark.dropdown-toggle {
      color: #fff;
      background-color: #1d2124;
      border-color: #171a1d; }
      .bootstrap-override .btn-dark:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-dark:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .bootstrap-override .btn-outline-primary {
    color: #007bff;
    border-color: #007bff; }
    .bootstrap-override .btn-outline-primary:hover {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
    .bootstrap-override .btn-outline-primary:focus, .bootstrap-override .btn-outline-primary.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
    .bootstrap-override .btn-outline-primary.disabled, .bootstrap-override .btn-outline-primary:disabled {
      color: #007bff;
      background-color: transparent; }
    .bootstrap-override .btn-outline-primary:not(:disabled):not(.disabled):active, .bootstrap-override .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-outline-primary.dropdown-toggle {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
      .bootstrap-override .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-outline-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .bootstrap-override .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d; }
    .bootstrap-override .btn-outline-secondary:hover {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
    .bootstrap-override .btn-outline-secondary:focus, .bootstrap-override .btn-outline-secondary.focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
    .bootstrap-override .btn-outline-secondary.disabled, .bootstrap-override .btn-outline-secondary:disabled {
      color: #6c757d;
      background-color: transparent; }
    .bootstrap-override .btn-outline-secondary:not(:disabled):not(.disabled):active, .bootstrap-override .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-outline-secondary.dropdown-toggle {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
      .bootstrap-override .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-outline-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .bootstrap-override .btn-outline-success {
    color: #28a745;
    border-color: #28a745; }
    .bootstrap-override .btn-outline-success:hover {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
    .bootstrap-override .btn-outline-success:focus, .bootstrap-override .btn-outline-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
    .bootstrap-override .btn-outline-success.disabled, .bootstrap-override .btn-outline-success:disabled {
      color: #28a745;
      background-color: transparent; }
    .bootstrap-override .btn-outline-success:not(:disabled):not(.disabled):active, .bootstrap-override .btn-outline-success:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-outline-success.dropdown-toggle {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
      .bootstrap-override .btn-outline-success:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-outline-success:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-outline-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .bootstrap-override .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8; }
    .bootstrap-override .btn-outline-info:hover {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8; }
    .bootstrap-override .btn-outline-info:focus, .bootstrap-override .btn-outline-info.focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
    .bootstrap-override .btn-outline-info.disabled, .bootstrap-override .btn-outline-info:disabled {
      color: #17a2b8;
      background-color: transparent; }
    .bootstrap-override .btn-outline-info:not(:disabled):not(.disabled):active, .bootstrap-override .btn-outline-info:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-outline-info.dropdown-toggle {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8; }
      .bootstrap-override .btn-outline-info:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-outline-info:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-outline-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .bootstrap-override .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107; }
    .bootstrap-override .btn-outline-warning:hover {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
    .bootstrap-override .btn-outline-warning:focus, .bootstrap-override .btn-outline-warning.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
    .bootstrap-override .btn-outline-warning.disabled, .bootstrap-override .btn-outline-warning:disabled {
      color: #ffc107;
      background-color: transparent; }
    .bootstrap-override .btn-outline-warning:not(:disabled):not(.disabled):active, .bootstrap-override .btn-outline-warning:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-outline-warning.dropdown-toggle {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
      .bootstrap-override .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-outline-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .bootstrap-override .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545; }
    .bootstrap-override .btn-outline-danger:hover {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545; }
    .bootstrap-override .btn-outline-danger:focus, .bootstrap-override .btn-outline-danger.focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
    .bootstrap-override .btn-outline-danger.disabled, .bootstrap-override .btn-outline-danger:disabled {
      color: #dc3545;
      background-color: transparent; }
    .bootstrap-override .btn-outline-danger:not(:disabled):not(.disabled):active, .bootstrap-override .btn-outline-danger:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-outline-danger.dropdown-toggle {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545; }
      .bootstrap-override .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-outline-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .bootstrap-override .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa; }
    .bootstrap-override .btn-outline-light:hover {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    .bootstrap-override .btn-outline-light:focus, .bootstrap-override .btn-outline-light.focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
    .bootstrap-override .btn-outline-light.disabled, .bootstrap-override .btn-outline-light:disabled {
      color: #f8f9fa;
      background-color: transparent; }
    .bootstrap-override .btn-outline-light:not(:disabled):not(.disabled):active, .bootstrap-override .btn-outline-light:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-outline-light.dropdown-toggle {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
      .bootstrap-override .btn-outline-light:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-outline-light:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-outline-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .bootstrap-override .btn-outline-dark {
    color: #343a40;
    border-color: #343a40; }
    .bootstrap-override .btn-outline-dark:hover {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
    .bootstrap-override .btn-outline-dark:focus, .bootstrap-override .btn-outline-dark.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
    .bootstrap-override .btn-outline-dark.disabled, .bootstrap-override .btn-outline-dark:disabled {
      color: #343a40;
      background-color: transparent; }
    .bootstrap-override .btn-outline-dark:not(:disabled):not(.disabled):active, .bootstrap-override .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > .bootstrap-override .btn-outline-dark.dropdown-toggle {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
      .bootstrap-override .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .bootstrap-override .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
      .show > .bootstrap-override .btn-outline-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .bootstrap-override .btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none; }
    .bootstrap-override .btn-link:hover {
      color: #0056b3;
      text-decoration: underline; }
    .bootstrap-override .btn-link:focus, .bootstrap-override .btn-link.focus {
      text-decoration: underline; }
    .bootstrap-override .btn-link:disabled, .bootstrap-override .btn-link.disabled {
      color: #6c757d;
      pointer-events: none; }
  .bootstrap-override .btn-lg, .bootstrap-override .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  .bootstrap-override .btn-sm, .bootstrap-override .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  .bootstrap-override .btn-block {
    display: block;
    width: 100%; }
    .bootstrap-override .btn-block + .btn-block {
      margin-top: 0.5rem; }
  .bootstrap-override input[type="submit"].btn-block,
  .bootstrap-override input[type="reset"].btn-block,
  .bootstrap-override input[type="button"].btn-block {
    width: 100%; }
  .bootstrap-override .fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .fade {
        -webkit-transition: none;
        transition: none; } }
    .bootstrap-override .fade:not(.show) {
      opacity: 0; }
  .bootstrap-override .collapse:not(.show) {
    display: none; }
  .bootstrap-override .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .collapsing {
        -webkit-transition: none;
        transition: none; } }
  .bootstrap-override .dropup,
  .bootstrap-override .dropright,
  .bootstrap-override .dropdown,
  .bootstrap-override .dropleft {
    position: relative; }
  .bootstrap-override .dropdown-toggle {
    white-space: nowrap; }
    .bootstrap-override .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent; }
    .bootstrap-override .dropdown-toggle:empty::after {
      margin-left: 0; }
  .bootstrap-override .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
  .bootstrap-override .dropdown-menu-left {
    right: auto;
    left: 0; }
  .bootstrap-override .dropdown-menu-right {
    right: 0;
    left: auto; }
  @media (min-width: 576px) {
    .bootstrap-override .dropdown-menu-sm-left {
      right: auto;
      left: 0; }
    .bootstrap-override .dropdown-menu-sm-right {
      right: 0;
      left: auto; } }
  @media (min-width: 768px) {
    .bootstrap-override .dropdown-menu-md-left {
      right: auto;
      left: 0; }
    .bootstrap-override .dropdown-menu-md-right {
      right: 0;
      left: auto; } }
  @media (min-width: 992px) {
    .bootstrap-override .dropdown-menu-lg-left {
      right: auto;
      left: 0; }
    .bootstrap-override .dropdown-menu-lg-right {
      right: 0;
      left: auto; } }
  @media (min-width: 1200px) {
    .bootstrap-override .dropdown-menu-xl-left {
      right: auto;
      left: 0; }
    .bootstrap-override .dropdown-menu-xl-right {
      right: 0;
      left: auto; } }
  .bootstrap-override .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem; }
  .bootstrap-override .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  .bootstrap-override .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  .bootstrap-override .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem; }
  .bootstrap-override .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  .bootstrap-override .dropright .dropdown-toggle:empty::after {
    margin-left: 0; }
  .bootstrap-override .dropright .dropdown-toggle::after {
    vertical-align: 0; }
  .bootstrap-override .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem; }
  .bootstrap-override .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  .bootstrap-override .dropleft .dropdown-toggle::after {
    display: none; }
  .bootstrap-override .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  .bootstrap-override .dropleft .dropdown-toggle:empty::after {
    margin-left: 0; }
  .bootstrap-override .dropleft .dropdown-toggle::before {
    vertical-align: 0; }
  .bootstrap-override .dropdown-menu[x-placement^="top"], .bootstrap-override .dropdown-menu[x-placement^="right"], .bootstrap-override .dropdown-menu[x-placement^="bottom"], .bootstrap-override .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto; }
  .bootstrap-override .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef; }
  .bootstrap-override .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
    .bootstrap-override .dropdown-item:hover, .bootstrap-override .dropdown-item:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #f8f9fa; }
    .bootstrap-override .dropdown-item.active, .bootstrap-override .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: #007bff; }
    .bootstrap-override .dropdown-item.disabled, .bootstrap-override .dropdown-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: transparent; }
  .bootstrap-override .dropdown-menu.show {
    display: block; }
  .bootstrap-override .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap; }
  .bootstrap-override .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529; }
  .bootstrap-override .btn-group,
  .bootstrap-override .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; }
    .bootstrap-override .btn-group > .btn,
    .bootstrap-override .btn-group-vertical > .btn {
      position: relative;
      flex: 1 1 auto; }
      .bootstrap-override .btn-group > .btn:hover,
      .bootstrap-override .btn-group-vertical > .btn:hover {
        z-index: 1; }
      .bootstrap-override .btn-group > .btn:focus, .bootstrap-override .btn-group > .btn:active, .bootstrap-override .btn-group > .btn.active,
      .bootstrap-override .btn-group-vertical > .btn:focus,
      .bootstrap-override .btn-group-vertical > .btn:active,
      .bootstrap-override .btn-group-vertical > .btn.active {
        z-index: 1; }
  .bootstrap-override .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .bootstrap-override .btn-toolbar .input-group {
      width: auto; }
  .bootstrap-override .btn-group > .btn:not(:first-child),
  .bootstrap-override .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .bootstrap-override .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .bootstrap-override .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .bootstrap-override .btn-group > .btn:not(:first-child),
  .bootstrap-override .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .bootstrap-override .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem; }
    .bootstrap-override .dropdown-toggle-split::after,
    .dropup .bootstrap-override .dropdown-toggle-split::after,
    .dropright .bootstrap-override .dropdown-toggle-split::after {
      margin-left: 0; }
    .dropleft .bootstrap-override .dropdown-toggle-split::before {
      margin-right: 0; }
  .bootstrap-override .btn-sm + .dropdown-toggle-split, .bootstrap-override .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem; }
  .bootstrap-override .btn-lg + .dropdown-toggle-split, .bootstrap-override .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .bootstrap-override .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    .bootstrap-override .btn-group-vertical > .btn,
    .bootstrap-override .btn-group-vertical > .btn-group {
      width: 100%; }
    .bootstrap-override .btn-group-vertical > .btn:not(:first-child),
    .bootstrap-override .btn-group-vertical > .btn-group:not(:first-child) {
      margin-top: -1px; }
    .bootstrap-override .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
    .bootstrap-override .btn-group-vertical > .btn-group:not(:last-child) > .btn {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .bootstrap-override .btn-group-vertical > .btn:not(:first-child),
    .bootstrap-override .btn-group-vertical > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  .bootstrap-override .btn-group-toggle > .btn,
  .bootstrap-override .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0; }
    .bootstrap-override .btn-group-toggle > .btn input[type="radio"],
    .bootstrap-override .btn-group-toggle > .btn input[type="checkbox"],
    .bootstrap-override .btn-group-toggle > .btn-group > .btn input[type="radio"],
    .bootstrap-override .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none; }
  .bootstrap-override .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
    .bootstrap-override .input-group > .form-control,
    .bootstrap-override .input-group > .form-control-plaintext,
    .bootstrap-override .input-group > .custom-select,
    .bootstrap-override .input-group > .custom-file {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0; }
      .bootstrap-override .input-group > .form-control + .form-control,
      .bootstrap-override .input-group > .form-control + .custom-select,
      .bootstrap-override .input-group > .form-control + .custom-file,
      .bootstrap-override .input-group > .form-control-plaintext + .form-control,
      .bootstrap-override .input-group > .form-control-plaintext + .custom-select,
      .bootstrap-override .input-group > .form-control-plaintext + .custom-file,
      .bootstrap-override .input-group > .custom-select + .form-control,
      .bootstrap-override .input-group > .custom-select + .custom-select,
      .bootstrap-override .input-group > .custom-select + .custom-file,
      .bootstrap-override .input-group > .custom-file + .form-control,
      .bootstrap-override .input-group > .custom-file + .custom-select,
      .bootstrap-override .input-group > .custom-file + .custom-file {
        margin-left: -1px; }
    .bootstrap-override .input-group > .form-control:focus,
    .bootstrap-override .input-group > .custom-select:focus,
    .bootstrap-override .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
      z-index: 3; }
    .bootstrap-override .input-group > .custom-file .custom-file-input:focus {
      z-index: 4; }
    .bootstrap-override .input-group > .form-control:not(:last-child),
    .bootstrap-override .input-group > .custom-select:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .bootstrap-override .input-group > .form-control:not(:first-child),
    .bootstrap-override .input-group > .custom-select:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .bootstrap-override .input-group > .custom-file {
      display: flex;
      align-items: center; }
      .bootstrap-override .input-group > .custom-file:not(:last-child) .custom-file-label,
      .bootstrap-override .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .bootstrap-override .input-group > .custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
  .bootstrap-override .input-group-prepend,
  .bootstrap-override .input-group-append {
    display: flex; }
    .bootstrap-override .input-group-prepend .btn,
    .bootstrap-override .input-group-append .btn {
      position: relative;
      z-index: 2; }
      .bootstrap-override .input-group-prepend .btn:focus,
      .bootstrap-override .input-group-append .btn:focus {
        z-index: 3; }
    .bootstrap-override .input-group-prepend .btn + .btn,
    .bootstrap-override .input-group-prepend .btn + .input-group-text,
    .bootstrap-override .input-group-prepend .input-group-text + .input-group-text,
    .bootstrap-override .input-group-prepend .input-group-text + .btn,
    .bootstrap-override .input-group-append .btn + .btn,
    .bootstrap-override .input-group-append .btn + .input-group-text,
    .bootstrap-override .input-group-append .input-group-text + .input-group-text,
    .bootstrap-override .input-group-append .input-group-text + .btn {
      margin-left: -1px; }
  .bootstrap-override .input-group-prepend {
    margin-right: -1px; }
  .bootstrap-override .input-group-append {
    margin-left: -1px; }
  .bootstrap-override .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
    .bootstrap-override .input-group-text input[type="radio"],
    .bootstrap-override .input-group-text input[type="checkbox"] {
      margin-top: 0; }
  .bootstrap-override .input-group-lg > .form-control:not(textarea),
  .bootstrap-override .input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px); }
  .bootstrap-override .input-group-lg > .form-control,
  .bootstrap-override .input-group-lg > .custom-select,
  .bootstrap-override .input-group-lg > .input-group-prepend > .input-group-text,
  .bootstrap-override .input-group-lg > .input-group-append > .input-group-text,
  .bootstrap-override .input-group-lg > .input-group-prepend > .btn,
  .bootstrap-override .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  .bootstrap-override .input-group-sm > .form-control:not(textarea),
  .bootstrap-override .input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px); }
  .bootstrap-override .input-group-sm > .form-control,
  .bootstrap-override .input-group-sm > .custom-select,
  .bootstrap-override .input-group-sm > .input-group-prepend > .input-group-text,
  .bootstrap-override .input-group-sm > .input-group-append > .input-group-text,
  .bootstrap-override .input-group-sm > .input-group-prepend > .btn,
  .bootstrap-override .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  .bootstrap-override .input-group-lg > .custom-select,
  .bootstrap-override .input-group-sm > .custom-select {
    padding-right: 1.75rem; }
  .bootstrap-override .input-group > .input-group-prepend > .btn,
  .bootstrap-override .input-group > .input-group-prepend > .input-group-text,
  .bootstrap-override .input-group > .input-group-append:not(:last-child) > .btn,
  .bootstrap-override .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .bootstrap-override .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .bootstrap-override .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .bootstrap-override .input-group > .input-group-append > .btn,
  .bootstrap-override .input-group > .input-group-append > .input-group-text,
  .bootstrap-override .input-group > .input-group-prepend:not(:first-child) > .btn,
  .bootstrap-override .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .bootstrap-override .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .bootstrap-override .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .bootstrap-override .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem; }
  .bootstrap-override .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem; }
  .bootstrap-override .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0; }
    .bootstrap-override .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #007bff;
      background-color: #007bff; }
    .bootstrap-override .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .bootstrap-override .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #80bdff; }
    .bootstrap-override .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      color: #fff;
      background-color: #b3d7ff;
      border-color: #b3d7ff; }
    .bootstrap-override .custom-control-input[disabled] ~ .custom-control-label, .bootstrap-override .custom-control-input:disabled ~ .custom-control-label {
      color: #6c757d; }
      .bootstrap-override .custom-control-input[disabled] ~ .custom-control-label::before, .bootstrap-override .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #e9ecef; }
  .bootstrap-override .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top; }
    .bootstrap-override .custom-control-label::before {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      background-color: #fff;
      border: #adb5bd solid 1px; }
    .bootstrap-override .custom-control-label::after {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: no-repeat 50% / 50% 50%; }
  .bootstrap-override .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem; }
  .bootstrap-override .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }
  .bootstrap-override .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff; }
  .bootstrap-override .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
  .bootstrap-override .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  .bootstrap-override .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  .bootstrap-override .custom-radio .custom-control-label::before {
    border-radius: 50%; }
  .bootstrap-override .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .bootstrap-override .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  .bootstrap-override .custom-switch {
    padding-left: 2.25rem; }
    .bootstrap-override .custom-switch .custom-control-label::before {
      left: -2.25rem;
      width: 1.75rem;
      pointer-events: all;
      border-radius: 0.5rem; }
    .bootstrap-override .custom-switch .custom-control-label::after {
      top: calc(0.25rem + 2px);
      left: calc(-2.25rem + 2px);
      width: calc(1rem - 4px);
      height: calc(1rem - 4px);
      background-color: #adb5bd;
      border-radius: 0.5rem;
      -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .bootstrap-override .custom-switch .custom-control-label::after {
          -webkit-transition: none;
          transition: none; } }
    .bootstrap-override .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #fff;
      -webkit-transform: translateX(0.75rem);
              transform: translateX(0.75rem); }
    .bootstrap-override .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5); }
  .bootstrap-override .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .bootstrap-override .custom-select:focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .bootstrap-override .custom-select:focus::-ms-value {
        color: #495057;
        background-color: #fff; }
    .bootstrap-override .custom-select[multiple], .bootstrap-override .custom-select[size]:not([size="1"]) {
      height: auto;
      padding-right: 0.75rem;
      background-image: none; }
    .bootstrap-override .custom-select:disabled {
      color: #6c757d;
      background-color: #e9ecef; }
    .bootstrap-override .custom-select::-ms-expand {
      display: none; }
    .bootstrap-override .custom-select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #495057; }
  .bootstrap-override .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem; }
  .bootstrap-override .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem; }
  .bootstrap-override .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0; }
  .bootstrap-override .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0; }
    .bootstrap-override .custom-file-input:focus ~ .custom-file-label {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .bootstrap-override .custom-file-input[disabled] ~ .custom-file-label,
    .bootstrap-override .custom-file-input:disabled ~ .custom-file-label {
      background-color: #e9ecef; }
    .bootstrap-override .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: "Browse"; }
    .bootstrap-override .custom-file-input ~ .custom-file-label[data-browse]::after {
      content: attr(data-browse); }
  .bootstrap-override .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
    .bootstrap-override .custom-file-label::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      height: calc(1.5em + 0.75rem);
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      color: #495057;
      content: "Browse";
      background-color: #e9ecef;
      border-left: inherit;
      border-radius: 0 0.25rem 0.25rem 0; }
  .bootstrap-override .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .bootstrap-override .custom-range:focus {
      outline: none; }
      .bootstrap-override .custom-range:focus::-webkit-slider-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .bootstrap-override .custom-range:focus::-moz-range-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .bootstrap-override .custom-range:focus::-ms-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .bootstrap-override .custom-range::-moz-focus-outer {
      border: 0; }
    .bootstrap-override .custom-range::-webkit-slider-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: -0.25rem;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      -webkit-appearance: none;
              appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        .bootstrap-override .custom-range::-webkit-slider-thumb {
          -webkit-transition: none;
          transition: none; } }
      .bootstrap-override .custom-range::-webkit-slider-thumb:active {
        background-color: #b3d7ff; }
    .bootstrap-override .custom-range::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem; }
    .bootstrap-override .custom-range::-moz-range-thumb {
      width: 1rem;
      height: 1rem;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      -moz-appearance: none;
           appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        .bootstrap-override .custom-range::-moz-range-thumb {
          -webkit-transition: none;
          transition: none; } }
      .bootstrap-override .custom-range::-moz-range-thumb:active {
        background-color: #b3d7ff; }
    .bootstrap-override .custom-range::-moz-range-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem; }
    .bootstrap-override .custom-range::-ms-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: 0;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        .bootstrap-override .custom-range::-ms-thumb {
          -webkit-transition: none;
          transition: none; } }
      .bootstrap-override .custom-range::-ms-thumb:active {
        background-color: #b3d7ff; }
    .bootstrap-override .custom-range::-ms-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: transparent;
      border-color: transparent;
      border-width: 0.5rem; }
    .bootstrap-override .custom-range::-ms-fill-lower {
      background-color: #dee2e6;
      border-radius: 1rem; }
    .bootstrap-override .custom-range::-ms-fill-upper {
      margin-right: 15px;
      background-color: #dee2e6;
      border-radius: 1rem; }
    .bootstrap-override .custom-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .bootstrap-override .custom-range:disabled::-webkit-slider-runnable-track {
      cursor: default; }
    .bootstrap-override .custom-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }
    .bootstrap-override .custom-range:disabled::-moz-range-track {
      cursor: default; }
    .bootstrap-override .custom-range:disabled::-ms-thumb {
      background-color: #adb5bd; }
  .bootstrap-override .custom-control-label::before,
  .bootstrap-override .custom-file-label,
  .bootstrap-override .custom-select {
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .custom-control-label::before,
      .bootstrap-override .custom-file-label,
      .bootstrap-override .custom-select {
        -webkit-transition: none;
        transition: none; } }
  .bootstrap-override .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .bootstrap-override .nav-link {
    display: block;
    padding: 0.5rem 1rem; }
    .bootstrap-override .nav-link:hover, .bootstrap-override .nav-link:focus {
      text-decoration: none; }
    .bootstrap-override .nav-link.disabled {
      color: #6c757d;
      pointer-events: none;
      cursor: default; }
  .bootstrap-override .nav-tabs {
    border-bottom: 1px solid #dee2e6; }
    .bootstrap-override .nav-tabs .nav-item {
      margin-bottom: -1px; }
    .bootstrap-override .nav-tabs .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
      .bootstrap-override .nav-tabs .nav-link:hover, .bootstrap-override .nav-tabs .nav-link:focus {
        border-color: #e9ecef #e9ecef #dee2e6; }
      .bootstrap-override .nav-tabs .nav-link.disabled {
        color: #6c757d;
        background-color: transparent;
        border-color: transparent; }
    .bootstrap-override .nav-tabs .nav-link.active,
    .bootstrap-override .nav-tabs .nav-item.show .nav-link {
      color: #495057;
      background-color: #fff;
      border-color: #dee2e6 #dee2e6 #fff; }
    .bootstrap-override .nav-tabs .dropdown-menu {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  .bootstrap-override .nav-pills .nav-link {
    border-radius: 0.25rem; }
  .bootstrap-override .nav-pills .nav-link.active,
  .bootstrap-override .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff; }
  .bootstrap-override .nav-fill > .nav-link,
  .bootstrap-override .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center; }
  .bootstrap-override .nav-justified > .nav-link,
  .bootstrap-override .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center; }
  .bootstrap-override .tab-content > .tab-pane {
    display: none; }
  .bootstrap-override .tab-content > .active {
    display: block; }
  .bootstrap-override .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
    .bootstrap-override .navbar .container,
    .bootstrap-override .navbar .container-fluid, .bootstrap-override .navbar .container-sm, .bootstrap-override .navbar .container-md, .bootstrap-override .navbar .container-lg, .bootstrap-override .navbar .container-xl {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; }
  .bootstrap-override .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }
    .bootstrap-override .navbar-brand:hover, .bootstrap-override .navbar-brand:focus {
      text-decoration: none; }
  .bootstrap-override .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .bootstrap-override .navbar-nav .nav-link {
      padding-right: 0;
      padding-left: 0; }
    .bootstrap-override .navbar-nav .dropdown-menu {
      position: static;
      float: none; }
  .bootstrap-override .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .bootstrap-override .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center; }
  .bootstrap-override .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
    .bootstrap-override .navbar-toggler:hover, .bootstrap-override .navbar-toggler:focus {
      text-decoration: none; }
  .bootstrap-override .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }
  @media (max-width: 575.98px) {
    .bootstrap-override .navbar-expand-sm > .container,
    .bootstrap-override .navbar-expand-sm > .container-fluid, .bootstrap-override .navbar-expand-sm > .container-sm, .bootstrap-override .navbar-expand-sm > .container-md, .bootstrap-override .navbar-expand-sm > .container-lg, .bootstrap-override .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 576px) {
    .bootstrap-override .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      .bootstrap-override .navbar-expand-sm .navbar-nav {
        flex-direction: row; }
        .bootstrap-override .navbar-expand-sm .navbar-nav .dropdown-menu {
          position: absolute; }
        .bootstrap-override .navbar-expand-sm .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .bootstrap-override .navbar-expand-sm > .container,
      .bootstrap-override .navbar-expand-sm > .container-fluid, .bootstrap-override .navbar-expand-sm > .container-sm, .bootstrap-override .navbar-expand-sm > .container-md, .bootstrap-override .navbar-expand-sm > .container-lg, .bootstrap-override .navbar-expand-sm > .container-xl {
        flex-wrap: nowrap; }
      .bootstrap-override .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      .bootstrap-override .navbar-expand-sm .navbar-toggler {
        display: none; } }
  @media (max-width: 767.98px) {
    .bootstrap-override .navbar-expand-md > .container,
    .bootstrap-override .navbar-expand-md > .container-fluid, .bootstrap-override .navbar-expand-md > .container-sm, .bootstrap-override .navbar-expand-md > .container-md, .bootstrap-override .navbar-expand-md > .container-lg, .bootstrap-override .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 768px) {
    .bootstrap-override .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      .bootstrap-override .navbar-expand-md .navbar-nav {
        flex-direction: row; }
        .bootstrap-override .navbar-expand-md .navbar-nav .dropdown-menu {
          position: absolute; }
        .bootstrap-override .navbar-expand-md .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .bootstrap-override .navbar-expand-md > .container,
      .bootstrap-override .navbar-expand-md > .container-fluid, .bootstrap-override .navbar-expand-md > .container-sm, .bootstrap-override .navbar-expand-md > .container-md, .bootstrap-override .navbar-expand-md > .container-lg, .bootstrap-override .navbar-expand-md > .container-xl {
        flex-wrap: nowrap; }
      .bootstrap-override .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      .bootstrap-override .navbar-expand-md .navbar-toggler {
        display: none; } }
  @media (max-width: 991.98px) {
    .bootstrap-override .navbar-expand-lg > .container,
    .bootstrap-override .navbar-expand-lg > .container-fluid, .bootstrap-override .navbar-expand-lg > .container-sm, .bootstrap-override .navbar-expand-lg > .container-md, .bootstrap-override .navbar-expand-lg > .container-lg, .bootstrap-override .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 992px) {
    .bootstrap-override .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      .bootstrap-override .navbar-expand-lg .navbar-nav {
        flex-direction: row; }
        .bootstrap-override .navbar-expand-lg .navbar-nav .dropdown-menu {
          position: absolute; }
        .bootstrap-override .navbar-expand-lg .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .bootstrap-override .navbar-expand-lg > .container,
      .bootstrap-override .navbar-expand-lg > .container-fluid, .bootstrap-override .navbar-expand-lg > .container-sm, .bootstrap-override .navbar-expand-lg > .container-md, .bootstrap-override .navbar-expand-lg > .container-lg, .bootstrap-override .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap; }
      .bootstrap-override .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      .bootstrap-override .navbar-expand-lg .navbar-toggler {
        display: none; } }
  @media (max-width: 1199.98px) {
    .bootstrap-override .navbar-expand-xl > .container,
    .bootstrap-override .navbar-expand-xl > .container-fluid, .bootstrap-override .navbar-expand-xl > .container-sm, .bootstrap-override .navbar-expand-xl > .container-md, .bootstrap-override .navbar-expand-xl > .container-lg, .bootstrap-override .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 1200px) {
    .bootstrap-override .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      .bootstrap-override .navbar-expand-xl .navbar-nav {
        flex-direction: row; }
        .bootstrap-override .navbar-expand-xl .navbar-nav .dropdown-menu {
          position: absolute; }
        .bootstrap-override .navbar-expand-xl .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .bootstrap-override .navbar-expand-xl > .container,
      .bootstrap-override .navbar-expand-xl > .container-fluid, .bootstrap-override .navbar-expand-xl > .container-sm, .bootstrap-override .navbar-expand-xl > .container-md, .bootstrap-override .navbar-expand-xl > .container-lg, .bootstrap-override .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap; }
      .bootstrap-override .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      .bootstrap-override .navbar-expand-xl .navbar-toggler {
        display: none; } }
  .bootstrap-override .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .bootstrap-override .navbar-expand > .container,
    .bootstrap-override .navbar-expand > .container-fluid, .bootstrap-override .navbar-expand > .container-sm, .bootstrap-override .navbar-expand > .container-md, .bootstrap-override .navbar-expand > .container-lg, .bootstrap-override .navbar-expand > .container-xl {
      padding-right: 0;
      padding-left: 0; }
    .bootstrap-override .navbar-expand .navbar-nav {
      flex-direction: row; }
      .bootstrap-override .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute; }
      .bootstrap-override .navbar-expand .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .bootstrap-override .navbar-expand > .container,
    .bootstrap-override .navbar-expand > .container-fluid, .bootstrap-override .navbar-expand > .container-sm, .bootstrap-override .navbar-expand > .container-md, .bootstrap-override .navbar-expand > .container-lg, .bootstrap-override .navbar-expand > .container-xl {
      flex-wrap: nowrap; }
    .bootstrap-override .navbar-expand .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .bootstrap-override .navbar-expand .navbar-toggler {
      display: none; }
  .bootstrap-override .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
    .bootstrap-override .navbar-light .navbar-brand:hover, .bootstrap-override .navbar-light .navbar-brand:focus {
      color: rgba(0, 0, 0, 0.9); }
  .bootstrap-override .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5); }
    .bootstrap-override .navbar-light .navbar-nav .nav-link:hover, .bootstrap-override .navbar-light .navbar-nav .nav-link:focus {
      color: rgba(0, 0, 0, 0.7); }
    .bootstrap-override .navbar-light .navbar-nav .nav-link.disabled {
      color: rgba(0, 0, 0, 0.3); }
  .bootstrap-override .navbar-light .navbar-nav .show > .nav-link,
  .bootstrap-override .navbar-light .navbar-nav .active > .nav-link,
  .bootstrap-override .navbar-light .navbar-nav .nav-link.show,
  .bootstrap-override .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9); }
  .bootstrap-override .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1); }
  .bootstrap-override .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  .bootstrap-override .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5); }
    .bootstrap-override .navbar-light .navbar-text a {
      color: rgba(0, 0, 0, 0.9); }
      .bootstrap-override .navbar-light .navbar-text a:hover, .bootstrap-override .navbar-light .navbar-text a:focus {
        color: rgba(0, 0, 0, 0.9); }
  .bootstrap-override .navbar-dark .navbar-brand {
    color: #fff; }
    .bootstrap-override .navbar-dark .navbar-brand:hover, .bootstrap-override .navbar-dark .navbar-brand:focus {
      color: #fff; }
  .bootstrap-override .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5); }
    .bootstrap-override .navbar-dark .navbar-nav .nav-link:hover, .bootstrap-override .navbar-dark .navbar-nav .nav-link:focus {
      color: rgba(255, 255, 255, 0.75); }
    .bootstrap-override .navbar-dark .navbar-nav .nav-link.disabled {
      color: rgba(255, 255, 255, 0.25); }
  .bootstrap-override .navbar-dark .navbar-nav .show > .nav-link,
  .bootstrap-override .navbar-dark .navbar-nav .active > .nav-link,
  .bootstrap-override .navbar-dark .navbar-nav .nav-link.show,
  .bootstrap-override .navbar-dark .navbar-nav .nav-link.active {
    color: #fff; }
  .bootstrap-override .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1); }
  .bootstrap-override .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  .bootstrap-override .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5); }
    .bootstrap-override .navbar-dark .navbar-text a {
      color: #fff; }
      .bootstrap-override .navbar-dark .navbar-text a:hover, .bootstrap-override .navbar-dark .navbar-text a:focus {
        color: #fff; }
  .bootstrap-override .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem; }
    .bootstrap-override .card > hr {
      margin-right: 0;
      margin-left: 0; }
    .bootstrap-override .card > .list-group {
      border-top: inherit;
      border-bottom: inherit; }
      .bootstrap-override .card > .list-group:first-child {
        border-top-width: 0;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px); }
      .bootstrap-override .card > .list-group:last-child {
        border-bottom-width: 0;
        border-bottom-right-radius: calc(0.25rem - 1px);
        border-bottom-left-radius: calc(0.25rem - 1px); }
    .bootstrap-override .card > .card-header + .list-group,
    .bootstrap-override .card > .list-group + .card-footer {
      border-top: 0; }
  .bootstrap-override .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem; }
  .bootstrap-override .card-title {
    margin-bottom: 0.75rem; }
  .bootstrap-override .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0; }
  .bootstrap-override .card-text:last-child {
    margin-bottom: 0; }
  .bootstrap-override .card-link:hover {
    text-decoration: none; }
  .bootstrap-override .card-link + .card-link {
    margin-left: 1.25rem; }
  .bootstrap-override .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    .bootstrap-override .card-header:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .bootstrap-override .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    .bootstrap-override .card-footer:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
  .bootstrap-override .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0; }
  .bootstrap-override .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
  .bootstrap-override .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.25rem - 1px); }
  .bootstrap-override .card-img,
  .bootstrap-override .card-img-top,
  .bootstrap-override .card-img-bottom {
    flex-shrink: 0;
    width: 100%; }
  .bootstrap-override .card-img,
  .bootstrap-override .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .bootstrap-override .card-img,
  .bootstrap-override .card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .bootstrap-override .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .bootstrap-override .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .bootstrap-override .card-deck .card {
        flex: 1 0;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }
  .bootstrap-override .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .bootstrap-override .card-group {
      display: flex;
      flex-flow: row wrap; }
      .bootstrap-override .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .bootstrap-override .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .bootstrap-override .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .bootstrap-override .card-group > .card:not(:last-child) .card-img-top,
          .bootstrap-override .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .bootstrap-override .card-group > .card:not(:last-child) .card-img-bottom,
          .bootstrap-override .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .bootstrap-override .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .bootstrap-override .card-group > .card:not(:first-child) .card-img-top,
          .bootstrap-override .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .bootstrap-override .card-group > .card:not(:first-child) .card-img-bottom,
          .bootstrap-override .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }
  .bootstrap-override .card-columns .card {
    margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    .bootstrap-override .card-columns {
      -webkit-column-count: 3;
         -moz-column-count: 3;
              column-count: 3;
      grid-column-gap: 1.25rem;
      -webkit-column-gap: 1.25rem;
         -moz-column-gap: 1.25rem;
              column-gap: 1.25rem;
      orphans: 1;
      widows: 1; }
      .bootstrap-override .card-columns .card {
        display: inline-block;
        width: 100%; } }
  .bootstrap-override .accordion {
    overflow-anchor: none; }
    .bootstrap-override .accordion > .card {
      overflow: hidden; }
      .bootstrap-override .accordion > .card:not(:last-of-type) {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      .bootstrap-override .accordion > .card:not(:first-of-type) {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
      .bootstrap-override .accordion > .card > .card-header {
        border-radius: 0;
        margin-bottom: -1px; }
  .bootstrap-override .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  .bootstrap-override .breadcrumb-item {
    display: flex; }
    .bootstrap-override .breadcrumb-item + .breadcrumb-item {
      padding-left: 0.5rem; }
      .bootstrap-override .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "/"; }
    .bootstrap-override .breadcrumb-item + .breadcrumb-item:hover::before {
      text-decoration: underline; }
    .bootstrap-override .breadcrumb-item + .breadcrumb-item:hover::before {
      text-decoration: none; }
    .bootstrap-override .breadcrumb-item.active {
      color: #6c757d; }
  .bootstrap-override .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem; }
  .bootstrap-override .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6; }
    .bootstrap-override .page-link:hover {
      z-index: 2;
      color: #0056b3;
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6; }
    .bootstrap-override .page-link:focus {
      z-index: 3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .bootstrap-override .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .bootstrap-override .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  .bootstrap-override .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .bootstrap-override .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6; }
  .bootstrap-override .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5; }
  .bootstrap-override .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem; }
  .bootstrap-override .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  .bootstrap-override .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5; }
  .bootstrap-override .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem; }
  .bootstrap-override .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem; }
  .bootstrap-override .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .badge {
        -webkit-transition: none;
        transition: none; } }
    a.bootstrap-override .badge:hover, a.bootstrap-override .badge:focus {
      text-decoration: none; }
    .bootstrap-override .badge:empty {
      display: none; }
  .bootstrap-override .btn .badge {
    position: relative;
    top: -1px; }
  .bootstrap-override .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem; }
  .bootstrap-override .badge-primary {
    color: #fff;
    background-color: #007bff; }
    a.bootstrap-override .badge-primary:hover, a.bootstrap-override .badge-primary:focus {
      color: #fff;
      background-color: #0062cc; }
    a.bootstrap-override .badge-primary:focus, a.bootstrap-override .badge-primary.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .bootstrap-override .badge-secondary {
    color: #fff;
    background-color: #6c757d; }
    a.bootstrap-override .badge-secondary:hover, a.bootstrap-override .badge-secondary:focus {
      color: #fff;
      background-color: #545b62; }
    a.bootstrap-override .badge-secondary:focus, a.bootstrap-override .badge-secondary.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .bootstrap-override .badge-success {
    color: #fff;
    background-color: #28a745; }
    a.bootstrap-override .badge-success:hover, a.bootstrap-override .badge-success:focus {
      color: #fff;
      background-color: #1e7e34; }
    a.bootstrap-override .badge-success:focus, a.bootstrap-override .badge-success.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .bootstrap-override .badge-info {
    color: #fff;
    background-color: #17a2b8; }
    a.bootstrap-override .badge-info:hover, a.bootstrap-override .badge-info:focus {
      color: #fff;
      background-color: #117a8b; }
    a.bootstrap-override .badge-info:focus, a.bootstrap-override .badge-info.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .bootstrap-override .badge-warning {
    color: #212529;
    background-color: #ffc107; }
    a.bootstrap-override .badge-warning:hover, a.bootstrap-override .badge-warning:focus {
      color: #212529;
      background-color: #d39e00; }
    a.bootstrap-override .badge-warning:focus, a.bootstrap-override .badge-warning.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .bootstrap-override .badge-danger {
    color: #fff;
    background-color: #dc3545; }
    a.bootstrap-override .badge-danger:hover, a.bootstrap-override .badge-danger:focus {
      color: #fff;
      background-color: #bd2130; }
    a.bootstrap-override .badge-danger:focus, a.bootstrap-override .badge-danger.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .bootstrap-override .badge-light {
    color: #212529;
    background-color: #f8f9fa; }
    a.bootstrap-override .badge-light:hover, a.bootstrap-override .badge-light:focus {
      color: #212529;
      background-color: #dae0e5; }
    a.bootstrap-override .badge-light:focus, a.bootstrap-override .badge-light.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .bootstrap-override .badge-dark {
    color: #fff;
    background-color: #343a40; }
    a.bootstrap-override .badge-dark:hover, a.bootstrap-override .badge-dark:focus {
      color: #fff;
      background-color: #1d2124; }
    a.bootstrap-override .badge-dark:focus, a.bootstrap-override .badge-dark.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .bootstrap-override .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem; }
    @media (min-width: 576px) {
      .bootstrap-override .jumbotron {
        padding: 4rem 2rem; } }
  .bootstrap-override .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0; }
  .bootstrap-override .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
  .bootstrap-override .alert-heading {
    color: inherit; }
  .bootstrap-override .alert-link {
    font-weight: 700; }
  .bootstrap-override .alert-dismissible {
    padding-right: 4rem; }
    .bootstrap-override .alert-dismissible .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.75rem 1.25rem;
      color: inherit; }
  .bootstrap-override .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff; }
    .bootstrap-override .alert-primary hr {
      border-top-color: #9fcdff; }
    .bootstrap-override .alert-primary .alert-link {
      color: #002752; }
  .bootstrap-override .alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db; }
    .bootstrap-override .alert-secondary hr {
      border-top-color: #c8cbcf; }
    .bootstrap-override .alert-secondary .alert-link {
      color: #202326; }
  .bootstrap-override .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
    .bootstrap-override .alert-success hr {
      border-top-color: #b1dfbb; }
    .bootstrap-override .alert-success .alert-link {
      color: #0b2e13; }
  .bootstrap-override .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb; }
    .bootstrap-override .alert-info hr {
      border-top-color: #abdde5; }
    .bootstrap-override .alert-info .alert-link {
      color: #062c33; }
  .bootstrap-override .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba; }
    .bootstrap-override .alert-warning hr {
      border-top-color: #ffe8a1; }
    .bootstrap-override .alert-warning .alert-link {
      color: #533f03; }
  .bootstrap-override .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
    .bootstrap-override .alert-danger hr {
      border-top-color: #f1b0b7; }
    .bootstrap-override .alert-danger .alert-link {
      color: #491217; }
  .bootstrap-override .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe; }
    .bootstrap-override .alert-light hr {
      border-top-color: #ececf6; }
    .bootstrap-override .alert-light .alert-link {
      color: #686868; }
  .bootstrap-override .alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca; }
    .bootstrap-override .alert-dark hr {
      border-top-color: #b9bbbe; }
    .bootstrap-override .alert-dark .alert-link {
      color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
  .bootstrap-override .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  .bootstrap-override .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .progress-bar {
        -webkit-transition: none;
        transition: none; } }
  .bootstrap-override .progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }
  .bootstrap-override .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .progress-bar-animated {
        -webkit-animation: none;
                animation: none; } }
  .bootstrap-override .media {
    display: flex;
    align-items: flex-start; }
  .bootstrap-override .media-body {
    flex: 1 1; }
  .bootstrap-override .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem; }
  .bootstrap-override .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit; }
    .bootstrap-override .list-group-item-action:hover, .bootstrap-override .list-group-item-action:focus {
      z-index: 1;
      color: #495057;
      text-decoration: none;
      background-color: #f8f9fa; }
    .bootstrap-override .list-group-item-action:active {
      color: #212529;
      background-color: #e9ecef; }
  .bootstrap-override .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125); }
    .bootstrap-override .list-group-item:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit; }
    .bootstrap-override .list-group-item:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit; }
    .bootstrap-override .list-group-item.disabled, .bootstrap-override .list-group-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: #fff; }
    .bootstrap-override .list-group-item.active {
      z-index: 2;
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
    .bootstrap-override .list-group-item + .bootstrap-override .list-group-item {
      border-top-width: 0; }
      .bootstrap-override .list-group-item + .bootstrap-override .list-group-item.active {
        margin-top: -1px;
        border-top-width: 1px; }
  .bootstrap-override .list-group-horizontal {
    flex-direction: row; }
    .bootstrap-override .list-group-horizontal > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .bootstrap-override .list-group-horizontal > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .bootstrap-override .list-group-horizontal > .list-group-item.active {
      margin-top: 0; }
    .bootstrap-override .list-group-horizontal > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .bootstrap-override .list-group-horizontal > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; }
  @media (min-width: 576px) {
    .bootstrap-override .list-group-horizontal-sm {
      flex-direction: row; }
      .bootstrap-override .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .bootstrap-override .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .bootstrap-override .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0; }
      .bootstrap-override .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .bootstrap-override .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 768px) {
    .bootstrap-override .list-group-horizontal-md {
      flex-direction: row; }
      .bootstrap-override .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .bootstrap-override .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .bootstrap-override .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0; }
      .bootstrap-override .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .bootstrap-override .list-group-horizontal-md > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 992px) {
    .bootstrap-override .list-group-horizontal-lg {
      flex-direction: row; }
      .bootstrap-override .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .bootstrap-override .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .bootstrap-override .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0; }
      .bootstrap-override .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .bootstrap-override .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 1200px) {
    .bootstrap-override .list-group-horizontal-xl {
      flex-direction: row; }
      .bootstrap-override .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .bootstrap-override .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .bootstrap-override .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0; }
      .bootstrap-override .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .bootstrap-override .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  .bootstrap-override .list-group-flush {
    border-radius: 0; }
    .bootstrap-override .list-group-flush > .list-group-item {
      border-width: 0 0 1px; }
      .bootstrap-override .list-group-flush > .list-group-item:last-child {
        border-bottom-width: 0; }
  .bootstrap-override .list-group-item-primary {
    color: #004085;
    background-color: #b8daff; }
    .bootstrap-override .list-group-item-primary.list-group-item-action:hover, .bootstrap-override .list-group-item-primary.list-group-item-action:focus {
      color: #004085;
      background-color: #9fcdff; }
    .bootstrap-override .list-group-item-primary.list-group-item-action.active {
      color: #fff;
      background-color: #004085;
      border-color: #004085; }
  .bootstrap-override .list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db; }
    .bootstrap-override .list-group-item-secondary.list-group-item-action:hover, .bootstrap-override .list-group-item-secondary.list-group-item-action:focus {
      color: #383d41;
      background-color: #c8cbcf; }
    .bootstrap-override .list-group-item-secondary.list-group-item-action.active {
      color: #fff;
      background-color: #383d41;
      border-color: #383d41; }
  .bootstrap-override .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb; }
    .bootstrap-override .list-group-item-success.list-group-item-action:hover, .bootstrap-override .list-group-item-success.list-group-item-action:focus {
      color: #155724;
      background-color: #b1dfbb; }
    .bootstrap-override .list-group-item-success.list-group-item-action.active {
      color: #fff;
      background-color: #155724;
      border-color: #155724; }
  .bootstrap-override .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb; }
    .bootstrap-override .list-group-item-info.list-group-item-action:hover, .bootstrap-override .list-group-item-info.list-group-item-action:focus {
      color: #0c5460;
      background-color: #abdde5; }
    .bootstrap-override .list-group-item-info.list-group-item-action.active {
      color: #fff;
      background-color: #0c5460;
      border-color: #0c5460; }
  .bootstrap-override .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba; }
    .bootstrap-override .list-group-item-warning.list-group-item-action:hover, .bootstrap-override .list-group-item-warning.list-group-item-action:focus {
      color: #856404;
      background-color: #ffe8a1; }
    .bootstrap-override .list-group-item-warning.list-group-item-action.active {
      color: #fff;
      background-color: #856404;
      border-color: #856404; }
  .bootstrap-override .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb; }
    .bootstrap-override .list-group-item-danger.list-group-item-action:hover, .bootstrap-override .list-group-item-danger.list-group-item-action:focus {
      color: #721c24;
      background-color: #f1b0b7; }
    .bootstrap-override .list-group-item-danger.list-group-item-action.active {
      color: #fff;
      background-color: #721c24;
      border-color: #721c24; }
  .bootstrap-override .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe; }
    .bootstrap-override .list-group-item-light.list-group-item-action:hover, .bootstrap-override .list-group-item-light.list-group-item-action:focus {
      color: #818182;
      background-color: #ececf6; }
    .bootstrap-override .list-group-item-light.list-group-item-action.active {
      color: #fff;
      background-color: #818182;
      border-color: #818182; }
  .bootstrap-override .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca; }
    .bootstrap-override .list-group-item-dark.list-group-item-action:hover, .bootstrap-override .list-group-item-dark.list-group-item-action:focus {
      color: #1b1e21;
      background-color: #b9bbbe; }
    .bootstrap-override .list-group-item-dark.list-group-item-action.active {
      color: #fff;
      background-color: #1b1e21;
      border-color: #1b1e21; }
  .bootstrap-override .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5; }
    .bootstrap-override .close:hover {
      color: #000;
      text-decoration: none; }
    .bootstrap-override .close:not(:disabled):not(.disabled):hover, .bootstrap-override .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }
  .bootstrap-override button.close {
    padding: 0;
    background-color: transparent;
    border: 0; }
  .bootstrap-override a.close.disabled {
    pointer-events: none; }
  .bootstrap-override .toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    border-radius: 0.25rem; }
    .bootstrap-override .toast:not(:last-child) {
      margin-bottom: 0.75rem; }
    .bootstrap-override .toast.showing {
      opacity: 1; }
    .bootstrap-override .toast.show {
      display: block;
      opacity: 1; }
    .bootstrap-override .toast.hide {
      display: none; }
  .bootstrap-override .toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .bootstrap-override .toast-body {
    padding: 0.75rem; }
  .bootstrap-override .modal-open {
    overflow: hidden; }
    .bootstrap-override .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto; }
  .bootstrap-override .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0; }
  .bootstrap-override .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
    .modal.fade .bootstrap-override .modal-dialog {
      -webkit-transition: -webkit-transform 0.3s ease-out;
      transition: -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      -webkit-transform: translate(0, -50px);
              transform: translate(0, -50px); }
      @media (prefers-reduced-motion: reduce) {
        .modal.fade .bootstrap-override .modal-dialog {
          -webkit-transition: none;
          transition: none; } }
    .modal.show .bootstrap-override .modal-dialog {
      -webkit-transform: none;
              transform: none; }
    .modal.modal-static .bootstrap-override .modal-dialog {
      -webkit-transform: scale(1.02);
              transform: scale(1.02); }
  .bootstrap-override .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem); }
    .bootstrap-override .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 1rem);
      overflow: hidden; }
    .bootstrap-override .modal-dialog-scrollable .modal-header,
    .bootstrap-override .modal-dialog-scrollable .modal-footer {
      flex-shrink: 0; }
    .bootstrap-override .modal-dialog-scrollable .modal-body {
      overflow-y: auto; }
  .bootstrap-override .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem); }
    .bootstrap-override .modal-dialog-centered::before {
      display: block;
      height: calc(100vh - 1rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
      content: ""; }
    .bootstrap-override .modal-dialog-centered.modal-dialog-scrollable {
      flex-direction: column;
      justify-content: center;
      height: 100%; }
      .bootstrap-override .modal-dialog-centered.modal-dialog-scrollable .modal-content {
        max-height: none; }
      .bootstrap-override .modal-dialog-centered.modal-dialog-scrollable::before {
        content: none; }
  .bootstrap-override .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0; }
  .bootstrap-override .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
    .bootstrap-override .modal-backdrop.fade {
      opacity: 0; }
    .bootstrap-override .modal-backdrop.show {
      opacity: 0.5; }
  .bootstrap-override .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    .bootstrap-override .modal-header .close {
      padding: 1rem 1rem;
      margin: -1rem -1rem -1rem auto; }
  .bootstrap-override .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  .bootstrap-override .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem; }
  .bootstrap-override .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px); }
    .bootstrap-override .modal-footer > * {
      margin: 0.25rem; }
  .bootstrap-override .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  @media (min-width: 576px) {
    .bootstrap-override .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .bootstrap-override .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem); }
      .bootstrap-override .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem); }
    .bootstrap-override .modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
      .bootstrap-override .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
    .bootstrap-override .modal-sm {
      max-width: 300px; } }
  @media (min-width: 992px) {
    .bootstrap-override .modal-lg,
    .bootstrap-override .modal-xl {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    .bootstrap-override .modal-xl {
      max-width: 1140px; } }
  .bootstrap-override .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0; }
    .bootstrap-override .tooltip.show {
      opacity: 0.9; }
    .bootstrap-override .tooltip .arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem; }
      .bootstrap-override .tooltip .arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid; }
  .bootstrap-override .bs-tooltip-top, .bootstrap-override .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0; }
    .bootstrap-override .bs-tooltip-top .arrow, .bootstrap-override .bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
      .bootstrap-override .bs-tooltip-top .arrow::before, .bootstrap-override .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #000; }
  .bootstrap-override .bs-tooltip-right, .bootstrap-override .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem; }
    .bootstrap-override .bs-tooltip-right .arrow, .bootstrap-override .bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0;
      width: 0.4rem;
      height: 0.8rem; }
      .bootstrap-override .bs-tooltip-right .arrow::before, .bootstrap-override .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: #000; }
  .bootstrap-override .bs-tooltip-bottom, .bootstrap-override .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0; }
    .bootstrap-override .bs-tooltip-bottom .arrow, .bootstrap-override .bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
      .bootstrap-override .bs-tooltip-bottom .arrow::before, .bootstrap-override .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: #000; }
  .bootstrap-override .bs-tooltip-left, .bootstrap-override .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem; }
    .bootstrap-override .bs-tooltip-left .arrow, .bootstrap-override .bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0;
      width: 0.4rem;
      height: 0.8rem; }
      .bootstrap-override .bs-tooltip-left .arrow::before, .bootstrap-override .bs-tooltip-auto[x-placement^="left"] .arrow::before {
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: #000; }
  .bootstrap-override .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem; }
  .bootstrap-override .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem; }
    .bootstrap-override .popover .arrow {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      margin: 0 0.3rem; }
      .bootstrap-override .popover .arrow::before, .bootstrap-override .popover .arrow::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid; }
  .bootstrap-override .bs-popover-top, .bootstrap-override .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem; }
    .bootstrap-override .bs-popover-top > .arrow, .bootstrap-override .bs-popover-auto[x-placement^="top"] > .arrow {
      bottom: calc(-0.5rem - 1px); }
      .bootstrap-override .bs-popover-top > .arrow::before, .bootstrap-override .bs-popover-auto[x-placement^="top"] > .arrow::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0, 0, 0, 0.25); }
      .bootstrap-override .bs-popover-top > .arrow::after, .bootstrap-override .bs-popover-auto[x-placement^="top"] > .arrow::after {
        bottom: 1px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #fff; }
  .bootstrap-override .bs-popover-right, .bootstrap-override .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem; }
    .bootstrap-override .bs-popover-right > .arrow, .bootstrap-override .bs-popover-auto[x-placement^="right"] > .arrow {
      left: calc(-0.5rem - 1px);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0; }
      .bootstrap-override .bs-popover-right > .arrow::before, .bootstrap-override .bs-popover-auto[x-placement^="right"] > .arrow::before {
        left: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0, 0, 0, 0.25); }
      .bootstrap-override .bs-popover-right > .arrow::after, .bootstrap-override .bs-popover-auto[x-placement^="right"] > .arrow::after {
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #fff; }
  .bootstrap-override .bs-popover-bottom, .bootstrap-override .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem; }
    .bootstrap-override .bs-popover-bottom > .arrow, .bootstrap-override .bs-popover-auto[x-placement^="bottom"] > .arrow {
      top: calc(-0.5rem - 1px); }
      .bootstrap-override .bs-popover-bottom > .arrow::before, .bootstrap-override .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25); }
      .bootstrap-override .bs-popover-bottom > .arrow::after, .bootstrap-override .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: #fff; }
    .bootstrap-override .bs-popover-bottom .popover-header::before, .bootstrap-override .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1rem;
      margin-left: -0.5rem;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  .bootstrap-override .bs-popover-left, .bootstrap-override .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem; }
    .bootstrap-override .bs-popover-left > .arrow, .bootstrap-override .bs-popover-auto[x-placement^="left"] > .arrow {
      right: calc(-0.5rem - 1px);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0; }
      .bootstrap-override .bs-popover-left > .arrow::before, .bootstrap-override .bs-popover-auto[x-placement^="left"] > .arrow::before {
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25); }
      .bootstrap-override .bs-popover-left > .arrow::after, .bootstrap-override .bs-popover-auto[x-placement^="left"] > .arrow::after {
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #fff; }
  .bootstrap-override .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    .bootstrap-override .popover-header:empty {
      display: none; }
  .bootstrap-override .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529; }
  .bootstrap-override .carousel {
    position: relative; }
  .bootstrap-override .carousel.pointer-event {
    touch-action: pan-y; }
  .bootstrap-override .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
    .bootstrap-override .carousel-inner::after {
      display: block;
      clear: both;
      content: ""; }
  .bootstrap-override .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .carousel-item {
        -webkit-transition: none;
        transition: none; } }
  .bootstrap-override .carousel-item.active,
  .bootstrap-override .carousel-item-next,
  .bootstrap-override .carousel-item-prev {
    display: block; }
  .bootstrap-override .carousel-item-next:not(.carousel-item-left),
  .bootstrap-override .active.carousel-item-right {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  .bootstrap-override .carousel-item-prev:not(.carousel-item-right),
  .bootstrap-override .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .bootstrap-override .carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
            transform: none; }
  .bootstrap-override .carousel-fade .carousel-item.active,
  .bootstrap-override .carousel-fade .carousel-item-next.carousel-item-left,
  .bootstrap-override .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1; }
  .bootstrap-override .carousel-fade .active.carousel-item-left,
  .bootstrap-override .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s 0.6s;
    transition: opacity 0s 0.6s; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .carousel-fade .active.carousel-item-left,
      .bootstrap-override .carousel-fade .active.carousel-item-right {
        -webkit-transition: none;
        transition: none; } }
  .bootstrap-override .carousel-control-prev,
  .bootstrap-override .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease; }
    @media (prefers-reduced-motion: reduce) {
      .bootstrap-override .carousel-control-prev,
      .bootstrap-override .carousel-control-next {
        -webkit-transition: none;
        transition: none; } }
    .bootstrap-override .carousel-control-prev:hover, .bootstrap-override .carousel-control-prev:focus,
    .bootstrap-override .carousel-control-next:hover,
    .bootstrap-override .carousel-control-next:focus {
      color: #fff;
      text-decoration: none;
      outline: 0;
      opacity: 0.9; }
  .bootstrap-override .carousel-control-prev {
    left: 0; }
  .bootstrap-override .carousel-control-next {
    right: 0; }
  .bootstrap-override .carousel-control-prev-icon,
  .bootstrap-override .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%; }
  .bootstrap-override .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }
  .bootstrap-override .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }
  .bootstrap-override .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none; }
    .bootstrap-override .carousel-indicators li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: .5;
      -webkit-transition: opacity 0.6s ease;
      transition: opacity 0.6s ease; }
      @media (prefers-reduced-motion: reduce) {
        .bootstrap-override .carousel-indicators li {
          -webkit-transition: none;
          transition: none; } }
    .bootstrap-override .carousel-indicators .active {
      opacity: 1; }
  .bootstrap-override .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .bootstrap-override .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
            animation: spinner-border .75s linear infinite; }
  .bootstrap-override .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }
  .bootstrap-override .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
            animation: spinner-grow .75s linear infinite; }
  .bootstrap-override .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }
  .bootstrap-override .align-baseline {
    vertical-align: baseline !important; }
  .bootstrap-override .align-top {
    vertical-align: top !important; }
  .bootstrap-override .align-middle {
    vertical-align: middle !important; }
  .bootstrap-override .align-bottom {
    vertical-align: bottom !important; }
  .bootstrap-override .align-text-bottom {
    vertical-align: text-bottom !important; }
  .bootstrap-override .align-text-top {
    vertical-align: text-top !important; }
  .bootstrap-override .bg-primary {
    background-color: #007bff !important; }
  .bootstrap-override a.bg-primary:hover, .bootstrap-override a.bg-primary:focus,
  .bootstrap-override button.bg-primary:hover,
  .bootstrap-override button.bg-primary:focus {
    background-color: #0062cc !important; }
  .bootstrap-override .bg-secondary {
    background-color: #6c757d !important; }
  .bootstrap-override a.bg-secondary:hover, .bootstrap-override a.bg-secondary:focus,
  .bootstrap-override button.bg-secondary:hover,
  .bootstrap-override button.bg-secondary:focus {
    background-color: #545b62 !important; }
  .bootstrap-override .bg-success {
    background-color: #28a745 !important; }
  .bootstrap-override a.bg-success:hover, .bootstrap-override a.bg-success:focus,
  .bootstrap-override button.bg-success:hover,
  .bootstrap-override button.bg-success:focus {
    background-color: #1e7e34 !important; }
  .bootstrap-override .bg-info {
    background-color: #17a2b8 !important; }
  .bootstrap-override a.bg-info:hover, .bootstrap-override a.bg-info:focus,
  .bootstrap-override button.bg-info:hover,
  .bootstrap-override button.bg-info:focus {
    background-color: #117a8b !important; }
  .bootstrap-override .bg-warning {
    background-color: #ffc107 !important; }
  .bootstrap-override a.bg-warning:hover, .bootstrap-override a.bg-warning:focus,
  .bootstrap-override button.bg-warning:hover,
  .bootstrap-override button.bg-warning:focus {
    background-color: #d39e00 !important; }
  .bootstrap-override .bg-danger {
    background-color: #dc3545 !important; }
  .bootstrap-override a.bg-danger:hover, .bootstrap-override a.bg-danger:focus,
  .bootstrap-override button.bg-danger:hover,
  .bootstrap-override button.bg-danger:focus {
    background-color: #bd2130 !important; }
  .bootstrap-override .bg-light {
    background-color: #f8f9fa !important; }
  .bootstrap-override a.bg-light:hover, .bootstrap-override a.bg-light:focus,
  .bootstrap-override button.bg-light:hover,
  .bootstrap-override button.bg-light:focus {
    background-color: #dae0e5 !important; }
  .bootstrap-override .bg-dark {
    background-color: #343a40 !important; }
  .bootstrap-override a.bg-dark:hover, .bootstrap-override a.bg-dark:focus,
  .bootstrap-override button.bg-dark:hover,
  .bootstrap-override button.bg-dark:focus {
    background-color: #1d2124 !important; }
  .bootstrap-override .bg-white {
    background-color: #fff !important; }
  .bootstrap-override .bg-transparent {
    background-color: transparent !important; }
  .bootstrap-override .border {
    border: 1px solid #dee2e6 !important; }
  .bootstrap-override .border-top {
    border-top: 1px solid #dee2e6 !important; }
  .bootstrap-override .border-right {
    border-right: 1px solid #dee2e6 !important; }
  .bootstrap-override .border-bottom {
    border-bottom: 1px solid #dee2e6 !important; }
  .bootstrap-override .border-left {
    border-left: 1px solid #dee2e6 !important; }
  .bootstrap-override .border-0 {
    border: 0 !important; }
  .bootstrap-override .border-top-0 {
    border-top: 0 !important; }
  .bootstrap-override .border-right-0 {
    border-right: 0 !important; }
  .bootstrap-override .border-bottom-0 {
    border-bottom: 0 !important; }
  .bootstrap-override .border-left-0 {
    border-left: 0 !important; }
  .bootstrap-override .border-primary {
    border-color: #007bff !important; }
  .bootstrap-override .border-secondary {
    border-color: #6c757d !important; }
  .bootstrap-override .border-success {
    border-color: #28a745 !important; }
  .bootstrap-override .border-info {
    border-color: #17a2b8 !important; }
  .bootstrap-override .border-warning {
    border-color: #ffc107 !important; }
  .bootstrap-override .border-danger {
    border-color: #dc3545 !important; }
  .bootstrap-override .border-light {
    border-color: #f8f9fa !important; }
  .bootstrap-override .border-dark {
    border-color: #343a40 !important; }
  .bootstrap-override .border-white {
    border-color: #fff !important; }
  .bootstrap-override .rounded-sm {
    border-radius: 0.2rem !important; }
  .bootstrap-override .rounded {
    border-radius: 0.25rem !important; }
  .bootstrap-override .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important; }
  .bootstrap-override .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important; }
  .bootstrap-override .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  .bootstrap-override .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  .bootstrap-override .rounded-lg {
    border-radius: 0.3rem !important; }
  .bootstrap-override .rounded-circle {
    border-radius: 50% !important; }
  .bootstrap-override .rounded-pill {
    border-radius: 50rem !important; }
  .bootstrap-override .rounded-0 {
    border-radius: 0 !important; }
  .bootstrap-override .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  .bootstrap-override .d-none {
    display: none !important; }
  .bootstrap-override .d-inline {
    display: inline !important; }
  .bootstrap-override .d-inline-block {
    display: inline-block !important; }
  .bootstrap-override .d-block {
    display: block !important; }
  .bootstrap-override .d-table {
    display: table !important; }
  .bootstrap-override .d-table-row {
    display: table-row !important; }
  .bootstrap-override .d-table-cell {
    display: table-cell !important; }
  .bootstrap-override .d-flex {
    display: flex !important; }
  .bootstrap-override .d-inline-flex {
    display: inline-flex !important; }
  @media (min-width: 576px) {
    .bootstrap-override .d-sm-none {
      display: none !important; }
    .bootstrap-override .d-sm-inline {
      display: inline !important; }
    .bootstrap-override .d-sm-inline-block {
      display: inline-block !important; }
    .bootstrap-override .d-sm-block {
      display: block !important; }
    .bootstrap-override .d-sm-table {
      display: table !important; }
    .bootstrap-override .d-sm-table-row {
      display: table-row !important; }
    .bootstrap-override .d-sm-table-cell {
      display: table-cell !important; }
    .bootstrap-override .d-sm-flex {
      display: flex !important; }
    .bootstrap-override .d-sm-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 768px) {
    .bootstrap-override .d-md-none {
      display: none !important; }
    .bootstrap-override .d-md-inline {
      display: inline !important; }
    .bootstrap-override .d-md-inline-block {
      display: inline-block !important; }
    .bootstrap-override .d-md-block {
      display: block !important; }
    .bootstrap-override .d-md-table {
      display: table !important; }
    .bootstrap-override .d-md-table-row {
      display: table-row !important; }
    .bootstrap-override .d-md-table-cell {
      display: table-cell !important; }
    .bootstrap-override .d-md-flex {
      display: flex !important; }
    .bootstrap-override .d-md-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 992px) {
    .bootstrap-override .d-lg-none {
      display: none !important; }
    .bootstrap-override .d-lg-inline {
      display: inline !important; }
    .bootstrap-override .d-lg-inline-block {
      display: inline-block !important; }
    .bootstrap-override .d-lg-block {
      display: block !important; }
    .bootstrap-override .d-lg-table {
      display: table !important; }
    .bootstrap-override .d-lg-table-row {
      display: table-row !important; }
    .bootstrap-override .d-lg-table-cell {
      display: table-cell !important; }
    .bootstrap-override .d-lg-flex {
      display: flex !important; }
    .bootstrap-override .d-lg-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 1200px) {
    .bootstrap-override .d-xl-none {
      display: none !important; }
    .bootstrap-override .d-xl-inline {
      display: inline !important; }
    .bootstrap-override .d-xl-inline-block {
      display: inline-block !important; }
    .bootstrap-override .d-xl-block {
      display: block !important; }
    .bootstrap-override .d-xl-table {
      display: table !important; }
    .bootstrap-override .d-xl-table-row {
      display: table-row !important; }
    .bootstrap-override .d-xl-table-cell {
      display: table-cell !important; }
    .bootstrap-override .d-xl-flex {
      display: flex !important; }
    .bootstrap-override .d-xl-inline-flex {
      display: inline-flex !important; } }
  @media print {
    .bootstrap-override .d-print-none {
      display: none !important; }
    .bootstrap-override .d-print-inline {
      display: inline !important; }
    .bootstrap-override .d-print-inline-block {
      display: inline-block !important; }
    .bootstrap-override .d-print-block {
      display: block !important; }
    .bootstrap-override .d-print-table {
      display: table !important; }
    .bootstrap-override .d-print-table-row {
      display: table-row !important; }
    .bootstrap-override .d-print-table-cell {
      display: table-cell !important; }
    .bootstrap-override .d-print-flex {
      display: flex !important; }
    .bootstrap-override .d-print-inline-flex {
      display: inline-flex !important; } }
  .bootstrap-override .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden; }
    .bootstrap-override .embed-responsive::before {
      display: block;
      content: ""; }
    .bootstrap-override .embed-responsive .embed-responsive-item,
    .bootstrap-override .embed-responsive iframe,
    .bootstrap-override .embed-responsive embed,
    .bootstrap-override .embed-responsive object,
    .bootstrap-override .embed-responsive video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; }
  .bootstrap-override .embed-responsive-21by9::before {
    padding-top: 42.85714%; }
  .bootstrap-override .embed-responsive-16by9::before {
    padding-top: 56.25%; }
  .bootstrap-override .embed-responsive-4by3::before {
    padding-top: 75%; }
  .bootstrap-override .embed-responsive-1by1::before {
    padding-top: 100%; }
  .bootstrap-override .flex-row {
    flex-direction: row !important; }
  .bootstrap-override .flex-column {
    flex-direction: column !important; }
  .bootstrap-override .flex-row-reverse {
    flex-direction: row-reverse !important; }
  .bootstrap-override .flex-column-reverse {
    flex-direction: column-reverse !important; }
  .bootstrap-override .flex-wrap {
    flex-wrap: wrap !important; }
  .bootstrap-override .flex-nowrap {
    flex-wrap: nowrap !important; }
  .bootstrap-override .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .bootstrap-override .flex-fill {
    flex: 1 1 auto !important; }
  .bootstrap-override .flex-grow-0 {
    flex-grow: 0 !important; }
  .bootstrap-override .flex-grow-1 {
    flex-grow: 1 !important; }
  .bootstrap-override .flex-shrink-0 {
    flex-shrink: 0 !important; }
  .bootstrap-override .flex-shrink-1 {
    flex-shrink: 1 !important; }
  .bootstrap-override .justify-content-start {
    justify-content: flex-start !important; }
  .bootstrap-override .justify-content-end {
    justify-content: flex-end !important; }
  .bootstrap-override .justify-content-center {
    justify-content: center !important; }
  .bootstrap-override .justify-content-between {
    justify-content: space-between !important; }
  .bootstrap-override .justify-content-around {
    justify-content: space-around !important; }
  .bootstrap-override .align-items-start {
    align-items: flex-start !important; }
  .bootstrap-override .align-items-end {
    align-items: flex-end !important; }
  .bootstrap-override .align-items-center {
    align-items: center !important; }
  .bootstrap-override .align-items-baseline {
    align-items: baseline !important; }
  .bootstrap-override .align-items-stretch {
    align-items: stretch !important; }
  .bootstrap-override .align-content-start {
    align-content: flex-start !important; }
  .bootstrap-override .align-content-end {
    align-content: flex-end !important; }
  .bootstrap-override .align-content-center {
    align-content: center !important; }
  .bootstrap-override .align-content-between {
    align-content: space-between !important; }
  .bootstrap-override .align-content-around {
    align-content: space-around !important; }
  .bootstrap-override .align-content-stretch {
    align-content: stretch !important; }
  .bootstrap-override .align-self-auto {
    align-self: auto !important; }
  .bootstrap-override .align-self-start {
    align-self: flex-start !important; }
  .bootstrap-override .align-self-end {
    align-self: flex-end !important; }
  .bootstrap-override .align-self-center {
    align-self: center !important; }
  .bootstrap-override .align-self-baseline {
    align-self: baseline !important; }
  .bootstrap-override .align-self-stretch {
    align-self: stretch !important; }
  @media (min-width: 576px) {
    .bootstrap-override .flex-sm-row {
      flex-direction: row !important; }
    .bootstrap-override .flex-sm-column {
      flex-direction: column !important; }
    .bootstrap-override .flex-sm-row-reverse {
      flex-direction: row-reverse !important; }
    .bootstrap-override .flex-sm-column-reverse {
      flex-direction: column-reverse !important; }
    .bootstrap-override .flex-sm-wrap {
      flex-wrap: wrap !important; }
    .bootstrap-override .flex-sm-nowrap {
      flex-wrap: nowrap !important; }
    .bootstrap-override .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .bootstrap-override .flex-sm-fill {
      flex: 1 1 auto !important; }
    .bootstrap-override .flex-sm-grow-0 {
      flex-grow: 0 !important; }
    .bootstrap-override .flex-sm-grow-1 {
      flex-grow: 1 !important; }
    .bootstrap-override .flex-sm-shrink-0 {
      flex-shrink: 0 !important; }
    .bootstrap-override .flex-sm-shrink-1 {
      flex-shrink: 1 !important; }
    .bootstrap-override .justify-content-sm-start {
      justify-content: flex-start !important; }
    .bootstrap-override .justify-content-sm-end {
      justify-content: flex-end !important; }
    .bootstrap-override .justify-content-sm-center {
      justify-content: center !important; }
    .bootstrap-override .justify-content-sm-between {
      justify-content: space-between !important; }
    .bootstrap-override .justify-content-sm-around {
      justify-content: space-around !important; }
    .bootstrap-override .align-items-sm-start {
      align-items: flex-start !important; }
    .bootstrap-override .align-items-sm-end {
      align-items: flex-end !important; }
    .bootstrap-override .align-items-sm-center {
      align-items: center !important; }
    .bootstrap-override .align-items-sm-baseline {
      align-items: baseline !important; }
    .bootstrap-override .align-items-sm-stretch {
      align-items: stretch !important; }
    .bootstrap-override .align-content-sm-start {
      align-content: flex-start !important; }
    .bootstrap-override .align-content-sm-end {
      align-content: flex-end !important; }
    .bootstrap-override .align-content-sm-center {
      align-content: center !important; }
    .bootstrap-override .align-content-sm-between {
      align-content: space-between !important; }
    .bootstrap-override .align-content-sm-around {
      align-content: space-around !important; }
    .bootstrap-override .align-content-sm-stretch {
      align-content: stretch !important; }
    .bootstrap-override .align-self-sm-auto {
      align-self: auto !important; }
    .bootstrap-override .align-self-sm-start {
      align-self: flex-start !important; }
    .bootstrap-override .align-self-sm-end {
      align-self: flex-end !important; }
    .bootstrap-override .align-self-sm-center {
      align-self: center !important; }
    .bootstrap-override .align-self-sm-baseline {
      align-self: baseline !important; }
    .bootstrap-override .align-self-sm-stretch {
      align-self: stretch !important; } }
  @media (min-width: 768px) {
    .bootstrap-override .flex-md-row {
      flex-direction: row !important; }
    .bootstrap-override .flex-md-column {
      flex-direction: column !important; }
    .bootstrap-override .flex-md-row-reverse {
      flex-direction: row-reverse !important; }
    .bootstrap-override .flex-md-column-reverse {
      flex-direction: column-reverse !important; }
    .bootstrap-override .flex-md-wrap {
      flex-wrap: wrap !important; }
    .bootstrap-override .flex-md-nowrap {
      flex-wrap: nowrap !important; }
    .bootstrap-override .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .bootstrap-override .flex-md-fill {
      flex: 1 1 auto !important; }
    .bootstrap-override .flex-md-grow-0 {
      flex-grow: 0 !important; }
    .bootstrap-override .flex-md-grow-1 {
      flex-grow: 1 !important; }
    .bootstrap-override .flex-md-shrink-0 {
      flex-shrink: 0 !important; }
    .bootstrap-override .flex-md-shrink-1 {
      flex-shrink: 1 !important; }
    .bootstrap-override .justify-content-md-start {
      justify-content: flex-start !important; }
    .bootstrap-override .justify-content-md-end {
      justify-content: flex-end !important; }
    .bootstrap-override .justify-content-md-center {
      justify-content: center !important; }
    .bootstrap-override .justify-content-md-between {
      justify-content: space-between !important; }
    .bootstrap-override .justify-content-md-around {
      justify-content: space-around !important; }
    .bootstrap-override .align-items-md-start {
      align-items: flex-start !important; }
    .bootstrap-override .align-items-md-end {
      align-items: flex-end !important; }
    .bootstrap-override .align-items-md-center {
      align-items: center !important; }
    .bootstrap-override .align-items-md-baseline {
      align-items: baseline !important; }
    .bootstrap-override .align-items-md-stretch {
      align-items: stretch !important; }
    .bootstrap-override .align-content-md-start {
      align-content: flex-start !important; }
    .bootstrap-override .align-content-md-end {
      align-content: flex-end !important; }
    .bootstrap-override .align-content-md-center {
      align-content: center !important; }
    .bootstrap-override .align-content-md-between {
      align-content: space-between !important; }
    .bootstrap-override .align-content-md-around {
      align-content: space-around !important; }
    .bootstrap-override .align-content-md-stretch {
      align-content: stretch !important; }
    .bootstrap-override .align-self-md-auto {
      align-self: auto !important; }
    .bootstrap-override .align-self-md-start {
      align-self: flex-start !important; }
    .bootstrap-override .align-self-md-end {
      align-self: flex-end !important; }
    .bootstrap-override .align-self-md-center {
      align-self: center !important; }
    .bootstrap-override .align-self-md-baseline {
      align-self: baseline !important; }
    .bootstrap-override .align-self-md-stretch {
      align-self: stretch !important; } }
  @media (min-width: 992px) {
    .bootstrap-override .flex-lg-row {
      flex-direction: row !important; }
    .bootstrap-override .flex-lg-column {
      flex-direction: column !important; }
    .bootstrap-override .flex-lg-row-reverse {
      flex-direction: row-reverse !important; }
    .bootstrap-override .flex-lg-column-reverse {
      flex-direction: column-reverse !important; }
    .bootstrap-override .flex-lg-wrap {
      flex-wrap: wrap !important; }
    .bootstrap-override .flex-lg-nowrap {
      flex-wrap: nowrap !important; }
    .bootstrap-override .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .bootstrap-override .flex-lg-fill {
      flex: 1 1 auto !important; }
    .bootstrap-override .flex-lg-grow-0 {
      flex-grow: 0 !important; }
    .bootstrap-override .flex-lg-grow-1 {
      flex-grow: 1 !important; }
    .bootstrap-override .flex-lg-shrink-0 {
      flex-shrink: 0 !important; }
    .bootstrap-override .flex-lg-shrink-1 {
      flex-shrink: 1 !important; }
    .bootstrap-override .justify-content-lg-start {
      justify-content: flex-start !important; }
    .bootstrap-override .justify-content-lg-end {
      justify-content: flex-end !important; }
    .bootstrap-override .justify-content-lg-center {
      justify-content: center !important; }
    .bootstrap-override .justify-content-lg-between {
      justify-content: space-between !important; }
    .bootstrap-override .justify-content-lg-around {
      justify-content: space-around !important; }
    .bootstrap-override .align-items-lg-start {
      align-items: flex-start !important; }
    .bootstrap-override .align-items-lg-end {
      align-items: flex-end !important; }
    .bootstrap-override .align-items-lg-center {
      align-items: center !important; }
    .bootstrap-override .align-items-lg-baseline {
      align-items: baseline !important; }
    .bootstrap-override .align-items-lg-stretch {
      align-items: stretch !important; }
    .bootstrap-override .align-content-lg-start {
      align-content: flex-start !important; }
    .bootstrap-override .align-content-lg-end {
      align-content: flex-end !important; }
    .bootstrap-override .align-content-lg-center {
      align-content: center !important; }
    .bootstrap-override .align-content-lg-between {
      align-content: space-between !important; }
    .bootstrap-override .align-content-lg-around {
      align-content: space-around !important; }
    .bootstrap-override .align-content-lg-stretch {
      align-content: stretch !important; }
    .bootstrap-override .align-self-lg-auto {
      align-self: auto !important; }
    .bootstrap-override .align-self-lg-start {
      align-self: flex-start !important; }
    .bootstrap-override .align-self-lg-end {
      align-self: flex-end !important; }
    .bootstrap-override .align-self-lg-center {
      align-self: center !important; }
    .bootstrap-override .align-self-lg-baseline {
      align-self: baseline !important; }
    .bootstrap-override .align-self-lg-stretch {
      align-self: stretch !important; } }
  @media (min-width: 1200px) {
    .bootstrap-override .flex-xl-row {
      flex-direction: row !important; }
    .bootstrap-override .flex-xl-column {
      flex-direction: column !important; }
    .bootstrap-override .flex-xl-row-reverse {
      flex-direction: row-reverse !important; }
    .bootstrap-override .flex-xl-column-reverse {
      flex-direction: column-reverse !important; }
    .bootstrap-override .flex-xl-wrap {
      flex-wrap: wrap !important; }
    .bootstrap-override .flex-xl-nowrap {
      flex-wrap: nowrap !important; }
    .bootstrap-override .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .bootstrap-override .flex-xl-fill {
      flex: 1 1 auto !important; }
    .bootstrap-override .flex-xl-grow-0 {
      flex-grow: 0 !important; }
    .bootstrap-override .flex-xl-grow-1 {
      flex-grow: 1 !important; }
    .bootstrap-override .flex-xl-shrink-0 {
      flex-shrink: 0 !important; }
    .bootstrap-override .flex-xl-shrink-1 {
      flex-shrink: 1 !important; }
    .bootstrap-override .justify-content-xl-start {
      justify-content: flex-start !important; }
    .bootstrap-override .justify-content-xl-end {
      justify-content: flex-end !important; }
    .bootstrap-override .justify-content-xl-center {
      justify-content: center !important; }
    .bootstrap-override .justify-content-xl-between {
      justify-content: space-between !important; }
    .bootstrap-override .justify-content-xl-around {
      justify-content: space-around !important; }
    .bootstrap-override .align-items-xl-start {
      align-items: flex-start !important; }
    .bootstrap-override .align-items-xl-end {
      align-items: flex-end !important; }
    .bootstrap-override .align-items-xl-center {
      align-items: center !important; }
    .bootstrap-override .align-items-xl-baseline {
      align-items: baseline !important; }
    .bootstrap-override .align-items-xl-stretch {
      align-items: stretch !important; }
    .bootstrap-override .align-content-xl-start {
      align-content: flex-start !important; }
    .bootstrap-override .align-content-xl-end {
      align-content: flex-end !important; }
    .bootstrap-override .align-content-xl-center {
      align-content: center !important; }
    .bootstrap-override .align-content-xl-between {
      align-content: space-between !important; }
    .bootstrap-override .align-content-xl-around {
      align-content: space-around !important; }
    .bootstrap-override .align-content-xl-stretch {
      align-content: stretch !important; }
    .bootstrap-override .align-self-xl-auto {
      align-self: auto !important; }
    .bootstrap-override .align-self-xl-start {
      align-self: flex-start !important; }
    .bootstrap-override .align-self-xl-end {
      align-self: flex-end !important; }
    .bootstrap-override .align-self-xl-center {
      align-self: center !important; }
    .bootstrap-override .align-self-xl-baseline {
      align-self: baseline !important; }
    .bootstrap-override .align-self-xl-stretch {
      align-self: stretch !important; } }
  .bootstrap-override .float-left {
    float: left !important; }
  .bootstrap-override .float-right {
    float: right !important; }
  .bootstrap-override .float-none {
    float: none !important; }
  @media (min-width: 576px) {
    .bootstrap-override .float-sm-left {
      float: left !important; }
    .bootstrap-override .float-sm-right {
      float: right !important; }
    .bootstrap-override .float-sm-none {
      float: none !important; } }
  @media (min-width: 768px) {
    .bootstrap-override .float-md-left {
      float: left !important; }
    .bootstrap-override .float-md-right {
      float: right !important; }
    .bootstrap-override .float-md-none {
      float: none !important; } }
  @media (min-width: 992px) {
    .bootstrap-override .float-lg-left {
      float: left !important; }
    .bootstrap-override .float-lg-right {
      float: right !important; }
    .bootstrap-override .float-lg-none {
      float: none !important; } }
  @media (min-width: 1200px) {
    .bootstrap-override .float-xl-left {
      float: left !important; }
    .bootstrap-override .float-xl-right {
      float: right !important; }
    .bootstrap-override .float-xl-none {
      float: none !important; } }
  .bootstrap-override .user-select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important; }
  .bootstrap-override .user-select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important; }
  .bootstrap-override .user-select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important; }
  .bootstrap-override .overflow-auto {
    overflow: auto !important; }
  .bootstrap-override .overflow-hidden {
    overflow: hidden !important; }
  .bootstrap-override .position-static {
    position: static !important; }
  .bootstrap-override .position-relative {
    position: relative !important; }
  .bootstrap-override .position-absolute {
    position: absolute !important; }
  .bootstrap-override .position-fixed {
    position: fixed !important; }
  .bootstrap-override .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; }
  .bootstrap-override .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  .bootstrap-override .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bootstrap-override .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  .bootstrap-override .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; }
  .bootstrap-override .sr-only-focusable:active, .bootstrap-override .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal; }
  .bootstrap-override .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }
  .bootstrap-override .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
  .bootstrap-override .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .bootstrap-override .shadow-none {
    box-shadow: none !important; }
  .bootstrap-override .w-25 {
    width: 25% !important; }
  .bootstrap-override .w-50 {
    width: 50% !important; }
  .bootstrap-override .w-75 {
    width: 75% !important; }
  .bootstrap-override .w-100 {
    width: 100% !important; }
  .bootstrap-override .w-auto {
    width: auto !important; }
  .bootstrap-override .h-25 {
    height: 25% !important; }
  .bootstrap-override .h-50 {
    height: 50% !important; }
  .bootstrap-override .h-75 {
    height: 75% !important; }
  .bootstrap-override .h-100 {
    height: 100% !important; }
  .bootstrap-override .h-auto {
    height: auto !important; }
  .bootstrap-override .mw-100 {
    max-width: 100% !important; }
  .bootstrap-override .mh-100 {
    max-height: 100% !important; }
  .bootstrap-override .min-vw-100 {
    min-width: 100vw !important; }
  .bootstrap-override .min-vh-100 {
    min-height: 100vh !important; }
  .bootstrap-override .vw-100 {
    width: 100vw !important; }
  .bootstrap-override .vh-100 {
    height: 100vh !important; }
  .bootstrap-override .m-0 {
    margin: 0 !important; }
  .bootstrap-override .mt-0,
  .bootstrap-override .my-0 {
    margin-top: 0 !important; }
  .bootstrap-override .mr-0,
  .bootstrap-override .mx-0 {
    margin-right: 0 !important; }
  .bootstrap-override .mb-0,
  .bootstrap-override .my-0 {
    margin-bottom: 0 !important; }
  .bootstrap-override .ml-0,
  .bootstrap-override .mx-0 {
    margin-left: 0 !important; }
  .bootstrap-override .m-1 {
    margin: 0.25rem !important; }
  .bootstrap-override .mt-1,
  .bootstrap-override .my-1 {
    margin-top: 0.25rem !important; }
  .bootstrap-override .mr-1,
  .bootstrap-override .mx-1 {
    margin-right: 0.25rem !important; }
  .bootstrap-override .mb-1,
  .bootstrap-override .my-1 {
    margin-bottom: 0.25rem !important; }
  .bootstrap-override .ml-1,
  .bootstrap-override .mx-1 {
    margin-left: 0.25rem !important; }
  .bootstrap-override .m-2 {
    margin: 0.5rem !important; }
  .bootstrap-override .mt-2,
  .bootstrap-override .my-2 {
    margin-top: 0.5rem !important; }
  .bootstrap-override .mr-2,
  .bootstrap-override .mx-2 {
    margin-right: 0.5rem !important; }
  .bootstrap-override .mb-2,
  .bootstrap-override .my-2 {
    margin-bottom: 0.5rem !important; }
  .bootstrap-override .ml-2,
  .bootstrap-override .mx-2 {
    margin-left: 0.5rem !important; }
  .bootstrap-override .m-3 {
    margin: 1rem !important; }
  .bootstrap-override .mt-3,
  .bootstrap-override .my-3 {
    margin-top: 1rem !important; }
  .bootstrap-override .mr-3,
  .bootstrap-override .mx-3 {
    margin-right: 1rem !important; }
  .bootstrap-override .mb-3,
  .bootstrap-override .my-3 {
    margin-bottom: 1rem !important; }
  .bootstrap-override .ml-3,
  .bootstrap-override .mx-3 {
    margin-left: 1rem !important; }
  .bootstrap-override .m-4 {
    margin: 1.5rem !important; }
  .bootstrap-override .mt-4,
  .bootstrap-override .my-4 {
    margin-top: 1.5rem !important; }
  .bootstrap-override .mr-4,
  .bootstrap-override .mx-4 {
    margin-right: 1.5rem !important; }
  .bootstrap-override .mb-4,
  .bootstrap-override .my-4 {
    margin-bottom: 1.5rem !important; }
  .bootstrap-override .ml-4,
  .bootstrap-override .mx-4 {
    margin-left: 1.5rem !important; }
  .bootstrap-override .m-5 {
    margin: 3rem !important; }
  .bootstrap-override .mt-5,
  .bootstrap-override .my-5 {
    margin-top: 3rem !important; }
  .bootstrap-override .mr-5,
  .bootstrap-override .mx-5 {
    margin-right: 3rem !important; }
  .bootstrap-override .mb-5,
  .bootstrap-override .my-5 {
    margin-bottom: 3rem !important; }
  .bootstrap-override .ml-5,
  .bootstrap-override .mx-5 {
    margin-left: 3rem !important; }
  .bootstrap-override .p-0 {
    padding: 0 !important; }
  .bootstrap-override .pt-0,
  .bootstrap-override .py-0 {
    padding-top: 0 !important; }
  .bootstrap-override .pr-0,
  .bootstrap-override .px-0 {
    padding-right: 0 !important; }
  .bootstrap-override .pb-0,
  .bootstrap-override .py-0 {
    padding-bottom: 0 !important; }
  .bootstrap-override .pl-0,
  .bootstrap-override .px-0 {
    padding-left: 0 !important; }
  .bootstrap-override .p-1 {
    padding: 0.25rem !important; }
  .bootstrap-override .pt-1,
  .bootstrap-override .py-1 {
    padding-top: 0.25rem !important; }
  .bootstrap-override .pr-1,
  .bootstrap-override .px-1 {
    padding-right: 0.25rem !important; }
  .bootstrap-override .pb-1,
  .bootstrap-override .py-1 {
    padding-bottom: 0.25rem !important; }
  .bootstrap-override .pl-1,
  .bootstrap-override .px-1 {
    padding-left: 0.25rem !important; }
  .bootstrap-override .p-2 {
    padding: 0.5rem !important; }
  .bootstrap-override .pt-2,
  .bootstrap-override .py-2 {
    padding-top: 0.5rem !important; }
  .bootstrap-override .pr-2,
  .bootstrap-override .px-2 {
    padding-right: 0.5rem !important; }
  .bootstrap-override .pb-2,
  .bootstrap-override .py-2 {
    padding-bottom: 0.5rem !important; }
  .bootstrap-override .pl-2,
  .bootstrap-override .px-2 {
    padding-left: 0.5rem !important; }
  .bootstrap-override .p-3 {
    padding: 1rem !important; }
  .bootstrap-override .pt-3,
  .bootstrap-override .py-3 {
    padding-top: 1rem !important; }
  .bootstrap-override .pr-3,
  .bootstrap-override .px-3 {
    padding-right: 1rem !important; }
  .bootstrap-override .pb-3,
  .bootstrap-override .py-3 {
    padding-bottom: 1rem !important; }
  .bootstrap-override .pl-3,
  .bootstrap-override .px-3 {
    padding-left: 1rem !important; }
  .bootstrap-override .p-4 {
    padding: 1.5rem !important; }
  .bootstrap-override .pt-4,
  .bootstrap-override .py-4 {
    padding-top: 1.5rem !important; }
  .bootstrap-override .pr-4,
  .bootstrap-override .px-4 {
    padding-right: 1.5rem !important; }
  .bootstrap-override .pb-4,
  .bootstrap-override .py-4 {
    padding-bottom: 1.5rem !important; }
  .bootstrap-override .pl-4,
  .bootstrap-override .px-4 {
    padding-left: 1.5rem !important; }
  .bootstrap-override .p-5 {
    padding: 3rem !important; }
  .bootstrap-override .pt-5,
  .bootstrap-override .py-5 {
    padding-top: 3rem !important; }
  .bootstrap-override .pr-5,
  .bootstrap-override .px-5 {
    padding-right: 3rem !important; }
  .bootstrap-override .pb-5,
  .bootstrap-override .py-5 {
    padding-bottom: 3rem !important; }
  .bootstrap-override .pl-5,
  .bootstrap-override .px-5 {
    padding-left: 3rem !important; }
  .bootstrap-override .m-n1 {
    margin: -0.25rem !important; }
  .bootstrap-override .mt-n1,
  .bootstrap-override .my-n1 {
    margin-top: -0.25rem !important; }
  .bootstrap-override .mr-n1,
  .bootstrap-override .mx-n1 {
    margin-right: -0.25rem !important; }
  .bootstrap-override .mb-n1,
  .bootstrap-override .my-n1 {
    margin-bottom: -0.25rem !important; }
  .bootstrap-override .ml-n1,
  .bootstrap-override .mx-n1 {
    margin-left: -0.25rem !important; }
  .bootstrap-override .m-n2 {
    margin: -0.5rem !important; }
  .bootstrap-override .mt-n2,
  .bootstrap-override .my-n2 {
    margin-top: -0.5rem !important; }
  .bootstrap-override .mr-n2,
  .bootstrap-override .mx-n2 {
    margin-right: -0.5rem !important; }
  .bootstrap-override .mb-n2,
  .bootstrap-override .my-n2 {
    margin-bottom: -0.5rem !important; }
  .bootstrap-override .ml-n2,
  .bootstrap-override .mx-n2 {
    margin-left: -0.5rem !important; }
  .bootstrap-override .m-n3 {
    margin: -1rem !important; }
  .bootstrap-override .mt-n3,
  .bootstrap-override .my-n3 {
    margin-top: -1rem !important; }
  .bootstrap-override .mr-n3,
  .bootstrap-override .mx-n3 {
    margin-right: -1rem !important; }
  .bootstrap-override .mb-n3,
  .bootstrap-override .my-n3 {
    margin-bottom: -1rem !important; }
  .bootstrap-override .ml-n3,
  .bootstrap-override .mx-n3 {
    margin-left: -1rem !important; }
  .bootstrap-override .m-n4 {
    margin: -1.5rem !important; }
  .bootstrap-override .mt-n4,
  .bootstrap-override .my-n4 {
    margin-top: -1.5rem !important; }
  .bootstrap-override .mr-n4,
  .bootstrap-override .mx-n4 {
    margin-right: -1.5rem !important; }
  .bootstrap-override .mb-n4,
  .bootstrap-override .my-n4 {
    margin-bottom: -1.5rem !important; }
  .bootstrap-override .ml-n4,
  .bootstrap-override .mx-n4 {
    margin-left: -1.5rem !important; }
  .bootstrap-override .m-n5 {
    margin: -3rem !important; }
  .bootstrap-override .mt-n5,
  .bootstrap-override .my-n5 {
    margin-top: -3rem !important; }
  .bootstrap-override .mr-n5,
  .bootstrap-override .mx-n5 {
    margin-right: -3rem !important; }
  .bootstrap-override .mb-n5,
  .bootstrap-override .my-n5 {
    margin-bottom: -3rem !important; }
  .bootstrap-override .ml-n5,
  .bootstrap-override .mx-n5 {
    margin-left: -3rem !important; }
  .bootstrap-override .m-auto {
    margin: auto !important; }
  .bootstrap-override .mt-auto,
  .bootstrap-override .my-auto {
    margin-top: auto !important; }
  .bootstrap-override .mr-auto,
  .bootstrap-override .mx-auto {
    margin-right: auto !important; }
  .bootstrap-override .mb-auto,
  .bootstrap-override .my-auto {
    margin-bottom: auto !important; }
  .bootstrap-override .ml-auto,
  .bootstrap-override .mx-auto {
    margin-left: auto !important; }
  @media (min-width: 576px) {
    .bootstrap-override .m-sm-0 {
      margin: 0 !important; }
    .bootstrap-override .mt-sm-0,
    .bootstrap-override .my-sm-0 {
      margin-top: 0 !important; }
    .bootstrap-override .mr-sm-0,
    .bootstrap-override .mx-sm-0 {
      margin-right: 0 !important; }
    .bootstrap-override .mb-sm-0,
    .bootstrap-override .my-sm-0 {
      margin-bottom: 0 !important; }
    .bootstrap-override .ml-sm-0,
    .bootstrap-override .mx-sm-0 {
      margin-left: 0 !important; }
    .bootstrap-override .m-sm-1 {
      margin: 0.25rem !important; }
    .bootstrap-override .mt-sm-1,
    .bootstrap-override .my-sm-1 {
      margin-top: 0.25rem !important; }
    .bootstrap-override .mr-sm-1,
    .bootstrap-override .mx-sm-1 {
      margin-right: 0.25rem !important; }
    .bootstrap-override .mb-sm-1,
    .bootstrap-override .my-sm-1 {
      margin-bottom: 0.25rem !important; }
    .bootstrap-override .ml-sm-1,
    .bootstrap-override .mx-sm-1 {
      margin-left: 0.25rem !important; }
    .bootstrap-override .m-sm-2 {
      margin: 0.5rem !important; }
    .bootstrap-override .mt-sm-2,
    .bootstrap-override .my-sm-2 {
      margin-top: 0.5rem !important; }
    .bootstrap-override .mr-sm-2,
    .bootstrap-override .mx-sm-2 {
      margin-right: 0.5rem !important; }
    .bootstrap-override .mb-sm-2,
    .bootstrap-override .my-sm-2 {
      margin-bottom: 0.5rem !important; }
    .bootstrap-override .ml-sm-2,
    .bootstrap-override .mx-sm-2 {
      margin-left: 0.5rem !important; }
    .bootstrap-override .m-sm-3 {
      margin: 1rem !important; }
    .bootstrap-override .mt-sm-3,
    .bootstrap-override .my-sm-3 {
      margin-top: 1rem !important; }
    .bootstrap-override .mr-sm-3,
    .bootstrap-override .mx-sm-3 {
      margin-right: 1rem !important; }
    .bootstrap-override .mb-sm-3,
    .bootstrap-override .my-sm-3 {
      margin-bottom: 1rem !important; }
    .bootstrap-override .ml-sm-3,
    .bootstrap-override .mx-sm-3 {
      margin-left: 1rem !important; }
    .bootstrap-override .m-sm-4 {
      margin: 1.5rem !important; }
    .bootstrap-override .mt-sm-4,
    .bootstrap-override .my-sm-4 {
      margin-top: 1.5rem !important; }
    .bootstrap-override .mr-sm-4,
    .bootstrap-override .mx-sm-4 {
      margin-right: 1.5rem !important; }
    .bootstrap-override .mb-sm-4,
    .bootstrap-override .my-sm-4 {
      margin-bottom: 1.5rem !important; }
    .bootstrap-override .ml-sm-4,
    .bootstrap-override .mx-sm-4 {
      margin-left: 1.5rem !important; }
    .bootstrap-override .m-sm-5 {
      margin: 3rem !important; }
    .bootstrap-override .mt-sm-5,
    .bootstrap-override .my-sm-5 {
      margin-top: 3rem !important; }
    .bootstrap-override .mr-sm-5,
    .bootstrap-override .mx-sm-5 {
      margin-right: 3rem !important; }
    .bootstrap-override .mb-sm-5,
    .bootstrap-override .my-sm-5 {
      margin-bottom: 3rem !important; }
    .bootstrap-override .ml-sm-5,
    .bootstrap-override .mx-sm-5 {
      margin-left: 3rem !important; }
    .bootstrap-override .p-sm-0 {
      padding: 0 !important; }
    .bootstrap-override .pt-sm-0,
    .bootstrap-override .py-sm-0 {
      padding-top: 0 !important; }
    .bootstrap-override .pr-sm-0,
    .bootstrap-override .px-sm-0 {
      padding-right: 0 !important; }
    .bootstrap-override .pb-sm-0,
    .bootstrap-override .py-sm-0 {
      padding-bottom: 0 !important; }
    .bootstrap-override .pl-sm-0,
    .bootstrap-override .px-sm-0 {
      padding-left: 0 !important; }
    .bootstrap-override .p-sm-1 {
      padding: 0.25rem !important; }
    .bootstrap-override .pt-sm-1,
    .bootstrap-override .py-sm-1 {
      padding-top: 0.25rem !important; }
    .bootstrap-override .pr-sm-1,
    .bootstrap-override .px-sm-1 {
      padding-right: 0.25rem !important; }
    .bootstrap-override .pb-sm-1,
    .bootstrap-override .py-sm-1 {
      padding-bottom: 0.25rem !important; }
    .bootstrap-override .pl-sm-1,
    .bootstrap-override .px-sm-1 {
      padding-left: 0.25rem !important; }
    .bootstrap-override .p-sm-2 {
      padding: 0.5rem !important; }
    .bootstrap-override .pt-sm-2,
    .bootstrap-override .py-sm-2 {
      padding-top: 0.5rem !important; }
    .bootstrap-override .pr-sm-2,
    .bootstrap-override .px-sm-2 {
      padding-right: 0.5rem !important; }
    .bootstrap-override .pb-sm-2,
    .bootstrap-override .py-sm-2 {
      padding-bottom: 0.5rem !important; }
    .bootstrap-override .pl-sm-2,
    .bootstrap-override .px-sm-2 {
      padding-left: 0.5rem !important; }
    .bootstrap-override .p-sm-3 {
      padding: 1rem !important; }
    .bootstrap-override .pt-sm-3,
    .bootstrap-override .py-sm-3 {
      padding-top: 1rem !important; }
    .bootstrap-override .pr-sm-3,
    .bootstrap-override .px-sm-3 {
      padding-right: 1rem !important; }
    .bootstrap-override .pb-sm-3,
    .bootstrap-override .py-sm-3 {
      padding-bottom: 1rem !important; }
    .bootstrap-override .pl-sm-3,
    .bootstrap-override .px-sm-3 {
      padding-left: 1rem !important; }
    .bootstrap-override .p-sm-4 {
      padding: 1.5rem !important; }
    .bootstrap-override .pt-sm-4,
    .bootstrap-override .py-sm-4 {
      padding-top: 1.5rem !important; }
    .bootstrap-override .pr-sm-4,
    .bootstrap-override .px-sm-4 {
      padding-right: 1.5rem !important; }
    .bootstrap-override .pb-sm-4,
    .bootstrap-override .py-sm-4 {
      padding-bottom: 1.5rem !important; }
    .bootstrap-override .pl-sm-4,
    .bootstrap-override .px-sm-4 {
      padding-left: 1.5rem !important; }
    .bootstrap-override .p-sm-5 {
      padding: 3rem !important; }
    .bootstrap-override .pt-sm-5,
    .bootstrap-override .py-sm-5 {
      padding-top: 3rem !important; }
    .bootstrap-override .pr-sm-5,
    .bootstrap-override .px-sm-5 {
      padding-right: 3rem !important; }
    .bootstrap-override .pb-sm-5,
    .bootstrap-override .py-sm-5 {
      padding-bottom: 3rem !important; }
    .bootstrap-override .pl-sm-5,
    .bootstrap-override .px-sm-5 {
      padding-left: 3rem !important; }
    .bootstrap-override .m-sm-n1 {
      margin: -0.25rem !important; }
    .bootstrap-override .mt-sm-n1,
    .bootstrap-override .my-sm-n1 {
      margin-top: -0.25rem !important; }
    .bootstrap-override .mr-sm-n1,
    .bootstrap-override .mx-sm-n1 {
      margin-right: -0.25rem !important; }
    .bootstrap-override .mb-sm-n1,
    .bootstrap-override .my-sm-n1 {
      margin-bottom: -0.25rem !important; }
    .bootstrap-override .ml-sm-n1,
    .bootstrap-override .mx-sm-n1 {
      margin-left: -0.25rem !important; }
    .bootstrap-override .m-sm-n2 {
      margin: -0.5rem !important; }
    .bootstrap-override .mt-sm-n2,
    .bootstrap-override .my-sm-n2 {
      margin-top: -0.5rem !important; }
    .bootstrap-override .mr-sm-n2,
    .bootstrap-override .mx-sm-n2 {
      margin-right: -0.5rem !important; }
    .bootstrap-override .mb-sm-n2,
    .bootstrap-override .my-sm-n2 {
      margin-bottom: -0.5rem !important; }
    .bootstrap-override .ml-sm-n2,
    .bootstrap-override .mx-sm-n2 {
      margin-left: -0.5rem !important; }
    .bootstrap-override .m-sm-n3 {
      margin: -1rem !important; }
    .bootstrap-override .mt-sm-n3,
    .bootstrap-override .my-sm-n3 {
      margin-top: -1rem !important; }
    .bootstrap-override .mr-sm-n3,
    .bootstrap-override .mx-sm-n3 {
      margin-right: -1rem !important; }
    .bootstrap-override .mb-sm-n3,
    .bootstrap-override .my-sm-n3 {
      margin-bottom: -1rem !important; }
    .bootstrap-override .ml-sm-n3,
    .bootstrap-override .mx-sm-n3 {
      margin-left: -1rem !important; }
    .bootstrap-override .m-sm-n4 {
      margin: -1.5rem !important; }
    .bootstrap-override .mt-sm-n4,
    .bootstrap-override .my-sm-n4 {
      margin-top: -1.5rem !important; }
    .bootstrap-override .mr-sm-n4,
    .bootstrap-override .mx-sm-n4 {
      margin-right: -1.5rem !important; }
    .bootstrap-override .mb-sm-n4,
    .bootstrap-override .my-sm-n4 {
      margin-bottom: -1.5rem !important; }
    .bootstrap-override .ml-sm-n4,
    .bootstrap-override .mx-sm-n4 {
      margin-left: -1.5rem !important; }
    .bootstrap-override .m-sm-n5 {
      margin: -3rem !important; }
    .bootstrap-override .mt-sm-n5,
    .bootstrap-override .my-sm-n5 {
      margin-top: -3rem !important; }
    .bootstrap-override .mr-sm-n5,
    .bootstrap-override .mx-sm-n5 {
      margin-right: -3rem !important; }
    .bootstrap-override .mb-sm-n5,
    .bootstrap-override .my-sm-n5 {
      margin-bottom: -3rem !important; }
    .bootstrap-override .ml-sm-n5,
    .bootstrap-override .mx-sm-n5 {
      margin-left: -3rem !important; }
    .bootstrap-override .m-sm-auto {
      margin: auto !important; }
    .bootstrap-override .mt-sm-auto,
    .bootstrap-override .my-sm-auto {
      margin-top: auto !important; }
    .bootstrap-override .mr-sm-auto,
    .bootstrap-override .mx-sm-auto {
      margin-right: auto !important; }
    .bootstrap-override .mb-sm-auto,
    .bootstrap-override .my-sm-auto {
      margin-bottom: auto !important; }
    .bootstrap-override .ml-sm-auto,
    .bootstrap-override .mx-sm-auto {
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    .bootstrap-override .m-md-0 {
      margin: 0 !important; }
    .bootstrap-override .mt-md-0,
    .bootstrap-override .my-md-0 {
      margin-top: 0 !important; }
    .bootstrap-override .mr-md-0,
    .bootstrap-override .mx-md-0 {
      margin-right: 0 !important; }
    .bootstrap-override .mb-md-0,
    .bootstrap-override .my-md-0 {
      margin-bottom: 0 !important; }
    .bootstrap-override .ml-md-0,
    .bootstrap-override .mx-md-0 {
      margin-left: 0 !important; }
    .bootstrap-override .m-md-1 {
      margin: 0.25rem !important; }
    .bootstrap-override .mt-md-1,
    .bootstrap-override .my-md-1 {
      margin-top: 0.25rem !important; }
    .bootstrap-override .mr-md-1,
    .bootstrap-override .mx-md-1 {
      margin-right: 0.25rem !important; }
    .bootstrap-override .mb-md-1,
    .bootstrap-override .my-md-1 {
      margin-bottom: 0.25rem !important; }
    .bootstrap-override .ml-md-1,
    .bootstrap-override .mx-md-1 {
      margin-left: 0.25rem !important; }
    .bootstrap-override .m-md-2 {
      margin: 0.5rem !important; }
    .bootstrap-override .mt-md-2,
    .bootstrap-override .my-md-2 {
      margin-top: 0.5rem !important; }
    .bootstrap-override .mr-md-2,
    .bootstrap-override .mx-md-2 {
      margin-right: 0.5rem !important; }
    .bootstrap-override .mb-md-2,
    .bootstrap-override .my-md-2 {
      margin-bottom: 0.5rem !important; }
    .bootstrap-override .ml-md-2,
    .bootstrap-override .mx-md-2 {
      margin-left: 0.5rem !important; }
    .bootstrap-override .m-md-3 {
      margin: 1rem !important; }
    .bootstrap-override .mt-md-3,
    .bootstrap-override .my-md-3 {
      margin-top: 1rem !important; }
    .bootstrap-override .mr-md-3,
    .bootstrap-override .mx-md-3 {
      margin-right: 1rem !important; }
    .bootstrap-override .mb-md-3,
    .bootstrap-override .my-md-3 {
      margin-bottom: 1rem !important; }
    .bootstrap-override .ml-md-3,
    .bootstrap-override .mx-md-3 {
      margin-left: 1rem !important; }
    .bootstrap-override .m-md-4 {
      margin: 1.5rem !important; }
    .bootstrap-override .mt-md-4,
    .bootstrap-override .my-md-4 {
      margin-top: 1.5rem !important; }
    .bootstrap-override .mr-md-4,
    .bootstrap-override .mx-md-4 {
      margin-right: 1.5rem !important; }
    .bootstrap-override .mb-md-4,
    .bootstrap-override .my-md-4 {
      margin-bottom: 1.5rem !important; }
    .bootstrap-override .ml-md-4,
    .bootstrap-override .mx-md-4 {
      margin-left: 1.5rem !important; }
    .bootstrap-override .m-md-5 {
      margin: 3rem !important; }
    .bootstrap-override .mt-md-5,
    .bootstrap-override .my-md-5 {
      margin-top: 3rem !important; }
    .bootstrap-override .mr-md-5,
    .bootstrap-override .mx-md-5 {
      margin-right: 3rem !important; }
    .bootstrap-override .mb-md-5,
    .bootstrap-override .my-md-5 {
      margin-bottom: 3rem !important; }
    .bootstrap-override .ml-md-5,
    .bootstrap-override .mx-md-5 {
      margin-left: 3rem !important; }
    .bootstrap-override .p-md-0 {
      padding: 0 !important; }
    .bootstrap-override .pt-md-0,
    .bootstrap-override .py-md-0 {
      padding-top: 0 !important; }
    .bootstrap-override .pr-md-0,
    .bootstrap-override .px-md-0 {
      padding-right: 0 !important; }
    .bootstrap-override .pb-md-0,
    .bootstrap-override .py-md-0 {
      padding-bottom: 0 !important; }
    .bootstrap-override .pl-md-0,
    .bootstrap-override .px-md-0 {
      padding-left: 0 !important; }
    .bootstrap-override .p-md-1 {
      padding: 0.25rem !important; }
    .bootstrap-override .pt-md-1,
    .bootstrap-override .py-md-1 {
      padding-top: 0.25rem !important; }
    .bootstrap-override .pr-md-1,
    .bootstrap-override .px-md-1 {
      padding-right: 0.25rem !important; }
    .bootstrap-override .pb-md-1,
    .bootstrap-override .py-md-1 {
      padding-bottom: 0.25rem !important; }
    .bootstrap-override .pl-md-1,
    .bootstrap-override .px-md-1 {
      padding-left: 0.25rem !important; }
    .bootstrap-override .p-md-2 {
      padding: 0.5rem !important; }
    .bootstrap-override .pt-md-2,
    .bootstrap-override .py-md-2 {
      padding-top: 0.5rem !important; }
    .bootstrap-override .pr-md-2,
    .bootstrap-override .px-md-2 {
      padding-right: 0.5rem !important; }
    .bootstrap-override .pb-md-2,
    .bootstrap-override .py-md-2 {
      padding-bottom: 0.5rem !important; }
    .bootstrap-override .pl-md-2,
    .bootstrap-override .px-md-2 {
      padding-left: 0.5rem !important; }
    .bootstrap-override .p-md-3 {
      padding: 1rem !important; }
    .bootstrap-override .pt-md-3,
    .bootstrap-override .py-md-3 {
      padding-top: 1rem !important; }
    .bootstrap-override .pr-md-3,
    .bootstrap-override .px-md-3 {
      padding-right: 1rem !important; }
    .bootstrap-override .pb-md-3,
    .bootstrap-override .py-md-3 {
      padding-bottom: 1rem !important; }
    .bootstrap-override .pl-md-3,
    .bootstrap-override .px-md-3 {
      padding-left: 1rem !important; }
    .bootstrap-override .p-md-4 {
      padding: 1.5rem !important; }
    .bootstrap-override .pt-md-4,
    .bootstrap-override .py-md-4 {
      padding-top: 1.5rem !important; }
    .bootstrap-override .pr-md-4,
    .bootstrap-override .px-md-4 {
      padding-right: 1.5rem !important; }
    .bootstrap-override .pb-md-4,
    .bootstrap-override .py-md-4 {
      padding-bottom: 1.5rem !important; }
    .bootstrap-override .pl-md-4,
    .bootstrap-override .px-md-4 {
      padding-left: 1.5rem !important; }
    .bootstrap-override .p-md-5 {
      padding: 3rem !important; }
    .bootstrap-override .pt-md-5,
    .bootstrap-override .py-md-5 {
      padding-top: 3rem !important; }
    .bootstrap-override .pr-md-5,
    .bootstrap-override .px-md-5 {
      padding-right: 3rem !important; }
    .bootstrap-override .pb-md-5,
    .bootstrap-override .py-md-5 {
      padding-bottom: 3rem !important; }
    .bootstrap-override .pl-md-5,
    .bootstrap-override .px-md-5 {
      padding-left: 3rem !important; }
    .bootstrap-override .m-md-n1 {
      margin: -0.25rem !important; }
    .bootstrap-override .mt-md-n1,
    .bootstrap-override .my-md-n1 {
      margin-top: -0.25rem !important; }
    .bootstrap-override .mr-md-n1,
    .bootstrap-override .mx-md-n1 {
      margin-right: -0.25rem !important; }
    .bootstrap-override .mb-md-n1,
    .bootstrap-override .my-md-n1 {
      margin-bottom: -0.25rem !important; }
    .bootstrap-override .ml-md-n1,
    .bootstrap-override .mx-md-n1 {
      margin-left: -0.25rem !important; }
    .bootstrap-override .m-md-n2 {
      margin: -0.5rem !important; }
    .bootstrap-override .mt-md-n2,
    .bootstrap-override .my-md-n2 {
      margin-top: -0.5rem !important; }
    .bootstrap-override .mr-md-n2,
    .bootstrap-override .mx-md-n2 {
      margin-right: -0.5rem !important; }
    .bootstrap-override .mb-md-n2,
    .bootstrap-override .my-md-n2 {
      margin-bottom: -0.5rem !important; }
    .bootstrap-override .ml-md-n2,
    .bootstrap-override .mx-md-n2 {
      margin-left: -0.5rem !important; }
    .bootstrap-override .m-md-n3 {
      margin: -1rem !important; }
    .bootstrap-override .mt-md-n3,
    .bootstrap-override .my-md-n3 {
      margin-top: -1rem !important; }
    .bootstrap-override .mr-md-n3,
    .bootstrap-override .mx-md-n3 {
      margin-right: -1rem !important; }
    .bootstrap-override .mb-md-n3,
    .bootstrap-override .my-md-n3 {
      margin-bottom: -1rem !important; }
    .bootstrap-override .ml-md-n3,
    .bootstrap-override .mx-md-n3 {
      margin-left: -1rem !important; }
    .bootstrap-override .m-md-n4 {
      margin: -1.5rem !important; }
    .bootstrap-override .mt-md-n4,
    .bootstrap-override .my-md-n4 {
      margin-top: -1.5rem !important; }
    .bootstrap-override .mr-md-n4,
    .bootstrap-override .mx-md-n4 {
      margin-right: -1.5rem !important; }
    .bootstrap-override .mb-md-n4,
    .bootstrap-override .my-md-n4 {
      margin-bottom: -1.5rem !important; }
    .bootstrap-override .ml-md-n4,
    .bootstrap-override .mx-md-n4 {
      margin-left: -1.5rem !important; }
    .bootstrap-override .m-md-n5 {
      margin: -3rem !important; }
    .bootstrap-override .mt-md-n5,
    .bootstrap-override .my-md-n5 {
      margin-top: -3rem !important; }
    .bootstrap-override .mr-md-n5,
    .bootstrap-override .mx-md-n5 {
      margin-right: -3rem !important; }
    .bootstrap-override .mb-md-n5,
    .bootstrap-override .my-md-n5 {
      margin-bottom: -3rem !important; }
    .bootstrap-override .ml-md-n5,
    .bootstrap-override .mx-md-n5 {
      margin-left: -3rem !important; }
    .bootstrap-override .m-md-auto {
      margin: auto !important; }
    .bootstrap-override .mt-md-auto,
    .bootstrap-override .my-md-auto {
      margin-top: auto !important; }
    .bootstrap-override .mr-md-auto,
    .bootstrap-override .mx-md-auto {
      margin-right: auto !important; }
    .bootstrap-override .mb-md-auto,
    .bootstrap-override .my-md-auto {
      margin-bottom: auto !important; }
    .bootstrap-override .ml-md-auto,
    .bootstrap-override .mx-md-auto {
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .bootstrap-override .m-lg-0 {
      margin: 0 !important; }
    .bootstrap-override .mt-lg-0,
    .bootstrap-override .my-lg-0 {
      margin-top: 0 !important; }
    .bootstrap-override .mr-lg-0,
    .bootstrap-override .mx-lg-0 {
      margin-right: 0 !important; }
    .bootstrap-override .mb-lg-0,
    .bootstrap-override .my-lg-0 {
      margin-bottom: 0 !important; }
    .bootstrap-override .ml-lg-0,
    .bootstrap-override .mx-lg-0 {
      margin-left: 0 !important; }
    .bootstrap-override .m-lg-1 {
      margin: 0.25rem !important; }
    .bootstrap-override .mt-lg-1,
    .bootstrap-override .my-lg-1 {
      margin-top: 0.25rem !important; }
    .bootstrap-override .mr-lg-1,
    .bootstrap-override .mx-lg-1 {
      margin-right: 0.25rem !important; }
    .bootstrap-override .mb-lg-1,
    .bootstrap-override .my-lg-1 {
      margin-bottom: 0.25rem !important; }
    .bootstrap-override .ml-lg-1,
    .bootstrap-override .mx-lg-1 {
      margin-left: 0.25rem !important; }
    .bootstrap-override .m-lg-2 {
      margin: 0.5rem !important; }
    .bootstrap-override .mt-lg-2,
    .bootstrap-override .my-lg-2 {
      margin-top: 0.5rem !important; }
    .bootstrap-override .mr-lg-2,
    .bootstrap-override .mx-lg-2 {
      margin-right: 0.5rem !important; }
    .bootstrap-override .mb-lg-2,
    .bootstrap-override .my-lg-2 {
      margin-bottom: 0.5rem !important; }
    .bootstrap-override .ml-lg-2,
    .bootstrap-override .mx-lg-2 {
      margin-left: 0.5rem !important; }
    .bootstrap-override .m-lg-3 {
      margin: 1rem !important; }
    .bootstrap-override .mt-lg-3,
    .bootstrap-override .my-lg-3 {
      margin-top: 1rem !important; }
    .bootstrap-override .mr-lg-3,
    .bootstrap-override .mx-lg-3 {
      margin-right: 1rem !important; }
    .bootstrap-override .mb-lg-3,
    .bootstrap-override .my-lg-3 {
      margin-bottom: 1rem !important; }
    .bootstrap-override .ml-lg-3,
    .bootstrap-override .mx-lg-3 {
      margin-left: 1rem !important; }
    .bootstrap-override .m-lg-4 {
      margin: 1.5rem !important; }
    .bootstrap-override .mt-lg-4,
    .bootstrap-override .my-lg-4 {
      margin-top: 1.5rem !important; }
    .bootstrap-override .mr-lg-4,
    .bootstrap-override .mx-lg-4 {
      margin-right: 1.5rem !important; }
    .bootstrap-override .mb-lg-4,
    .bootstrap-override .my-lg-4 {
      margin-bottom: 1.5rem !important; }
    .bootstrap-override .ml-lg-4,
    .bootstrap-override .mx-lg-4 {
      margin-left: 1.5rem !important; }
    .bootstrap-override .m-lg-5 {
      margin: 3rem !important; }
    .bootstrap-override .mt-lg-5,
    .bootstrap-override .my-lg-5 {
      margin-top: 3rem !important; }
    .bootstrap-override .mr-lg-5,
    .bootstrap-override .mx-lg-5 {
      margin-right: 3rem !important; }
    .bootstrap-override .mb-lg-5,
    .bootstrap-override .my-lg-5 {
      margin-bottom: 3rem !important; }
    .bootstrap-override .ml-lg-5,
    .bootstrap-override .mx-lg-5 {
      margin-left: 3rem !important; }
    .bootstrap-override .p-lg-0 {
      padding: 0 !important; }
    .bootstrap-override .pt-lg-0,
    .bootstrap-override .py-lg-0 {
      padding-top: 0 !important; }
    .bootstrap-override .pr-lg-0,
    .bootstrap-override .px-lg-0 {
      padding-right: 0 !important; }
    .bootstrap-override .pb-lg-0,
    .bootstrap-override .py-lg-0 {
      padding-bottom: 0 !important; }
    .bootstrap-override .pl-lg-0,
    .bootstrap-override .px-lg-0 {
      padding-left: 0 !important; }
    .bootstrap-override .p-lg-1 {
      padding: 0.25rem !important; }
    .bootstrap-override .pt-lg-1,
    .bootstrap-override .py-lg-1 {
      padding-top: 0.25rem !important; }
    .bootstrap-override .pr-lg-1,
    .bootstrap-override .px-lg-1 {
      padding-right: 0.25rem !important; }
    .bootstrap-override .pb-lg-1,
    .bootstrap-override .py-lg-1 {
      padding-bottom: 0.25rem !important; }
    .bootstrap-override .pl-lg-1,
    .bootstrap-override .px-lg-1 {
      padding-left: 0.25rem !important; }
    .bootstrap-override .p-lg-2 {
      padding: 0.5rem !important; }
    .bootstrap-override .pt-lg-2,
    .bootstrap-override .py-lg-2 {
      padding-top: 0.5rem !important; }
    .bootstrap-override .pr-lg-2,
    .bootstrap-override .px-lg-2 {
      padding-right: 0.5rem !important; }
    .bootstrap-override .pb-lg-2,
    .bootstrap-override .py-lg-2 {
      padding-bottom: 0.5rem !important; }
    .bootstrap-override .pl-lg-2,
    .bootstrap-override .px-lg-2 {
      padding-left: 0.5rem !important; }
    .bootstrap-override .p-lg-3 {
      padding: 1rem !important; }
    .bootstrap-override .pt-lg-3,
    .bootstrap-override .py-lg-3 {
      padding-top: 1rem !important; }
    .bootstrap-override .pr-lg-3,
    .bootstrap-override .px-lg-3 {
      padding-right: 1rem !important; }
    .bootstrap-override .pb-lg-3,
    .bootstrap-override .py-lg-3 {
      padding-bottom: 1rem !important; }
    .bootstrap-override .pl-lg-3,
    .bootstrap-override .px-lg-3 {
      padding-left: 1rem !important; }
    .bootstrap-override .p-lg-4 {
      padding: 1.5rem !important; }
    .bootstrap-override .pt-lg-4,
    .bootstrap-override .py-lg-4 {
      padding-top: 1.5rem !important; }
    .bootstrap-override .pr-lg-4,
    .bootstrap-override .px-lg-4 {
      padding-right: 1.5rem !important; }
    .bootstrap-override .pb-lg-4,
    .bootstrap-override .py-lg-4 {
      padding-bottom: 1.5rem !important; }
    .bootstrap-override .pl-lg-4,
    .bootstrap-override .px-lg-4 {
      padding-left: 1.5rem !important; }
    .bootstrap-override .p-lg-5 {
      padding: 3rem !important; }
    .bootstrap-override .pt-lg-5,
    .bootstrap-override .py-lg-5 {
      padding-top: 3rem !important; }
    .bootstrap-override .pr-lg-5,
    .bootstrap-override .px-lg-5 {
      padding-right: 3rem !important; }
    .bootstrap-override .pb-lg-5,
    .bootstrap-override .py-lg-5 {
      padding-bottom: 3rem !important; }
    .bootstrap-override .pl-lg-5,
    .bootstrap-override .px-lg-5 {
      padding-left: 3rem !important; }
    .bootstrap-override .m-lg-n1 {
      margin: -0.25rem !important; }
    .bootstrap-override .mt-lg-n1,
    .bootstrap-override .my-lg-n1 {
      margin-top: -0.25rem !important; }
    .bootstrap-override .mr-lg-n1,
    .bootstrap-override .mx-lg-n1 {
      margin-right: -0.25rem !important; }
    .bootstrap-override .mb-lg-n1,
    .bootstrap-override .my-lg-n1 {
      margin-bottom: -0.25rem !important; }
    .bootstrap-override .ml-lg-n1,
    .bootstrap-override .mx-lg-n1 {
      margin-left: -0.25rem !important; }
    .bootstrap-override .m-lg-n2 {
      margin: -0.5rem !important; }
    .bootstrap-override .mt-lg-n2,
    .bootstrap-override .my-lg-n2 {
      margin-top: -0.5rem !important; }
    .bootstrap-override .mr-lg-n2,
    .bootstrap-override .mx-lg-n2 {
      margin-right: -0.5rem !important; }
    .bootstrap-override .mb-lg-n2,
    .bootstrap-override .my-lg-n2 {
      margin-bottom: -0.5rem !important; }
    .bootstrap-override .ml-lg-n2,
    .bootstrap-override .mx-lg-n2 {
      margin-left: -0.5rem !important; }
    .bootstrap-override .m-lg-n3 {
      margin: -1rem !important; }
    .bootstrap-override .mt-lg-n3,
    .bootstrap-override .my-lg-n3 {
      margin-top: -1rem !important; }
    .bootstrap-override .mr-lg-n3,
    .bootstrap-override .mx-lg-n3 {
      margin-right: -1rem !important; }
    .bootstrap-override .mb-lg-n3,
    .bootstrap-override .my-lg-n3 {
      margin-bottom: -1rem !important; }
    .bootstrap-override .ml-lg-n3,
    .bootstrap-override .mx-lg-n3 {
      margin-left: -1rem !important; }
    .bootstrap-override .m-lg-n4 {
      margin: -1.5rem !important; }
    .bootstrap-override .mt-lg-n4,
    .bootstrap-override .my-lg-n4 {
      margin-top: -1.5rem !important; }
    .bootstrap-override .mr-lg-n4,
    .bootstrap-override .mx-lg-n4 {
      margin-right: -1.5rem !important; }
    .bootstrap-override .mb-lg-n4,
    .bootstrap-override .my-lg-n4 {
      margin-bottom: -1.5rem !important; }
    .bootstrap-override .ml-lg-n4,
    .bootstrap-override .mx-lg-n4 {
      margin-left: -1.5rem !important; }
    .bootstrap-override .m-lg-n5 {
      margin: -3rem !important; }
    .bootstrap-override .mt-lg-n5,
    .bootstrap-override .my-lg-n5 {
      margin-top: -3rem !important; }
    .bootstrap-override .mr-lg-n5,
    .bootstrap-override .mx-lg-n5 {
      margin-right: -3rem !important; }
    .bootstrap-override .mb-lg-n5,
    .bootstrap-override .my-lg-n5 {
      margin-bottom: -3rem !important; }
    .bootstrap-override .ml-lg-n5,
    .bootstrap-override .mx-lg-n5 {
      margin-left: -3rem !important; }
    .bootstrap-override .m-lg-auto {
      margin: auto !important; }
    .bootstrap-override .mt-lg-auto,
    .bootstrap-override .my-lg-auto {
      margin-top: auto !important; }
    .bootstrap-override .mr-lg-auto,
    .bootstrap-override .mx-lg-auto {
      margin-right: auto !important; }
    .bootstrap-override .mb-lg-auto,
    .bootstrap-override .my-lg-auto {
      margin-bottom: auto !important; }
    .bootstrap-override .ml-lg-auto,
    .bootstrap-override .mx-lg-auto {
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .bootstrap-override .m-xl-0 {
      margin: 0 !important; }
    .bootstrap-override .mt-xl-0,
    .bootstrap-override .my-xl-0 {
      margin-top: 0 !important; }
    .bootstrap-override .mr-xl-0,
    .bootstrap-override .mx-xl-0 {
      margin-right: 0 !important; }
    .bootstrap-override .mb-xl-0,
    .bootstrap-override .my-xl-0 {
      margin-bottom: 0 !important; }
    .bootstrap-override .ml-xl-0,
    .bootstrap-override .mx-xl-0 {
      margin-left: 0 !important; }
    .bootstrap-override .m-xl-1 {
      margin: 0.25rem !important; }
    .bootstrap-override .mt-xl-1,
    .bootstrap-override .my-xl-1 {
      margin-top: 0.25rem !important; }
    .bootstrap-override .mr-xl-1,
    .bootstrap-override .mx-xl-1 {
      margin-right: 0.25rem !important; }
    .bootstrap-override .mb-xl-1,
    .bootstrap-override .my-xl-1 {
      margin-bottom: 0.25rem !important; }
    .bootstrap-override .ml-xl-1,
    .bootstrap-override .mx-xl-1 {
      margin-left: 0.25rem !important; }
    .bootstrap-override .m-xl-2 {
      margin: 0.5rem !important; }
    .bootstrap-override .mt-xl-2,
    .bootstrap-override .my-xl-2 {
      margin-top: 0.5rem !important; }
    .bootstrap-override .mr-xl-2,
    .bootstrap-override .mx-xl-2 {
      margin-right: 0.5rem !important; }
    .bootstrap-override .mb-xl-2,
    .bootstrap-override .my-xl-2 {
      margin-bottom: 0.5rem !important; }
    .bootstrap-override .ml-xl-2,
    .bootstrap-override .mx-xl-2 {
      margin-left: 0.5rem !important; }
    .bootstrap-override .m-xl-3 {
      margin: 1rem !important; }
    .bootstrap-override .mt-xl-3,
    .bootstrap-override .my-xl-3 {
      margin-top: 1rem !important; }
    .bootstrap-override .mr-xl-3,
    .bootstrap-override .mx-xl-3 {
      margin-right: 1rem !important; }
    .bootstrap-override .mb-xl-3,
    .bootstrap-override .my-xl-3 {
      margin-bottom: 1rem !important; }
    .bootstrap-override .ml-xl-3,
    .bootstrap-override .mx-xl-3 {
      margin-left: 1rem !important; }
    .bootstrap-override .m-xl-4 {
      margin: 1.5rem !important; }
    .bootstrap-override .mt-xl-4,
    .bootstrap-override .my-xl-4 {
      margin-top: 1.5rem !important; }
    .bootstrap-override .mr-xl-4,
    .bootstrap-override .mx-xl-4 {
      margin-right: 1.5rem !important; }
    .bootstrap-override .mb-xl-4,
    .bootstrap-override .my-xl-4 {
      margin-bottom: 1.5rem !important; }
    .bootstrap-override .ml-xl-4,
    .bootstrap-override .mx-xl-4 {
      margin-left: 1.5rem !important; }
    .bootstrap-override .m-xl-5 {
      margin: 3rem !important; }
    .bootstrap-override .mt-xl-5,
    .bootstrap-override .my-xl-5 {
      margin-top: 3rem !important; }
    .bootstrap-override .mr-xl-5,
    .bootstrap-override .mx-xl-5 {
      margin-right: 3rem !important; }
    .bootstrap-override .mb-xl-5,
    .bootstrap-override .my-xl-5 {
      margin-bottom: 3rem !important; }
    .bootstrap-override .ml-xl-5,
    .bootstrap-override .mx-xl-5 {
      margin-left: 3rem !important; }
    .bootstrap-override .p-xl-0 {
      padding: 0 !important; }
    .bootstrap-override .pt-xl-0,
    .bootstrap-override .py-xl-0 {
      padding-top: 0 !important; }
    .bootstrap-override .pr-xl-0,
    .bootstrap-override .px-xl-0 {
      padding-right: 0 !important; }
    .bootstrap-override .pb-xl-0,
    .bootstrap-override .py-xl-0 {
      padding-bottom: 0 !important; }
    .bootstrap-override .pl-xl-0,
    .bootstrap-override .px-xl-0 {
      padding-left: 0 !important; }
    .bootstrap-override .p-xl-1 {
      padding: 0.25rem !important; }
    .bootstrap-override .pt-xl-1,
    .bootstrap-override .py-xl-1 {
      padding-top: 0.25rem !important; }
    .bootstrap-override .pr-xl-1,
    .bootstrap-override .px-xl-1 {
      padding-right: 0.25rem !important; }
    .bootstrap-override .pb-xl-1,
    .bootstrap-override .py-xl-1 {
      padding-bottom: 0.25rem !important; }
    .bootstrap-override .pl-xl-1,
    .bootstrap-override .px-xl-1 {
      padding-left: 0.25rem !important; }
    .bootstrap-override .p-xl-2 {
      padding: 0.5rem !important; }
    .bootstrap-override .pt-xl-2,
    .bootstrap-override .py-xl-2 {
      padding-top: 0.5rem !important; }
    .bootstrap-override .pr-xl-2,
    .bootstrap-override .px-xl-2 {
      padding-right: 0.5rem !important; }
    .bootstrap-override .pb-xl-2,
    .bootstrap-override .py-xl-2 {
      padding-bottom: 0.5rem !important; }
    .bootstrap-override .pl-xl-2,
    .bootstrap-override .px-xl-2 {
      padding-left: 0.5rem !important; }
    .bootstrap-override .p-xl-3 {
      padding: 1rem !important; }
    .bootstrap-override .pt-xl-3,
    .bootstrap-override .py-xl-3 {
      padding-top: 1rem !important; }
    .bootstrap-override .pr-xl-3,
    .bootstrap-override .px-xl-3 {
      padding-right: 1rem !important; }
    .bootstrap-override .pb-xl-3,
    .bootstrap-override .py-xl-3 {
      padding-bottom: 1rem !important; }
    .bootstrap-override .pl-xl-3,
    .bootstrap-override .px-xl-3 {
      padding-left: 1rem !important; }
    .bootstrap-override .p-xl-4 {
      padding: 1.5rem !important; }
    .bootstrap-override .pt-xl-4,
    .bootstrap-override .py-xl-4 {
      padding-top: 1.5rem !important; }
    .bootstrap-override .pr-xl-4,
    .bootstrap-override .px-xl-4 {
      padding-right: 1.5rem !important; }
    .bootstrap-override .pb-xl-4,
    .bootstrap-override .py-xl-4 {
      padding-bottom: 1.5rem !important; }
    .bootstrap-override .pl-xl-4,
    .bootstrap-override .px-xl-4 {
      padding-left: 1.5rem !important; }
    .bootstrap-override .p-xl-5 {
      padding: 3rem !important; }
    .bootstrap-override .pt-xl-5,
    .bootstrap-override .py-xl-5 {
      padding-top: 3rem !important; }
    .bootstrap-override .pr-xl-5,
    .bootstrap-override .px-xl-5 {
      padding-right: 3rem !important; }
    .bootstrap-override .pb-xl-5,
    .bootstrap-override .py-xl-5 {
      padding-bottom: 3rem !important; }
    .bootstrap-override .pl-xl-5,
    .bootstrap-override .px-xl-5 {
      padding-left: 3rem !important; }
    .bootstrap-override .m-xl-n1 {
      margin: -0.25rem !important; }
    .bootstrap-override .mt-xl-n1,
    .bootstrap-override .my-xl-n1 {
      margin-top: -0.25rem !important; }
    .bootstrap-override .mr-xl-n1,
    .bootstrap-override .mx-xl-n1 {
      margin-right: -0.25rem !important; }
    .bootstrap-override .mb-xl-n1,
    .bootstrap-override .my-xl-n1 {
      margin-bottom: -0.25rem !important; }
    .bootstrap-override .ml-xl-n1,
    .bootstrap-override .mx-xl-n1 {
      margin-left: -0.25rem !important; }
    .bootstrap-override .m-xl-n2 {
      margin: -0.5rem !important; }
    .bootstrap-override .mt-xl-n2,
    .bootstrap-override .my-xl-n2 {
      margin-top: -0.5rem !important; }
    .bootstrap-override .mr-xl-n2,
    .bootstrap-override .mx-xl-n2 {
      margin-right: -0.5rem !important; }
    .bootstrap-override .mb-xl-n2,
    .bootstrap-override .my-xl-n2 {
      margin-bottom: -0.5rem !important; }
    .bootstrap-override .ml-xl-n2,
    .bootstrap-override .mx-xl-n2 {
      margin-left: -0.5rem !important; }
    .bootstrap-override .m-xl-n3 {
      margin: -1rem !important; }
    .bootstrap-override .mt-xl-n3,
    .bootstrap-override .my-xl-n3 {
      margin-top: -1rem !important; }
    .bootstrap-override .mr-xl-n3,
    .bootstrap-override .mx-xl-n3 {
      margin-right: -1rem !important; }
    .bootstrap-override .mb-xl-n3,
    .bootstrap-override .my-xl-n3 {
      margin-bottom: -1rem !important; }
    .bootstrap-override .ml-xl-n3,
    .bootstrap-override .mx-xl-n3 {
      margin-left: -1rem !important; }
    .bootstrap-override .m-xl-n4 {
      margin: -1.5rem !important; }
    .bootstrap-override .mt-xl-n4,
    .bootstrap-override .my-xl-n4 {
      margin-top: -1.5rem !important; }
    .bootstrap-override .mr-xl-n4,
    .bootstrap-override .mx-xl-n4 {
      margin-right: -1.5rem !important; }
    .bootstrap-override .mb-xl-n4,
    .bootstrap-override .my-xl-n4 {
      margin-bottom: -1.5rem !important; }
    .bootstrap-override .ml-xl-n4,
    .bootstrap-override .mx-xl-n4 {
      margin-left: -1.5rem !important; }
    .bootstrap-override .m-xl-n5 {
      margin: -3rem !important; }
    .bootstrap-override .mt-xl-n5,
    .bootstrap-override .my-xl-n5 {
      margin-top: -3rem !important; }
    .bootstrap-override .mr-xl-n5,
    .bootstrap-override .mx-xl-n5 {
      margin-right: -3rem !important; }
    .bootstrap-override .mb-xl-n5,
    .bootstrap-override .my-xl-n5 {
      margin-bottom: -3rem !important; }
    .bootstrap-override .ml-xl-n5,
    .bootstrap-override .mx-xl-n5 {
      margin-left: -3rem !important; }
    .bootstrap-override .m-xl-auto {
      margin: auto !important; }
    .bootstrap-override .mt-xl-auto,
    .bootstrap-override .my-xl-auto {
      margin-top: auto !important; }
    .bootstrap-override .mr-xl-auto,
    .bootstrap-override .mx-xl-auto {
      margin-right: auto !important; }
    .bootstrap-override .mb-xl-auto,
    .bootstrap-override .my-xl-auto {
      margin-bottom: auto !important; }
    .bootstrap-override .ml-xl-auto,
    .bootstrap-override .mx-xl-auto {
      margin-left: auto !important; } }
  .bootstrap-override .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0); }
  .bootstrap-override .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }
  .bootstrap-override .text-justify {
    text-align: justify !important; }
  .bootstrap-override .text-wrap {
    white-space: normal !important; }
  .bootstrap-override .text-nowrap {
    white-space: nowrap !important; }
  .bootstrap-override .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .bootstrap-override .text-left {
    text-align: left !important; }
  .bootstrap-override .text-right {
    text-align: right !important; }
  .bootstrap-override .text-center {
    text-align: center !important; }
  @media (min-width: 576px) {
    .bootstrap-override .text-sm-left {
      text-align: left !important; }
    .bootstrap-override .text-sm-right {
      text-align: right !important; }
    .bootstrap-override .text-sm-center {
      text-align: center !important; } }
  @media (min-width: 768px) {
    .bootstrap-override .text-md-left {
      text-align: left !important; }
    .bootstrap-override .text-md-right {
      text-align: right !important; }
    .bootstrap-override .text-md-center {
      text-align: center !important; } }
  @media (min-width: 992px) {
    .bootstrap-override .text-lg-left {
      text-align: left !important; }
    .bootstrap-override .text-lg-right {
      text-align: right !important; }
    .bootstrap-override .text-lg-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    .bootstrap-override .text-xl-left {
      text-align: left !important; }
    .bootstrap-override .text-xl-right {
      text-align: right !important; }
    .bootstrap-override .text-xl-center {
      text-align: center !important; } }
  .bootstrap-override .text-lowercase {
    text-transform: lowercase !important; }
  .bootstrap-override .text-uppercase {
    text-transform: uppercase !important; }
  .bootstrap-override .text-capitalize {
    text-transform: capitalize !important; }
  .bootstrap-override .font-weight-light {
    font-weight: 300 !important; }
  .bootstrap-override .font-weight-lighter {
    font-weight: lighter !important; }
  .bootstrap-override .font-weight-normal {
    font-weight: 400 !important; }
  .bootstrap-override .font-weight-bold {
    font-weight: 700 !important; }
  .bootstrap-override .font-weight-bolder {
    font-weight: bolder !important; }
  .bootstrap-override .font-italic {
    font-style: italic !important; }
  .bootstrap-override .text-white {
    color: #fff !important; }
  .bootstrap-override .text-primary {
    color: #007bff !important; }
  .bootstrap-override a.text-primary:hover, .bootstrap-override a.text-primary:focus {
    color: #0056b3 !important; }
  .bootstrap-override .text-secondary {
    color: #6c757d !important; }
  .bootstrap-override a.text-secondary:hover, .bootstrap-override a.text-secondary:focus {
    color: #494f54 !important; }
  .bootstrap-override .text-success {
    color: #28a745 !important; }
  .bootstrap-override a.text-success:hover, .bootstrap-override a.text-success:focus {
    color: #19692c !important; }
  .bootstrap-override .text-info {
    color: #17a2b8 !important; }
  .bootstrap-override a.text-info:hover, .bootstrap-override a.text-info:focus {
    color: #0f6674 !important; }
  .bootstrap-override .text-warning {
    color: #ffc107 !important; }
  .bootstrap-override a.text-warning:hover, .bootstrap-override a.text-warning:focus {
    color: #ba8b00 !important; }
  .bootstrap-override .text-danger {
    color: #dc3545 !important; }
  .bootstrap-override a.text-danger:hover, .bootstrap-override a.text-danger:focus {
    color: #a71d2a !important; }
  .bootstrap-override .text-light {
    color: #f8f9fa !important; }
  .bootstrap-override a.text-light:hover, .bootstrap-override a.text-light:focus {
    color: #cbd3da !important; }
  .bootstrap-override .text-dark {
    color: #343a40 !important; }
  .bootstrap-override a.text-dark:hover, .bootstrap-override a.text-dark:focus {
    color: #121416 !important; }
  .bootstrap-override .text-body {
    color: #212529 !important; }
  .bootstrap-override .text-muted {
    color: #6c757d !important; }
  .bootstrap-override .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important; }
  .bootstrap-override .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }
  .bootstrap-override .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }
  .bootstrap-override .text-decoration-none {
    text-decoration: none !important; }
  .bootstrap-override .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important; }
  .bootstrap-override .text-reset {
    color: inherit !important; }
  .bootstrap-override .visible {
    visibility: visible !important; }
  .bootstrap-override .invisible {
    visibility: hidden !important; }
  @media print {
    .bootstrap-override *,
    .bootstrap-override *::before,
    .bootstrap-override *::after {
      text-shadow: none !important;
      box-shadow: none !important; }
    .bootstrap-override a:not(.btn) {
      text-decoration: underline; }
    .bootstrap-override abbr[title]::after {
      content: " (" attr(title) ")"; }
    .bootstrap-override pre {
      white-space: pre-wrap !important; }
    .bootstrap-override pre,
    .bootstrap-override blockquote {
      border: 1px solid #adb5bd;
      page-break-inside: avoid; }
    .bootstrap-override thead {
      display: table-header-group; }
    .bootstrap-override tr,
    .bootstrap-override img {
      page-break-inside: avoid; }
    .bootstrap-override p,
    .bootstrap-override h2,
    .bootstrap-override h3 {
      orphans: 3;
      widows: 3; }
    .bootstrap-override h2,
    .bootstrap-override h3 {
      page-break-after: avoid; }
    @page {
      .bootstrap-override {
        size: a3; } }
    .bootstrap-override body {
      min-width: 992px !important; }
    .bootstrap-override .container {
      min-width: 992px !important; }
    .bootstrap-override .navbar {
      display: none; }
    .bootstrap-override .badge {
      border: 1px solid #000; }
    .bootstrap-override .table {
      border-collapse: collapse !important; }
      .bootstrap-override .table td,
      .bootstrap-override .table th {
        background-color: #fff !important; }
    .bootstrap-override .table-bordered th,
    .bootstrap-override .table-bordered td {
      border: 1px solid #dee2e6 !important; }
    .bootstrap-override .table-dark {
      color: inherit; }
      .bootstrap-override .table-dark th,
      .bootstrap-override .table-dark td,
      .bootstrap-override .table-dark thead th,
      .bootstrap-override .table-dark tbody + tbody {
        border-color: #dee2e6; }
    .bootstrap-override .table .thead-dark th {
      color: inherit;
      border-color: #dee2e6; } }

.beta {
  height: 14px;
  width: 34px;
  background-color: orange;
  margin-left: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
  margin-top: 3px; }
  .beta::before {
    content: '';
    height: 0px;
    width: 0px;
    background: transparent;
    position: absolute;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid orange;
    margin-left: -7px; }
  .beta::after {
    content: 'BETA';
    margin-left: 6px;
    line-height: 0;
    font-size: 9px;
    font-weight: bold;
    position: absolute;
    margin-top: 6px;
    color: #151619; }

@font-face {
  font-family: 'SourceSansProRegular';
  src: url(/static/media/sourcesanspro-regular.47dbe582.ttf) format("truetype"); }

.goog-menuheader {
  color: white;
  font-weight: bold;
  margin-top: 5px;
  padding-bottom: 4px;
  border-bottom: 1pt solid;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.goog-tooltip {
  background-color: #828282;
  color: white;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, sans-serif;
  font-size: 8pt;
  padding: 7px;
  left: 34px;
  border-radius: 3px;
  z-index: 9999; }

.goog-menuitem-content {
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 14px; }

.goog-menuitem-icon {
  left: 12px !important;
  font-size: 20px;
  height: 20px !important;
  width: 20px !important; }

.blocklyWidgetDiv:not(.fieldTextInput) {
  padding-bottom: 12px; }

.blocklyWidgetDiv.fieldTextInput input.blocklyHtmlInput {
  font-size: 14pt !important; }

.blocklyWidgetDiv .goog-menu, .custom-rest-output .goog-menu {
  background-color: #16191D !important;
  border: 1px solid #2C333A;
  border-radius: 6px;
  width: calc(100% - 2px);
  overflow-y: visible !important;
  padding: 8px 0 !important; }

.blocklyWidgetDiv .goog-menuitem, .blocklyDropDownDiv .goog-menuitem {
  padding: 9px 12px 9px 28px;
  color: #E6EAF0 !important; }

.blocklyWidgetDiv .goog-menuitem-highlight {
  background-color: #1D2125 !important;
  border-color: transparent !important;
  border-width: 0px !important; }

.blocklyWidgetDiv .goog-menuitem-disabled .goog-menuitem-accel, .blocklyWidgetDiv .goog-menuitem-disabled .goog-menuitem-content, .blocklyDropDownDiv .goog-menuitem-disabled .goog-menuitem-accel, .blocklyDropDownDiv .goog-menuitem-disabled .goog-menuitem-content {
  color: #596573 !important; }

body {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
  /*   padding:8px; */
  /* border:8pt solid white; */ }

.header {
  height: 7%;
  width: 100%;
  background-color: #3b4449;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  color: white;
  display: inline-block; }

.header div {
  display: inline-block;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: relative; }

.footer {
  top: 93%;
  height: 7%;
  width: 100%;
  position: absolute;
  padding-right: 20px;
  border-top: 1pt solid #edf0f1;
  /*   background-color:rgb(67,77,83); */
  background: #3D464D; }

.footer div {
  float: right;
  color: white;
  width: 120px;
  text-align: center;
  border-radius: 4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: relative;
  padding: 5px; }

#cancelButton {
  cursor: pointer;
  background-color: transparent;
  color: white; }

#saveButton {
  cursor: pointer;
  background-color: #1ead8a; }

.header > .buttons {
  padding: 5px;
  float: right;
  color: white;
  cursor: pointer; }

.content {
  padding: 20px;
  position: absolute;
  top: 7%;
  bottom: 7%;
  width: 100%;
  left: 0;
  right: 0; }

.displayNone {
  display: none; }

.blocklyHtmlInput::-webkit-input-placeholder {
  color: lightgrey; }

.blocklyHtmlInput::-moz-placeholder {
  color: lightgrey; }

.blocklyHtmlInput:-ms-input-placeholder {
  color: lightgrey; }

.blocklyHtmlInput::-ms-input-placeholder {
  color: lightgrey; }

.blocklyHtmlInput::placeholder {
  color: lightgrey; }

.fieldDateEditor {
  width: 100%;
  height: 100%;
  display: flex; }

.fieldDateEditor > input {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

.fieldDateEditor > div {
  padding: 0px;
  width: 10%; }

.fieldDateEditor > input::-webkit-inner-spin-button {
  display: none; }

/*.fieldTextInput{*/
/*  opacity:0.5*/
/*}*/
/*.blocklyHtmlInput{*/
/*  opacity: 0.5;*/
/*}*/
/* bg color of workspace, etc.. */
.blocklySvg {
  background-color: #1D2125; }

#blocklyDiv {
  border: 1pt solid #edf0f1;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

/* Toolbox */
div#blocklyDiv .blocklyToolboxDiv {
  background-color: #464646;
  color: var(--font-color);
  padding-top: 25px; }

.blocklyToolboxDiv {
  position: absolute !important;
  width: 200px; }

.blocklyToolboxDelete ~ .blocklyFlyout > .blocklyFlyoutBackground {
  opacity: 0.5;
  -webkit-transition: all 0.125s linear;
  transition: all 0.125s linear; }

.blockly-panel {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: inline-block; }

.output-panel {
  position: relative;
  width: 25%;
  height: 100%;
  box-sizing: border-box;
  float: right; }

.explanation-panel {
  height: 49.5%;
  margin-bottom: 1%;
  width: 100%;
  padding: 8px;
  position: relative;
  background-color: white;
  overflow-y: scroll;
  box-sizing: border-box; }

#codeExplanationText {
  width: 100%;
  height: 90%;
  /*   background-color:rgb(237,237,237) */ }

.output-panel #codeDiv {
  background-color: white;
  /*   height:49.5%; @*/
  height: 100%;
  width: 100%;
  /*   padding:8px; */
  box-sizing: border-box;
  /*   border-top: 4px solid  rgb(237,237,237);; */
  display: block;
  position: relative;
  background-color: white;
  /*   overflow-y:scroll; */ }

.rest-service-test {
  background-color: #16191D;
  display: flex;
  flex-flow: column;
  height: 100%;
  border-radius: 6px;
  color: #ffffff; }
  .rest-service-test textarea {
    outline: unset;
    border: unset; }
  .rest-service-test button {
    display: inline-block;
    font-size: 11px;
    font-family: "sourceSansProSemibold", Arial, Helvetica, sans-serif;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
    color: #ffffff;
    height: 26px;
    line-height: 10px;
    letter-spacing: 0.25px;
    border: 1px solid transparent;
    border-radius: 3px;
    min-width: 54px;
    padding: 6px 0px;
    text-transform: uppercase; }
  .rest-service-test .responseUrl {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px; }
  .rest-service-test .rest-close, .rest-service-test .rest-copy {
    color: #8695A7;
    border: none;
    background-color: transparent;
    padding-right: 0px;
    margin-right: 5px; }
    .rest-service-test .rest-close:hover, .rest-service-test .rest-copy:hover {
      color: #9FADBC; }
  .rest-service-test .rest-copy {
    float: right;
    display: flex;
    min-width: 65px;
    margin-left: 15px;
    margin-right: 0px; }
    .rest-service-test .rest-copy.disabled {
      cursor: default;
      opacity: 1; }
    .rest-service-test .rest-copy i {
      width: 16px;
      height: 16px;
      background: url(/static/media/copy.066748c4.svg) center center no-repeat;
      margin-right: 10px; }
    .rest-service-test .rest-copy span {
      line-height: 16px; }
  .rest-service-test .rest-done {
    -webkit-text-fill-color: #E6EAF0;
    opacity: 1;
    color: #E6EAF0;
    background-color: #004ECC !important; }
    .rest-service-test .rest-done:hover:not(.disabled) {
      background-color: #0C5FE4 !important; }
    .rest-service-test .rest-done.disabled {
      cursor: default;
      background-color: #596573 !important; }
  .rest-service-test .rest-queryurl {
    margin-left: 10px;
    -webkit-text-fill-color: #E6EAF0;
    opacity: 1;
    color: #E6EAF0;
    background-color: #004ECC !important;
    padding-left: 12px;
    padding-right: 12px; }
    .rest-service-test .rest-queryurl:hover {
      background-color: #0C5FE4 !important; }
  .rest-service-test > div {
    margin: 5px; }
  .rest-service-test > input {
    width: 25%;
    margin: 5px;
    background-color: #1ead8a;
    color: #E6EAF0; }
  .rest-service-test .responseArea {
    color: #E6EAF0;
    display: block;
    flex: 1 1 auto;
    background-color: #22262B;
    resize: none;
    overflow-y: scroll;
    overflow-x: unset;
    padding: 5px; }
  .rest-service-test .responseArea::-webkit-scrollbar {
    width: 8px;
    cursor: pointer !important; }
  .rest-service-test .responseArea::-webkit-scrollbar-thumb {
    background-color: #454e59;
    border-radius: 8px;
    cursor: pointer !important; }
  .rest-service-test .rest-control {
    margin: unset;
    padding: 10px; }

/*render over top of widgetDiv (zindexd=999999)*/
.blocklyDropDownDiv {
  z-index: 100000; }

.blocklyWidgetDiv, .custom-rest-output {
  border-radius: 5px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.3);
  width: 200px;
  overflow: hidden;
  -webkit-transform: translate(5px, -5px);
          transform: translate(5px, -5px); }
  .blocklyWidgetDiv .goog-menu, .custom-rest-output .goog-menu {
    overflow-y: scroll;
    background-color: #464646; }
  .blocklyWidgetDiv .goog-menuitem, .custom-rest-output .goog-menuitem {
    color: #FFFFFF; }
  .blocklyWidgetDiv .goog-menuitem-highlight, .custom-rest-output .goog-menuitem-highlight {
    background-color: #3E3E3E;
    border-color: #3E3E3E;
    border-style: solid; }

.custom-rest-output {
  top: 5px;
  left: unset;
  right: 18px;
  position: absolute;
  direction: ltr; }

/* Blockly Tooltip */
.blocklyTooltipDiv {
  background-color: #16191D;
  border: 1px solid #2C333A;
  border-radius: 4px;
  color: #E6EAF0;
  padding: 7px 10px;
  opacity: 1 !important; }

.dropdown-bar {
  display: block;
  font-family: sans-serif;
  font-size: 16px;
  width: 200px;
  margin-left: auto;
  margin-right: auto; }

#languageDropdown {
  border: 0;
  background-color: transparent;
  font-size: 16px;
  outline: none; }

#im-just-an-underline {
  border-bottom: 1px solid;
  display: inline;
  padding-right: 5px; }

.POps {
  height: 1px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2); }

.close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 100%;
  color: white;
  width: 56px;
  height: 56px;
  line-height: 56px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.37);
  -webkit-transition: box-shadow .3s ease-in-out;
  transition: box-shadow .3s ease-in-out;
  text-align: center;
  font-weight: 500;
  background-color: #044d7c;
  cursor: pointer; }

.close-button:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.3);
  -webkit-transition: box-shadow .3s ease-in-out;
  transition: box-shadow .3s ease-in-out; }

.but-not-that-pretty {
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important; }

.center {
  text-align: center; }

.indent {
  position: relative;
  border-left: 20px solid rgba(0, 0, 0, 0.125);
  padding-left: 5px; }

.explanation {
  background-color: #ededed;
  padding-left: 5px;
  color: white; }

.blocklyPath.blocklyBlockBackground {
  stroke-width: 1px; }

.blocklySelected .blocklyPath.blocklyBlockBackground {
  stroke-width: 1.5px; }

.blocklySelected .blocklyPath.blocklyBlockBackground:last-child {
  display: none; }

.blocklyFlyout .blocklyZoom {
  display: none; }

.blocklyToolboxDiv {
  border-right: 1px solid #2C333A;
  border-left: 1px solid rgba(0, 0, 0, 0.15); }

.blocklyTreeRow {
  text-align: left;
  height: 38px;
  box-sizing: border-box;
  cursor: pointer;
  border-left: none !important;
  margin: 0;
  padding: 5px 35px 5px 7px !important;
  color: #E6EAF0; }

.blocklyTreeRow:not(.blocklyTreeSelected):hover {
  background-color: #22262B;
  color: #E6EAF0 !important; }

.blocklyTreeSeparator {
  border-bottom: 1pt solid #2C333A;
  border-left: none !important;
  /*border-bottom: solid #e5e5e5 1px;*/
  height: 0;
  margin: 4px 10px; }

.blocklyTreeIcon {
  display: inline-block !important;
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  margin-left: 3px;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  line-height: 26px !important;
  text-align: center;
  font-size: 22px; }

.blocklyTreeLabel {
  font-size: 0.8rem;
  margin-left: 10px;
  font-weight: 400;
  cursor: pointer;
  color: #FFFFFF;
  line-height: 1rem;
  font-family: 'SourceSansProRegular';
  font-size: 14px; }

.blocklyFlyout {
  min-width: 195px; }
  .blocklyFlyout .blocklyFlyoutLabelText {
    fill: #FFF;
    font-family: 'SourceSansProRegular';
    font-size: 20pt;
    font-weight: inherit; }
  .blocklyFlyout .blocklyFlyoutButton {
    fill: #004ECC;
    border: none; }
    .blocklyFlyout .blocklyFlyoutButton .blocklyFlyoutButtonBackground {
      stroke: #004ECC; }
    .blocklyFlyout .blocklyFlyoutButton .blocklyText {
      fill: #E6EAF0;
      text-transform: uppercase; }
    .blocklyFlyout .blocklyFlyoutButton:hover {
      fill: #0C5FE4; }

.blocklyText.field-text-placeholder {
  fill: #aaa;
  display: none; }
  .blocklyText.field-text-placeholder.visible {
    display: block; }

.field-text-quote.invisible, .blocklyText.invisible {
  display: none; }

.blocklyFlyoutBackground {
  fill: #16191D;
  fill-opacity: 1;
  -webkit-transform: scale(2);
          transform: scale(2); }

.blocklyToolboxDiv {
  background-color: #16191D;
  margin-top: 48px;
  height: calc(100% - 48px) !important; }
  .blocklyToolboxDiv .scrollbar-thumb {
    width: 4px;
    left: 2px; }
  .blocklyToolboxDiv .scroll-content {
    padding-bottom: 5px; }

.toolbox-trashcan {
  position: absolute;
  width: 46px;
  min-height: 100%;
  z-index: 48;
  background: rgba(22, 25, 29, 0.8);
  opacity: 0;
  display: none;
  -webkit-transition: 0.2s ease-in-out background;
  transition: 0.2s ease-in-out background;
  text-align: center; }
  .toolbox-trashcan:before {
    position: absolute;
    bottom: 60px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 30px;
    height: 30px;
    font-size: 24px; }
  .toolbox-trashcan.expanded {
    width: 200px; }
  .toolbox-trashcan.forDelete {
    background: rgba(80, 30, 30, 0.8); }

.blocklyTreeRow {
  position: relative; }

.blocklyTreeRowExpanded:after {
  background-repeat: no-repeat;
  width: 15px;
  height: 12px;
  top: 12px;
  right: 5px;
  position: absolute;
  display: inline-block;
  background-image: url(https://img.icons8.com/ios-glyphs/452/forward.png);
  background-position: 0px 0px;
  background-size: 12px; }

.blocklyTreeRow.blocklyTreeSelected {
  background-color: #1D2125 !important; }

.blocklyTreeRow.blocklyTreeSelected .blocklyTreeLabel {
  color: #E6EAF0 !important; }

.blocklyTreeRow .blocklyTreeRow .blocklyTreeIcon {
  display: none !important; }

.blocklyTreeRow.blocklyTreeSelected:before {
  width: 3px;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-color: #0C5FE4; }

.blocklyTreeIcon.undefined {
  display: none !important; }

.blocklyTreeIcon.category_variables {
  background-image: url(/static/media/variables.0aacaf1e.svg); }

.blocklyTreeIcon.category_functions {
  background-image: url(/static/media/functions.63d0779a.svg); }

.blocklyTreeIcon.subcategory {
  background: none;
  border-right: 2pt solid grey;
  border-radius: 0px;
  box-sizing: border-box; }

svg.fa-angle-double-left {
  position: absolute;
  bottom: 20px;
  left: 170px;
  z-index: 999;
  color: #AAA;
  cursor: pointer; }

svg.fa-angle-double-right {
  position: absolute;
  bottom: 20px;
  left: 13px;
  z-index: 999;
  color: #AAA;
  cursor: pointer; }

.blocklyWorkspace {
  /*width: calc(100% - 240px);*/
  /*height: calc(100% - 50px);*/
  /*transform: translate(185px, 25px);*/ }
  .blocklyWorkspace .blocklyTrash {
    display: none; }
  .blocklyWorkspace .blocklyZoom .editor-icon.icon-background {
    height: 142;
    y: 44; }

#workspaceMountDiv > .injectionDiv > svg > .blocklyWorkspace .blocklyZoom {
  display: none !important; }

.blocklyText, .blocklyHtmlInput {
  font-family: 'SourceSansProRegular';
  font-size: 14pt;
  font-weight: inherit; }

.goog-menuheader, .goog-menuitem-content {
  font-family: 'SourceSansProRegular';
  font-size: 14px;
  font-weight: 400; }

.blocklyDatePicker .goog-date-picker-monthyear,
.blocklyDatePicker .goog-date-picker-btn,
.blocklyDatePicker .goog-date-picker-wday,
.blocklyDatePicker .goog-date-picker-date {
  font-family: 'SourceSansProRegular';
  font-size: 14px;
  font-weight: 400; }

.blocklyWidgetDiv.fieldTextInput {
  line-height: 30px; }

@font-face {
  font-family: "darktheme";
  src: url(/static/media/snap-dark.b92bfe81.eot);
  src: url(/static/media/snap-dark.58cba09c.ttf) format("truetype"), url(/static/media/snap-dark.1250c23e.woff) format("woff"), url(/static/media/snap-dark.2f4eb271.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

.darkTheme [class^="snap-icon-"],
.darkTheme [class*=" snap-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "darktheme", sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.darkTheme .snap-icon-action:before {
  content: "\e900";
  color: #e6eaf0; }

.darkTheme .snap-icon-action_dual .path1:before {
  content: "\e901";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-action_dual .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-action_dual .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-activity:before {
  content: "\e904";
  color: #e6eaf0; }

.darkTheme .snap-icon-activity_dual .path1:before {
  content: "\e905";
  color: #e6eaf0; }

.darkTheme .snap-icon-activity_dual .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-activity_dual .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-activity_dual .path4:before {
  content: "\e908";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-add:before {
  content: "\e909";
  color: #e6eaf0; }

.darkTheme .snap-icon-add_dual .path1:before {
  content: "\e90a";
  color: #e6eaf0; }

.darkTheme .snap-icon-add_dual .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-agex:before {
  content: "\e90c";
  color: #e6eaf0; }

.darkTheme .snap-icon-agex_dual .path1:before {
  content: "\e90d";
  color: #e6eaf0; }

.darkTheme .snap-icon-agex_dual .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-agex_dual .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-AI:before {
  content: "\e910";
  color: #e6eaf0; }

.darkTheme .snap-icon-AI_dual .path1:before {
  content: "\e911";
  color: #e6eaf0; }

.darkTheme .snap-icon-AI_dual .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-AI_dual .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-AI_dual .path4:before {
  content: "\e914";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-AI_dual .path5:before {
  content: "\e915";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-AI_dual .path6:before {
  content: "\e916";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-AI_dual .path7:before {
  content: "\e917";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-AI_dual .path8:before {
  content: "\e918";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-alert_active .path1:before {
  content: "\e919";
  color: #e6eaf0; }

.darkTheme .snap-icon-alert_active .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-alert_active .path3:before {
  content: "\e91b";
  margin-left: -1em;
  color: #e87a66; }

.darkTheme .snap-icon-alert_active_dual .path1:before {
  content: "\e91c";
  color: #e6eaf0; }

.darkTheme .snap-icon-alert_active_dual .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-alert_active_dual .path3:before {
  content: "\e91e";
  margin-left: -1em;
  color: #e87a66; }

.darkTheme .snap-icon-alert_active_dual .path4:before {
  content: "\e91f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-alert_default:before {
  content: "\e920";
  color: #e6eaf0; }

.darkTheme .snap-icon-alert_default_dual .path1:before {
  content: "\e921";
  color: #e6eaf0; }

.darkTheme .snap-icon-alert_default_dual .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-alert_default_dual .path3:before {
  content: "\e923";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-announcement .path1:before {
  content: "\e924";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-announcement .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-announcement_dual .path1:before {
  content: "\e926";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-announcement_dual .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-announcement_dual .path3:before {
  content: "\e928";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-Api:before {
  content: "\e929";
  color: #e6eaf0; }

.darkTheme .snap-icon-Api_dual .path1:before {
  content: "\e92a";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-Api_dual .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-Api_dual .path3:before {
  content: "\e92c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-app_action .path1:before {
  content: "\e92d";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-app_action .path2:before {
  content: "\e92e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_action .path3:before {
  content: "\e92f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_action .path4:before {
  content: "\e930";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_action .path5:before {
  content: "\e931";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_action .path6:before {
  content: "\e932";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_action .path7:before {
  content: "\e933";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_action_dual .path1:before {
  content: "\e934";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-app_action_dual .path2:before {
  content: "\e935";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_action_dual .path3:before {
  content: "\e936";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-app_action_dual .path4:before {
  content: "\e937";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-app_action_dual .path5:before {
  content: "\e938";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-app_action_dual .path6:before {
  content: "\e939";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-app_action_dual .path7:before {
  content: "\e93a";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-app_elements .path1:before {
  content: "\e93b";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-app_elements .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_elements .path3:before {
  content: "\e93d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_elements .path4:before {
  content: "\e93e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_elements .path5:before {
  content: "\e93f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_elements .path6:before {
  content: "\e940";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_elements .path7:before {
  content: "\e941";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_elements_dual .path1:before {
  content: "\e942";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-app_elements_dual .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_elements_dual .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-app_elements_dual .path4:before {
  content: "\e945";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-app_elements_dual .path5:before {
  content: "\e946";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-app_elements_dual .path6:before {
  content: "\e947";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-app_elements_dual .path7:before {
  content: "\e948";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-app_empty:before {
  content: "\e949";
  color: #e6eaf0; }

.darkTheme .snap-icon-App_label .path1:before {
  content: "\e94a";
  color: #f57c00; }

.darkTheme .snap-icon-App_label .path2:before {
  content: "\e94b";
  margin-left: -1em;
  color: #fafbfc; }

.darkTheme .snap-icon-app_logo:before {
  content: "\e94c"; }

.darkTheme .snap-icon-applications:before {
  content: "\e94d";
  color: #e6eaf0; }

.darkTheme .snap-icon-applications_dual .path1:before {
  content: "\e94e";
  color: #e6eaf0; }

.darkTheme .snap-icon-applications_dual .path2:before {
  content: "\e94f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-applications_dual .path3:before {
  content: "\e950";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-applications_dual .path4:before {
  content: "\e951";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-applications_dual .path5:before {
  content: "\e952";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-arrow_left:before {
  content: "\e953";
  color: #e6eaf0; }

.darkTheme .snap-icon-arrow_option_circle:before {
  content: "\e954";
  color: #e6eaf0; }

.darkTheme .snap-icon-arrow_option_square:before {
  content: "\e955";
  color: #e6eaf0; }

.darkTheme .snap-icon-arrow_option_vertical:before {
  content: "\e956";
  color: #e6eaf0; }

.darkTheme .snap-icon-arrow_right:before {
  content: "\e957";
  color: #e6eaf0; }

.darkTheme .snap-icon-audio:before {
  content: "\e958";
  color: #e6eaf0; }

.darkTheme .snap-icon-audio_dual .path1:before {
  content: "\e959";
  color: #e6eaf0; }

.darkTheme .snap-icon-audio_dual .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-audio_dual .path3:before {
  content: "\e95b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-autogenerate_form:before {
  content: "\e95c";
  color: #e6eaf0; }

.darkTheme .snap-icon-autogenerate_form_dual .path1:before {
  content: "\e95d";
  color: #e6eaf0; }

.darkTheme .snap-icon-autogenerate_form_dual .path2:before {
  content: "\e95e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-autogenerate_form_dual .path3:before {
  content: "\e95f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-autogenerate_form_dual .path4:before {
  content: "\e960";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-azure:before {
  content: "\e961";
  color: #e6eaf0; }

.darkTheme .snap-icon-azure_dual:before {
  content: "\e962";
  color: #3884ff; }

.darkTheme .snap-icon-barcode:before {
  content: "\e963";
  color: #e6eaf0; }

.darkTheme .snap-icon-barcode_dual .path1:before {
  content: "\e964";
  color: #e6eaf0; }

.darkTheme .snap-icon-barcode_dual .path2:before {
  content: "\e965";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-body:before {
  content: "\e966";
  color: #e6eaf0; }

.darkTheme .snap-icon-body_dual .path1:before {
  content: "\e967";
  color: #e6eaf0; }

.darkTheme .snap-icon-body_dual .path2:before {
  content: "\e968";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-border_bottom:before {
  content: "\e969";
  color: #e6eaf0; }

.darkTheme .snap-icon-border_center:before {
  content: "\e96a";
  color: #e6eaf0; }

.darkTheme .snap-icon-border_dash1:before {
  content: "\e96b";
  color: #e6eaf0; }

.darkTheme .snap-icon-border_dash2:before {
  content: "\e96c";
  color: #e6eaf0; }

.darkTheme .snap-icon-border_full:before {
  content: "\e96d";
  color: #e6eaf0; }

.darkTheme .snap-icon-border_left:before {
  content: "\e96e";
  color: #e6eaf0; }

.darkTheme .snap-icon-border_right:before {
  content: "\e96f";
  color: #e6eaf0; }

.darkTheme .snap-icon-border_top:before {
  content: "\e970";
  color: #e6eaf0; }

.darkTheme .snap-icon-business_card:before {
  content: "\e971";
  color: #e6eaf0; }

.darkTheme .snap-icon-business_card_dual .path1:before {
  content: "\e972";
  color: #e6eaf0; }

.darkTheme .snap-icon-business_card_dual .path2:before {
  content: "\e973";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-business_card_dual .path3:before {
  content: "\e974";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-business_card_dual .path4:before {
  content: "\e975";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-business_card_dual .path5:before {
  content: "\e976";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-business_card_dual .path6:before {
  content: "\e977";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-business_card_dual .path7:before {
  content: "\e978";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-button:before {
  content: "\e979";
  color: #e6eaf0; }

.darkTheme .snap-icon-button_dual .path1:before {
  content: "\e97a";
  color: #e6eaf0; }

.darkTheme .snap-icon-button_dual .path2:before {
  content: "\e97b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-button_dual .path3:before {
  content: "\e97c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-calendar:before {
  content: "\e97d";
  color: #e6eaf0; }

.darkTheme .snap-icon-camera:before {
  content: "\e97e";
  color: #e6eaf0; }

.darkTheme .snap-icon-camera_dual .path1:before {
  content: "\e97f";
  color: #e6eaf0; }

.darkTheme .snap-icon-camera_dual .path2:before {
  content: "\e980";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-camera_dual .path3:before {
  content: "\e981";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-carousel:before {
  content: "\e982";
  color: #e6eaf0; }

.darkTheme .snap-icon-carousel_dual .path1:before {
  content: "\e983";
  color: #e6eaf0; }

.darkTheme .snap-icon-carousel_dual .path2:before {
  content: "\e984";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-carousel_dual .path3:before {
  content: "\e985";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-carousel_dual .path4:before {
  content: "\e986";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-certificate:before {
  content: "\e987";
  color: #e6eaf0; }

.darkTheme .snap-icon-certificate_dual .path1:before {
  content: "\e988";
  color: #e6eaf0; }

.darkTheme .snap-icon-certificate_dual .path2:before {
  content: "\e989";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-certificate_dual .path3:before {
  content: "\e98a";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-chart:before {
  content: "\e98b";
  color: #e6eaf0; }

.darkTheme .snap-icon-chart_dual .path1:before {
  content: "\e98c";
  color: #e6eaf0; }

.darkTheme .snap-icon-chart_dual .path2:before {
  content: "\e98d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-chart_loading:before {
  content: "\e98e";
  color: #9fadbc; }

.darkTheme .snap-icon-check:before {
  content: "\e98f";
  color: #e6eaf0; }

.darkTheme .snap-icon-checkbox:before {
  content: "\e990";
  color: #e6eaf0; }

.darkTheme .snap-icon-checkbox_checked:before {
  content: "\e991";
  color: #e6eaf0; }

.darkTheme .snap-icon-checkbox_checked_dual .path1:before {
  content: "\e992";
  color: #e6eaf0; }

.darkTheme .snap-icon-checkbox_checked_dual .path2:before {
  content: "\e993";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-checkbox_indeterminate:before {
  content: "\e994";
  color: #e6eaf0; }

.darkTheme .snap-icon-checkbox_indeterminate_dual .path1:before {
  content: "\e995";
  color: #3884ff; }

.darkTheme .snap-icon-checkbox_indeterminate_dual .path2:before {
  content: "\e996";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-checkbox_selected_blue:before {
  content: "\e997";
  color: #3884ff; }

.darkTheme .snap-icon-checked:before {
  content: "\e998";
  color: #e6eaf0; }

.darkTheme .snap-icon-checkmark_filled:before {
  content: "\e999";
  color: #e6eaf0; }

.darkTheme .snap-icon-checkmark_outline:before {
  content: "\e99a";
  color: #e6eaf0; }

.darkTheme .snap-icon-checkmark_outline_dual .path1:before {
  content: "\e99b";
  color: #3884ff; }

.darkTheme .snap-icon-checkmark_outline_dual .path2:before {
  content: "\e99c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-chevron_down:before {
  content: "\e99d";
  color: #e6eaf0; }

.darkTheme .snap-icon-chevron_right:before {
  content: "\e99e";
  color: #e6eaf0; }

.darkTheme .snap-icon-chevron_sort:before {
  content: "\e99f";
  color: #e6eaf0; }

.darkTheme .snap-icon-chevron_sort_dual .path1:before {
  content: "\e9a0";
  color: #3884ff; }

.darkTheme .snap-icon-chevron_sort_dual .path2:before {
  content: "\e9a1";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-chevron_up:before {
  content: "\e9a2";
  color: #e6eaf0; }

.darkTheme .snap-icon-choose_item_left:before {
  content: "\e9a3";
  color: #e6eaf0; }

.darkTheme .snap-icon-choose_item_right:before {
  content: "\e9a4";
  color: #e6eaf0; }

.darkTheme .snap-icon-clean:before {
  content: "\e9a5";
  color: #e6eaf0; }

.darkTheme .snap-icon-clean_dual .path1:before {
  content: "\e9a6";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-clean_dual .path2:before {
  content: "\e9a7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-clean_dual .path3:before {
  content: "\e9a8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-clean_dual .path4:before {
  content: "\e9a9";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-clean_dual .path5:before {
  content: "\e9aa";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-close:before {
  content: "\e9ab";
  color: #e6eaf0; }

.darkTheme .snap-icon-close_filled .path1:before {
  content: "\e9ac";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-close_filled .path2:before {
  content: "\e9ad";
  margin-left: -1em;
  color: #e06651; }

.darkTheme .snap-icon-close_outline:before {
  content: "\e9ae";
  color: #e6eaf0; }

.darkTheme .snap-icon-close_outline_color .path1:before {
  content: "\e9af";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-close_outline_color .path2:before {
  content: "\e9b0";
  margin-left: -1em;
  color: #e06651; }

.darkTheme .snap-icon-close_outline_color .path3:before {
  content: "\e9b1";
  margin-left: -1em;
  color: #e06651; }

.darkTheme .snap-icon-close_outline_dual .path1:before {
  content: "\e9b2";
  color: #e6eaf0; }

.darkTheme .snap-icon-close_outline_dual .path2:before {
  content: "\e9b3";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cloud_connect .path1:before {
  content: "\e9b4";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-cloud_connect .path2:before {
  content: "\e9b5";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cloud_connect .path3:before {
  content: "\e9b6";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cloud_connect_dual .path1:before {
  content: "\e9b7";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-cloud_connect_dual .path2:before {
  content: "\e9b8";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cloud_connect_dual .path3:before {
  content: "\e9b9";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-code_block .path1:before {
  content: "\e9ba";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-code_block .path2:before {
  content: "\e9bb";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-code_block .path3:before {
  content: "\e9bc";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-code_block .path4:before {
  content: "\e9bd";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-code_block .path5:before {
  content: "\e9be";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-code_block .path6:before {
  content: "\e9bf";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-code_block_dual .path1:before {
  content: "\e9c0";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-code_block_dual .path2:before {
  content: "\e9c1";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-code_block_dual .path3:before {
  content: "\e9c2";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-code_block_dual .path4:before {
  content: "\e9c3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-code_block_dual .path5:before {
  content: "\e9c4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-code_block_dual .path6:before {
  content: "\e9c5";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cog:before {
  content: "\e9c6";
  color: #e6eaf0; }

.darkTheme .snap-icon-cog_dual .path1:before {
  content: "\e9c7";
  color: #e6eaf0; }

.darkTheme .snap-icon-cog_dual .path2:before {
  content: "\e9c8";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cog_dual .path3:before {
  content: "\e9c9";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-color_blank .path1:before {
  content: "\e9ca";
  color: #16191d; }

.darkTheme .snap-icon-color_blank .path2:before {
  content: "\e9cb";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path3:before {
  content: "\e9cc";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path4:before {
  content: "\e9cd";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path5:before {
  content: "\e9ce";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path6:before {
  content: "\e9cf";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path7:before {
  content: "\e9d0";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path8:before {
  content: "\e9d1";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path9:before {
  content: "\e9d2";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path10:before {
  content: "\e9d3";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path11:before {
  content: "\e9d4";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path12:before {
  content: "\e9d5";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path13:before {
  content: "\e9d6";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_blank .path14:before {
  content: "\e9d7";
  margin-left: -1em;
  color: #1d2125; }

.darkTheme .snap-icon-color_fill:before {
  content: "\e9d8";
  color: #ff5959; }

.darkTheme .snap-icon-computer .path1:before {
  content: "\e9d9";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-computer .path2:before {
  content: "\e9da";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-computer_dual .path1:before {
  content: "\e9db";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-computer_dual .path2:before {
  content: "\e9dc";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-computer_dual .path3:before {
  content: "\e9dd";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-condition:before {
  content: "\e9de";
  color: #e6eaf0; }

.darkTheme .snap-icon-condition_dual .path1:before {
  content: "\e9df";
  color: #e6eaf0; }

.darkTheme .snap-icon-condition_dual .path2:before {
  content: "\e9e0";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-condition_dual .path3:before {
  content: "\e9e1";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-condition_dual .path4:before {
  content: "\e9e2";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-connect_filled .path1:before {
  content: "\e9e3";
  color: #00ae00; }

.darkTheme .snap-icon-connect_filled .path2:before {
  content: "\e9e4";
  margin-left: -1em;
  color: white; }

.darkTheme .snap-icon-connect_filled .path3:before {
  content: "\e9e5";
  margin-left: -1em;
  color: white; }

.darkTheme .snap-icon-connect_filled .path4:before {
  content: "\e9e6";
  margin-left: -1em;
  color: white; }

.darkTheme .snap-icon-connection .path1:before {
  content: "\e9e7";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-connection .path2:before {
  content: "\e9e8";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-connection_dual .path1:before {
  content: "\e9e9";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-connection_dual .path2:before {
  content: "\e9ea";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-connection_dual .path3:before {
  content: "\e9eb";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cookie:before {
  content: "\e9ec";
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path1:before {
  content: "\e9ed";
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path2:before {
  content: "\e9ee";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path3:before {
  content: "\e9ef";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path4:before {
  content: "\e9f0";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path5:before {
  content: "\e9f1";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path6:before {
  content: "\e9f2";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path7:before {
  content: "\e9f3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path8:before {
  content: "\e9f4";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-cookie_dual .path9:before {
  content: "\e9f5";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cookie_dual .path10:before {
  content: "\e9f6";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cookie_dual .path11:before {
  content: "\e9f7";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cookie_dual .path12:before {
  content: "\e9f8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cookie_dual .path13:before {
  content: "\e9f9";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cookie_dual .path14:before {
  content: "\e9fa";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-cookie_dual .path15:before {
  content: "\e9fb";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-copy:before {
  content: "\e9fc";
  color: #e6eaf0; }

.darkTheme .snap-icon-copy_dual .path1:before {
  content: "\e9fd";
  color: #e6eaf0; }

.darkTheme .snap-icon-copy_dual .path2:before {
  content: "\e9fe";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-create_get_variable:before {
  content: "\e9ff";
  color: #e6eaf0; }

.darkTheme .snap-icon-create_get_variable_dual .path1:before {
  content: "\ea00";
  color: #e6eaf0; }

.darkTheme .snap-icon-create_get_variable_dual .path2:before {
  content: "\ea01";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-create_get_variable_dual .path3:before {
  content: "\ea02";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-create_get_variable_dual .path4:before {
  content: "\ea03";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-create_get_variable_dual .path5:before {
  content: "\ea04";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-create_get_variable_dual .path6:before {
  content: "\ea05";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-create_set_variable:before {
  content: "\ea06";
  color: #e6eaf0; }

.darkTheme .snap-icon-create_set_variable_dual .path1:before {
  content: "\ea07";
  color: #e6eaf0; }

.darkTheme .snap-icon-create_set_variable_dual .path2:before {
  content: "\ea08";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-create_set_variable_dual .path3:before {
  content: "\ea09";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-create_set_variable_dual .path4:before {
  content: "\ea0a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-create_set_variable_dual .path5:before {
  content: "\ea0b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-create_set_variable_dual .path6:before {
  content: "\ea0c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-create_set_variable_dual .path7:before {
  content: "\ea0d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-crop:before {
  content: "\ea0e";
  color: #e6eaf0; }

.darkTheme .snap-icon-csv:before {
  content: "\ea0f";
  color: #e6eaf0; }

.darkTheme .snap-icon-csv_dual .path1:before {
  content: "\ea10";
  color: #e6eaf0; }

.darkTheme .snap-icon-csv_dual .path2:before {
  content: "\ea11";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-csv_dual .path3:before {
  content: "\ea12";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-csv_dual .path4:before {
  content: "\ea13";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-data_connection:before {
  content: "\ea14";
  color: #e6eaf0; }

.darkTheme .snap-icon-data_connection_dual .path1:before {
  content: "\ea15";
  color: #e6eaf0; }

.darkTheme .snap-icon-data_connection_dual .path2:before {
  content: "\ea16";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-data_connection_dual .path3:before {
  content: "\ea17";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-data_export:before {
  content: "\ea18";
  color: #e6eaf0; }

.darkTheme .snap-icon-data_export_dual .path1:before {
  content: "\ea19";
  color: #3884ff; }

.darkTheme .snap-icon-data_export_dual .path2:before {
  content: "\ea1a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-data_link:before {
  content: "\ea1b";
  color: #e6eaf0; }

.darkTheme .snap-icon-data_source_large:before {
  content: "\ea1c";
  color: #e6eaf0; }

.darkTheme .snap-icon-date_picker:before {
  content: "\ea1d";
  color: #e6eaf0; }

.darkTheme .snap-icon-date_picker_dual .path1:before {
  content: "\ea1e";
  color: #e6eaf0; }

.darkTheme .snap-icon-date_picker_dual .path2:before {
  content: "\ea1f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-datetime:before {
  content: "\ea20";
  color: #e6eaf0; }

.darkTheme .snap-icon-datetime_dual .path1:before {
  content: "\ea21";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-datetime_dual .path2:before {
  content: "\ea22";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-datetime_dual .path3:before {
  content: "\ea23";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-datetime_dual .path4:before {
  content: "\ea24";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-deactivate .path1:before {
  content: "\ea25";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-deactivate .path2:before {
  content: "\ea26";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-deactivate .path3:before {
  content: "\ea27";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-deactivate_color .path1:before {
  content: "\ea28";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-deactivate_color .path2:before {
  content: "\ea29";
  margin-left: -1em;
  color: #e06651; }

.darkTheme .snap-icon-deactivate_color .path3:before {
  content: "\ea2a";
  margin-left: -1em;
  color: #e06651; }

.darkTheme .snap-icon-deactivate_dual .path1:before {
  content: "\ea2b";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-deactivate_dual .path2:before {
  content: "\ea2c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-deactivate_dual .path3:before {
  content: "\ea2d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-debug .path1:before {
  content: "\ea2e";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-debug .path2:before {
  content: "\ea2f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-debug_dual .path1:before {
  content: "\ea30";
  color: #3884ff; }

.darkTheme .snap-icon-debug_dual .path2:before {
  content: "\ea31";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-delete:before {
  content: "\ea32";
  color: #e6eaf0; }

.darkTheme .snap-icon-delete_dual .path1:before {
  content: "\ea33";
  color: #3884ff; }

.darkTheme .snap-icon-delete_dual .path2:before {
  content: "\ea34";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-delete_dual .path3:before {
  content: "\ea35";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-delete_dual .path4:before {
  content: "\ea36";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-device:before {
  content: "\ea37";
  color: #e6eaf0; }

.darkTheme .snap-icon-device_android:before {
  content: "\ea38";
  color: #e6eaf0; }

.darkTheme .snap-icon-device_dual .path1:before {
  content: "\ea39";
  color: #e6eaf0; }

.darkTheme .snap-icon-device_dual .path2:before {
  content: "\ea3a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-device_dual .path3:before {
  content: "\ea3b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-device_ios:before {
  content: "\ea3c";
  color: #e6eaf0; }

.darkTheme .snap-icon-device_rotation:before {
  content: "\ea3d";
  color: #e6eaf0; }

.darkTheme .snap-icon-device_rotation_dual .path1:before {
  content: "\ea3e";
  color: #e6eaf0; }

.darkTheme .snap-icon-device_rotation_dual .path2:before {
  content: "\ea3f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-device_rotation_dual .path3:before {
  content: "\ea40";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-device_windows:before {
  content: "\ea41";
  color: #e6eaf0; }

.darkTheme .snap-icon-digit .path1:before {
  content: "\ea42";
  color: #e6eaf0; }

.darkTheme .snap-icon-digit .path2:before {
  content: "\ea43";
  margin-left: -1em;
  color: #22262b; }

.darkTheme .snap-icon-document:before {
  content: "\ea44";
  color: #e6eaf0; }

.darkTheme .snap-icon-document_dual .path1:before {
  content: "\ea45";
  color: #e6eaf0; }

.darkTheme .snap-icon-document_dual .path2:before {
  content: "\ea46";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-document_dual .path3:before {
  content: "\ea47";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-document_dual .path4:before {
  content: "\ea48";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-document_dual .path5:before {
  content: "\ea49";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-donut_chart:before {
  content: "\ea4a";
  color: #e6eaf0; }

.darkTheme .snap-icon-donut_chart_dual .path1:before {
  content: "\ea4b";
  color: #3884ff; }

.darkTheme .snap-icon-donut_chart_dual .path2:before {
  content: "\ea4c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-double_preset_1:before {
  content: "\ea4d";
  color: #e6eaf0; }

.darkTheme .snap-icon-double_preset_2:before {
  content: "\ea4e";
  color: #e6eaf0; }

.darkTheme .snap-icon-double_preset_3:before {
  content: "\ea4f";
  color: #e6eaf0; }

.darkTheme .snap-icon-double_preset_4:before {
  content: "\ea50";
  color: #e6eaf0; }

.darkTheme .snap-icon-download:before {
  content: "\ea51";
  color: #e6eaf0; }

.darkTheme .snap-icon-download_dual .path1:before {
  content: "\ea52";
  color: #e6eaf0; }

.darkTheme .snap-icon-download_dual .path2:before {
  content: "\ea53";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-download_dual .path3:before {
  content: "\ea54";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-draft:before {
  content: "\ea55";
  color: #e6eaf0; }

.darkTheme .snap-icon-draft_dual .path1:before {
  content: "\ea56";
  color: #e6eaf0; }

.darkTheme .snap-icon-draft_dual .path2:before {
  content: "\ea57";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-drag_vertical:before {
  content: "\ea58";
  color: #e6eaf0; }

.darkTheme .snap-icon-draggable:before {
  content: "\ea59";
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown:before {
  content: "\ea5a";
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown_dual .path1:before {
  content: "\ea5b";
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown_dual .path2:before {
  content: "\ea5c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown_dual .path3:before {
  content: "\ea5d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown_dual .path4:before {
  content: "\ea5e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown_dual .path5:before {
  content: "\ea5f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown_dual .path6:before {
  content: "\ea60";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown_dual .path7:before {
  content: "\ea61";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dropdown_dual .path8:before {
  content: "\ea62";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dropdown_dual .path9:before {
  content: "\ea63";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dropdown_dual .path10:before {
  content: "\ea64";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dropdown_dual .path11:before {
  content: "\ea65";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dropdown_dual .path12:before {
  content: "\ea66";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dropdown_dual .path13:before {
  content: "\ea67";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dynamic_users:before {
  content: "\ea68";
  color: #e6eaf0; }

.darkTheme .snap-icon-dynamic_users_dual .path1:before {
  content: "\ea69";
  color: #e6eaf0; }

.darkTheme .snap-icon-dynamic_users_dual .path2:before {
  content: "\ea6a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dynamic_users_dual .path3:before {
  content: "\ea6b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dynamic_users_dual .path4:before {
  content: "\ea6c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-dynamic_users_dual .path5:before {
  content: "\ea6d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dynamic_users_dual .path6:before {
  content: "\ea6e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dynamic_users_dual .path7:before {
  content: "\ea6f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-dynamic_users_dual .path8:before {
  content: "\ea70";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-edit:before {
  content: "\ea71";
  color: #e6eaf0; }

.darkTheme .snap-icon-edit_dual .path1:before {
  content: "\ea72";
  color: #e6eaf0; }

.darkTheme .snap-icon-edit_dual .path2:before {
  content: "\ea73";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-edit_user:before {
  content: "\ea74";
  color: #e6eaf0; }

.darkTheme .snap-icon-edit_user_dual .path1:before {
  content: "\ea75";
  color: #e6eaf0; }

.darkTheme .snap-icon-edit_user_dual .path2:before {
  content: "\ea76";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-Empty-illustration .path1:before {
  content: "\ea77";
  color: #596573; }

.darkTheme .snap-icon-Empty-illustration .path2:before {
  content: "\ea78";
  margin-left: -1em;
  color: #e6eaf0;
  opacity: 0.1; }

.darkTheme .snap-icon-Empty-illustration .path3:before {
  content: "\ea79";
  margin-left: -1em;
  color: #596573; }

.darkTheme .snap-icon-Empty-illustration .path4:before {
  content: "\ea7a";
  margin-left: -1em;
  color: #e6eaf0;
  opacity: 0.17; }

.darkTheme .snap-icon-Empty-illustration .path5:before {
  content: "\ea7b";
  margin-left: -1em;
  color: #596573; }

.darkTheme .snap-icon-Empty-illustration .path6:before {
  content: "\ea7c";
  margin-left: -1em;
  color: #596573; }

.darkTheme .snap-icon-Empty-illustration .path7:before {
  content: "\ea7d";
  margin-left: -1em;
  color: #e6eaf0;
  opacity: 0.0500; }

.darkTheme .snap-icon-Empty-illustration .path8:before {
  content: "\ea7e";
  margin-left: -1em;
  color: #596573; }

.darkTheme .snap-icon-Empty-illustration .path9:before {
  content: "\ea7f";
  margin-left: -1em;
  color: #596573; }

.darkTheme .snap-icon-Empty-illustration .path10:before {
  content: "\ea80";
  margin-left: -1em;
  color: #e6eaf0;
  opacity: 0.0700; }

.darkTheme .snap-icon-Empty-illustration .path11:before {
  content: "\ea81";
  margin-left: -1em;
  color: #e6eaf0;
  opacity: 0.1; }

.darkTheme .snap-icon-Empty-illustration .path12:before {
  content: "\ea82";
  margin-left: -1em;
  color: #e6eaf0;
  opacity: 0.1; }

.darkTheme .snap-icon-Empty-illustration .path13:before {
  content: "\ea83";
  margin-left: -1em;
  color: #596573; }

.darkTheme .snap-icon-Empty-illustration .path14:before {
  content: "\ea84";
  margin-left: -1em;
  color: #596573; }

.darkTheme .snap-icon-Empty-illustration .path15:before {
  content: "\ea85";
  margin-left: -1em;
  color: #e6eaf0;
  opacity: 0.0700; }

.darkTheme .snap-icon-enroll:before {
  content: "\ea86";
  color: #e6eaf0; }

.darkTheme .snap-icon-enroll_dual .path1:before {
  content: "\ea87";
  color: #e6eaf0; }

.darkTheme .snap-icon-enroll_dual .path2:before {
  content: "\ea88";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-erase .path1:before {
  content: "\ea89";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-erase .path2:before {
  content: "\ea8a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-erase .path3:before {
  content: "\ea8b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-erase_dual .path1:before {
  content: "\ea8c";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-erase_dual .path2:before {
  content: "\ea8d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-erase_dual .path3:before {
  content: "\ea8e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-error:before {
  content: "\ea8f";
  color: #e6eaf0; }

.darkTheme .snap-icon-error_filled_color .path1:before {
  content: "\ea90";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-error_filled_color .path2:before {
  content: "\ea91";
  margin-left: -1em;
  color: #e06651; }

.darkTheme .snap-icon-Expand-categories:before {
  content: "\ea92";
  color: #e6eaf0; }

.darkTheme .snap-icon-export:before {
  content: "\ea93";
  color: #e6eaf0; }

.darkTheme .snap-icon-export_dual .path1:before {
  content: "\ea94";
  color: #e6eaf0; }

.darkTheme .snap-icon-export_dual .path2:before {
  content: "\ea95";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-ext_link .path1:before {
  content: "\ea96";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-ext_link .path2:before {
  content: "\ea97";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-ext_link .path3:before {
  content: "\ea98";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-ext_link_dual .path1:before {
  content: "\ea99";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-ext_link_dual .path2:before {
  content: "\ea9a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-ext_link_dual .path3:before {
  content: "\ea9b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-external_data:before {
  content: "\ea9c";
  color: #e6eaf0; }

.darkTheme .snap-icon-external_data_dual .path1:before {
  content: "\ea9d";
  color: #e6eaf0; }

.darkTheme .snap-icon-external_data_dual .path2:before {
  content: "\ea9e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-external_data_dual .path3:before {
  content: "\ea9f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-external_data_dual .path4:before {
  content: "\eaa0";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-external_data_dual .path5:before {
  content: "\eaa1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-external_input:before {
  content: "\eaa2";
  color: #e6eaf0; }

.darkTheme .snap-icon-external_input_dual .path1:before {
  content: "\eaa3";
  color: #e6eaf0; }

.darkTheme .snap-icon-external_input_dual .path2:before {
  content: "\eaa4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-feedback:before {
  content: "\eaa5";
  color: #e6eaf0; }

.darkTheme .snap-icon-feedback_dual .path1:before {
  content: "\eaa6";
  color: #3884ff; }

.darkTheme .snap-icon-feedback_dual .path2:before {
  content: "\eaa7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-feedback_dual .path3:before {
  content: "\eaa8";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-feedback_dual .path4:before {
  content: "\eaa9";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-feedback_dual .path5:before {
  content: "\eaaa";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-feedback_dual .path6:before {
  content: "\eaab";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-field:before {
  content: "\eaac";
  color: #e6eaf0; }

.darkTheme .snap-icon-field_dual .path1:before {
  content: "\eaad";
  color: #e6eaf0; }

.darkTheme .snap-icon-field_dual .path2:before {
  content: "\eaae";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-fifiletype_xls:before {
  content: "\eaaf";
  color: #e6eaf0; }

.darkTheme .snap-icon-fifiletype_xls_dual .path1:before {
  content: "\eab0";
  color: #e6eaf0; }

.darkTheme .snap-icon-fifiletype_xls_dual .path2:before {
  content: "\eab1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-fifiletype_xls_dual .path3:before {
  content: "\eab2";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-fifiletype_xls_dual .path4:before {
  content: "\eab3";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-file:before {
  content: "\eab4";
  color: #e6eaf0; }

.darkTheme .snap-icon-file_upload:before {
  content: "\eab5";
  color: #e6eaf0; }

.darkTheme .snap-icon-file_upload_dual .path1:before {
  content: "\eab6";
  color: #e6eaf0; }

.darkTheme .snap-icon-file_upload_dual .path2:before {
  content: "\eab7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-file_upload_dual .path3:before {
  content: "\eab8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-filetype_xml:before {
  content: "\eab9";
  color: #e6eaf0; }

.darkTheme .snap-icon-filetype_xml_dual .path1:before {
  content: "\eaba";
  color: #e6eaf0; }

.darkTheme .snap-icon-filetype_xml_dual .path2:before {
  content: "\eabb";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-filetype_xml_dual .path3:before {
  content: "\eabc";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-filetype_xml_dual .path4:before {
  content: "\eabd";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-Filter:before {
  content: "\eabe";
  color: #e6eaf0; }

.darkTheme .snap-icon-fit_to_height:before {
  content: "\eabf";
  color: #e6eaf0; }

.darkTheme .snap-icon-fit_to_screen:before {
  content: "\eac0";
  color: #e6eaf0; }

.darkTheme .snap-icon-fit_to_screen_dual .path1:before {
  content: "\eac1";
  color: #3884ff; }

.darkTheme .snap-icon-fit_to_screen_dual .path2:before {
  content: "\eac2";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-fit_to_screen_dual .path3:before {
  content: "\eac3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-fit_to_width:before {
  content: "\eac4";
  color: #e6eaf0; }

.darkTheme .snap-icon-flag_filled:before {
  content: "\eac5";
  color: #e6eaf0; }

.darkTheme .snap-icon-flag_filled_color:before {
  content: "\eac6";
  color: #5ab883; }

.darkTheme .snap-icon-font .path1:before {
  content: "\eac7";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-font .path2:before {
  content: "\eac8";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-font .path3:before {
  content: "\eac9";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-font_dual .path1:before {
  content: "\eaca";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-font_dual .path2:before {
  content: "\eacb";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-font_dual .path3:before {
  content: "\eacc";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-footer:before {
  content: "\eacd";
  color: #e6eaf0; }

.darkTheme .snap-icon-footer_dual .path1:before {
  content: "\eace";
  color: #e6eaf0; }

.darkTheme .snap-icon-footer_dual .path2:before {
  content: "\eacf";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-form:before {
  content: "\ead0";
  color: #e6eaf0; }

.darkTheme .snap-icon-form_dual .path1:before {
  content: "\ead1";
  color: #e6eaf0; }

.darkTheme .snap-icon-form_dual .path2:before {
  content: "\ead2";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-form_dual .path3:before {
  content: "\ead3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-form_dual .path4:before {
  content: "\ead4";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-form_dual .path5:before {
  content: "\ead5";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-form_dual .path6:before {
  content: "\ead6";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-four_preset:before {
  content: "\ead7";
  color: #e6eaf0; }

.darkTheme .snap-icon-FTP:before {
  content: "\ead8";
  color: #e6eaf0; }

.darkTheme .snap-icon-FTP_dual .path1:before {
  content: "\ead9";
  color: #e6eaf0; }

.darkTheme .snap-icon-FTP_dual .path2:before {
  content: "\eada";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-FTP_dual .path3:before {
  content: "\eadb";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-get_logs .path1:before {
  content: "\eadc";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-get_logs .path2:before {
  content: "\eadd";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs .path3:before {
  content: "\eade";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs .path4:before {
  content: "\eadf";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs .path5:before {
  content: "\eae0";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs .path6:before {
  content: "\eae1";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs .path7:before {
  content: "\eae2";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs_dual .path1:before {
  content: "\eae3";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-get_logs_dual .path2:before {
  content: "\eae4";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs_dual .path3:before {
  content: "\eae5";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs_dual .path4:before {
  content: "\eae6";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs_dual .path5:before {
  content: "\eae7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs_dual .path6:before {
  content: "\eae8";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-get_logs_dual .path7:before {
  content: "\eae9";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-global_variable:before {
  content: "\eaea";
  color: #e6eaf0; }

.darkTheme .snap-icon-global_variable_dual .path1:before {
  content: "\eaeb";
  color: #3884ff; }

.darkTheme .snap-icon-global_variable_dual .path2:before {
  content: "\eaec";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-Gmail:before {
  content: "\eaed";
  color: #e6eaf0; }

.darkTheme .snap-icon-Gmail_dual .path1:before {
  content: "\eaee";
  color: #e6eaf0; }

.darkTheme .snap-icon-Gmail_dual .path2:before {
  content: "\eaef";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-Gmail_dual .path3:before {
  content: "\eaf0";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-google:before {
  content: "\eaf1";
  color: #e6eaf0; }

.darkTheme .snap-icon-google_drive:before {
  content: "\eaf2";
  color: #e6eaf0; }

.darkTheme .snap-icon-google_drive_dual .path1:before {
  content: "\eaf3";
  color: #e6eaf0; }

.darkTheme .snap-icon-google_drive_dual .path2:before {
  content: "\eaf4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-grid:before {
  content: "\eaf5";
  color: #e6eaf0; }

.darkTheme .snap-icon-grid_dual .path1:before {
  content: "\eaf6";
  color: #e6eaf0; }

.darkTheme .snap-icon-grid_dual .path2:before {
  content: "\eaf7";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-grid_dual .path3:before {
  content: "\eaf8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-grid_view:before {
  content: "\eaf9";
  color: #e6eaf0; }

.darkTheme .snap-icon-grid_view_dual .path1:before {
  content: "\eafa";
  color: #e6eaf0; }

.darkTheme .snap-icon-grid_view_dual .path2:before {
  content: "\eafb";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-grid_view_dual .path3:before {
  content: "\eafc";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-grid_view_dual .path4:before {
  content: "\eafd";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-group_approval:before {
  content: "\eafe";
  color: #e6eaf0; }

.darkTheme .snap-icon-group_approval_dual .path1:before {
  content: "\eaff";
  color: #e6eaf0; }

.darkTheme .snap-icon-group_approval_dual .path2:before {
  content: "\eb00";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-group_approval_dual .path3:before {
  content: "\eb01";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-group_approval_dual .path4:before {
  content: "\eb02";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-group_approval_dual .path5:before {
  content: "\eb03";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-group_list:before {
  content: "\eb04";
  color: #e6eaf0; }

.darkTheme .snap-icon-group_list_dual .path1:before {
  content: "\eb05";
  color: #3884ff; }

.darkTheme .snap-icon-group_list_dual .path2:before {
  content: "\eb06";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-group_list_dual .path3:before {
  content: "\eb07";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-group_list_dual .path4:before {
  content: "\eb08";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-group_list_dual .path5:before {
  content: "\eb09";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-group_list_dual .path6:before {
  content: "\eb0a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-group_list_dual .path7:before {
  content: "\eb0b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-groups .path1:before {
  content: "\eb0c";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-groups .path2:before {
  content: "\eb0d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-groups .path3:before {
  content: "\eb0e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-groups_dual .path1:before {
  content: "\eb0f";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-groups_dual .path2:before {
  content: "\eb10";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-groups_dual .path3:before {
  content: "\eb11";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-header:before {
  content: "\eb12";
  color: #e6eaf0; }

.darkTheme .snap-icon-header_dual .path1:before {
  content: "\eb13";
  color: #e6eaf0; }

.darkTheme .snap-icon-header_dual .path2:before {
  content: "\eb14";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-heart:before {
  content: "\eb15";
  color: #e6eaf0; }

.darkTheme .snap-icon-heart_filled:before {
  content: "\eb16";
  color: #e6eaf0; }

.darkTheme .snap-icon-help:before {
  content: "\eb17";
  color: #e6eaf0; }

.darkTheme .snap-icon-help_dual .path1:before {
  content: "\eb18";
  color: #e6eaf0; }

.darkTheme .snap-icon-help_dual .path2:before {
  content: "\eb19";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-help_dual .path3:before {
  content: "\eb1a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-help_dual .path4:before {
  content: "\eb1b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-help_dual .path5:before {
  content: "\eb1c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-home_filled:before {
  content: "\eb1d";
  color: #e6eaf0; }

.darkTheme .snap-icon-home_filled_color:before {
  content: "\eb1e";
  color: #cb69a7; }

.darkTheme .snap-icon-horizontal_bar_chart:before {
  content: "\eb1f";
  color: #e6eaf0; }

.darkTheme .snap-icon-horizontal_bar_chart_dual .path1:before {
  content: "\eb20";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-horizontal_bar_chart_dual .path2:before {
  content: "\eb21";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-horizontal_bar_chart_dual .path3:before {
  content: "\eb22";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-image:before {
  content: "\eb23";
  color: #e6eaf0; }

.darkTheme .snap-icon-image_dual .path1:before {
  content: "\eb24";
  color: #e6eaf0; }

.darkTheme .snap-icon-image_dual .path2:before {
  content: "\eb25";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-image_dual .path3:before {
  content: "\eb26";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-inbox:before {
  content: "\eb27";
  color: #e6eaf0; }

.darkTheme .snap-icon-inbox_dual .path1:before {
  content: "\eb28";
  color: #e6eaf0; }

.darkTheme .snap-icon-inbox_dual .path2:before {
  content: "\eb29";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-info:before {
  content: "\eb2a";
  color: #e6eaf0; }

.darkTheme .snap-icon-info_dual .path1:before {
  content: "\eb2b";
  color: #3884ff; }

.darkTheme .snap-icon-info_dual .path2:before {
  content: "\eb2c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-info_dual .path3:before {
  content: "\eb2d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-info_filled-color .path1:before {
  content: "\eb2e";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-info_filled-color .path2:before {
  content: "\eb2f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-info_filled:before {
  content: "\eb30";
  color: #e6eaf0; }

.darkTheme .snap-icon-inline_input:before {
  content: "\eb31";
  color: #e6eaf0; }

.darkTheme .snap-icon-inline_input_dual .path1:before {
  content: "\eb32";
  color: #e6eaf0; }

.darkTheme .snap-icon-inline_input_dual .path2:before {
  content: "\eb33";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-label:before {
  content: "\eb34";
  color: #e6eaf0; }

.darkTheme .snap-icon-label_dual .path1:before {
  content: "\eb35";
  color: #e6eaf0; }

.darkTheme .snap-icon-label_dual .path2:before {
  content: "\eb36";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-label_dual .path3:before {
  content: "\eb37";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-label_dual .path4:before {
  content: "\eb38";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-launch:before {
  content: "\eb39";
  color: #e6eaf0; }

.darkTheme .snap-icon-launch_dual .path1:before {
  content: "\eb3a";
  color: #e6eaf0; }

.darkTheme .snap-icon-launch_dual .path2:before {
  content: "\eb3b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-launch_dual .path3:before {
  content: "\eb3c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-layers:before {
  content: "\eb3d";
  color: #e6eaf0; }

.darkTheme .snap-icon-layers_dual .path1:before {
  content: "\eb3e";
  color: #e6eaf0; }

.darkTheme .snap-icon-layers_dual .path2:before {
  content: "\eb3f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-layers_dual .path3:before {
  content: "\eb40";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-layers_dual .path4:before {
  content: "\eb41";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-layout:before {
  content: "\eb42";
  color: #e6eaf0; }

.darkTheme .snap-icon-layout_dual .path1:before {
  content: "\eb43";
  color: #e6eaf0; }

.darkTheme .snap-icon-layout_dual .path2:before {
  content: "\eb44";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-layout_dual .path3:before {
  content: "\eb45";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-layout_dual .path4:before {
  content: "\eb46";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-line_chart .path1:before {
  content: "\eb47";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-line_chart .path2:before {
  content: "\eb48";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-line_chart_dual .path1:before {
  content: "\eb49";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-line_chart_dual .path2:before {
  content: "\eb4a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-line_chart_dual .path3:before {
  content: "\eb4b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-line_chart_dual .path4:before {
  content: "\eb4c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-line_chart_dual .path5:before {
  content: "\eb4d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-line_chart_dual .path6:before {
  content: "\eb4e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-link:before {
  content: "\eb4f";
  color: #e6eaf0; }

.darkTheme .snap-icon-link_dual .path1:before {
  content: "\eb50";
  color: #e6eaf0; }

.darkTheme .snap-icon-link_dual .path2:before {
  content: "\eb51";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-link_dual .path3:before {
  content: "\eb52";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list:before {
  content: "\eb53";
  color: #e6eaf0; }

.darkTheme .snap-icon-list_bulleted:before {
  content: "\eb54";
  color: #e6eaf0; }

.darkTheme .snap-icon-list_bulleted_dual .path1:before {
  content: "\eb55";
  color: #e6eaf0; }

.darkTheme .snap-icon-list_bulleted_dual .path2:before {
  content: "\eb56";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-list_bulleted_dual .path3:before {
  content: "\eb57";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_bulleted_dual .path4:before {
  content: "\eb58";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_dual .path1:before {
  content: "\eb59";
  color: #e6eaf0; }

.darkTheme .snap-icon-list_dual .path2:before {
  content: "\eb5a";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_dual .path3:before {
  content: "\eb5b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_dual .path4:before {
  content: "\eb5c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_dual .path5:before {
  content: "\eb5d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_dual .path6:before {
  content: "\eb5e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_dual .path7:before {
  content: "\eb5f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_empty:before {
  content: "\eb60";
  color: #9fadbc; }

.darkTheme .snap-icon-list_numbered:before {
  content: "\eb61";
  color: #e6eaf0; }

.darkTheme .snap-icon-list_numbered_dual .path1:before {
  content: "\eb62";
  color: #e6eaf0; }

.darkTheme .snap-icon-list_numbered_dual .path2:before {
  content: "\eb63";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-list_numbered_dual .path3:before {
  content: "\eb64";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list_numbered_dual .path4:before {
  content: "\eb65";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-list2:before {
  content: "\eb66";
  color: #e6eaf0; }

.darkTheme .snap-icon-list2_dual .path1:before {
  content: "\eb67";
  color: #e6eaf0; }

.darkTheme .snap-icon-list2_dual .path2:before {
  content: "\eb68";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-list2_dual .path3:before {
  content: "\eb69";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-list2_dual .path4:before {
  content: "\eb6a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-list2_dual .path5:before {
  content: "\eb6b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-list2_dual .path6:before {
  content: "\eb6c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-list2_dual .path7:before {
  content: "\eb6d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-loading:before {
  content: "\eb6e";
  color: #9fadbc; }

.darkTheme .snap-icon-location:before {
  content: "\eb6f";
  color: #e6eaf0; }

.darkTheme .snap-icon-location_dual .path1:before {
  content: "\eb70";
  color: #e6eaf0; }

.darkTheme .snap-icon-location_dual .path2:before {
  content: "\eb71";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-location_dual .path3:before {
  content: "\eb72";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-location_filled:before {
  content: "\eb73";
  color: #e6eaf0; }

.darkTheme .snap-icon-location_filled_color:before {
  content: "\eb74";
  color: #e06651; }

.darkTheme .snap-icon-lock:before {
  content: "\eb75";
  color: #e6eaf0; }

.darkTheme .snap-icon-lock_dual .path1:before {
  content: "\eb76";
  color: #e6eaf0; }

.darkTheme .snap-icon-lock_dual .path2:before {
  content: "\eb77";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-lockdown:before {
  content: "\eb78";
  color: #e6eaf0; }

.darkTheme .snap-icon-lockdown_app_icon .path1:before {
  content: "\eb79";
  color: #42825e; }

.darkTheme .snap-icon-lockdown_app_icon .path2:before {
  content: "\eb7a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-logic .path1:before {
  content: "\eb7b";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-logic .path2:before {
  content: "\eb7c";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-logic_dual .path1:before {
  content: "\eb7d";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-logic_dual .path2:before {
  content: "\eb7e";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-logic_dual .path3:before {
  content: "\eb7f";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-logout:before {
  content: "\eb80";
  color: #e6eaf0; }

.darkTheme .snap-icon-logout_dual .path1:before {
  content: "\eb81";
  color: #e6eaf0; }

.darkTheme .snap-icon-logout_dual .path2:before {
  content: "\eb82";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-loop .path1:before {
  content: "\eb83";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-loop .path2:before {
  content: "\eb84";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-loop .path3:before {
  content: "\eb85";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-loop_dual .path1:before {
  content: "\eb86";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-loop_dual .path2:before {
  content: "\eb87";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-loop_dual .path3:before {
  content: "\eb88";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-manage_environments:before {
  content: "\eb89";
  color: #e6eaf0; }

.darkTheme .snap-icon-manage_environments_dual .path1:before {
  content: "\eb8a";
  color: #e6eaf0; }

.darkTheme .snap-icon-manage_environments_dual .path2:before {
  content: "\eb8b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-manage_environments_dual .path3:before {
  content: "\eb8c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-manage_environments_dual .path4:before {
  content: "\eb8d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-manage_environments_dual .path5:before {
  content: "\eb8e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-manager:before {
  content: "\eb8f";
  color: #e6eaf0; }

.darkTheme .snap-icon-manager_dual .path1:before {
  content: "\eb90";
  color: #e6eaf0; }

.darkTheme .snap-icon-manager_dual .path2:before {
  content: "\eb91";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-manager_dual .path3:before {
  content: "\eb92";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-map:before {
  content: "\eb93";
  color: #e6eaf0; }

.darkTheme .snap-icon-map_dual .path1:before {
  content: "\eb94";
  color: #e6eaf0; }

.darkTheme .snap-icon-map_dual .path2:before {
  content: "\eb95";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-map_dual .path3:before {
  content: "\eb96";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-map_dual .path4:before {
  content: "\eb97";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-margin:before {
  content: "\eb98";
  color: #e6eaf0; }

.darkTheme .snap-icon-masked:before {
  content: "\eb99";
  color: #e6eaf0; }

.darkTheme .snap-icon-masked_dual .path1:before {
  content: "\eb9a";
  color: #e6eaf0; }

.darkTheme .snap-icon-masked_dual .path2:before {
  content: "\eb9b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-masked_dual .path3:before {
  content: "\eb9c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-masked_dual .path4:before {
  content: "\eb9d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-masked_dual .path5:before {
  content: "\eb9e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-maths .path1:before {
  content: "\eb9f";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-maths .path2:before {
  content: "\eba0";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-maths .path3:before {
  content: "\eba1";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-maths_dual .path1:before {
  content: "\eba2";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-maths_dual .path2:before {
  content: "\eba3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-maths_dual .path3:before {
  content: "\eba4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-maximize:before {
  content: "\eba5";
  color: #e6eaf0; }

.darkTheme .snap-icon-menu:before {
  content: "\eba6";
  color: #e6eaf0; }

.darkTheme .snap-icon-menu_dual .path1:before {
  content: "\eba7";
  color: #e6eaf0; }

.darkTheme .snap-icon-menu_dual .path2:before {
  content: "\eba8";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-menu_dual .path3:before {
  content: "\eba9";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-menu_dual .path4:before {
  content: "\ebaa";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-menu_dual .path5:before {
  content: "\ebab";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-menu_dual .path6:before {
  content: "\ebac";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-minimize:before {
  content: "\ebad";
  color: #e6eaf0; }

.darkTheme .snap-icon-mobicontrol_filled:before {
  content: "\ebae"; }

.darkTheme .snap-icon-mobile_landscape:before {
  content: "\ebaf";
  color: #e6eaf0; }

.darkTheme .snap-icon-mobile_landscape_dual .path1:before {
  content: "\ebb0";
  color: #e6eaf0; }

.darkTheme .snap-icon-mobile_landscape_dual .path2:before {
  content: "\ebb1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-mobile_protrait:before {
  content: "\ebb2";
  color: #e6eaf0; }

.darkTheme .snap-icon-mobile_protrait_dual .path1:before {
  content: "\ebb3";
  color: #e6eaf0; }

.darkTheme .snap-icon-mobile_protrait_dual .path2:before {
  content: "\ebb4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-modal_popup:before {
  content: "\ebb5";
  color: #e6eaf0; }

.darkTheme .snap-icon-modal_popup_blue:before {
  content: "\ebb6";
  color: #3884ff; }

.darkTheme .snap-icon-modal_popup_dual .path1:before {
  content: "\ebb7";
  color: #e6eaf0; }

.darkTheme .snap-icon-modal_popup_dual .path2:before {
  content: "\ebb8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-more:before {
  content: "\ebb9"; }

.darkTheme .snap-icon-move_to_builder:before {
  content: "\ebba";
  color: #e6eaf0; }

.darkTheme .snap-icon-move_to_builder_dual .path1:before {
  content: "\ebbb";
  color: #3884ff; }

.darkTheme .snap-icon-move_to_builder_dual .path2:before {
  content: "\ebbc";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-move_to_group .path1:before {
  content: "\ebbd";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-move_to_group .path2:before {
  content: "\ebbe";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-move_to_group .path3:before {
  content: "\ebbf";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-move_to_group_dual .path1:before {
  content: "\ebc0";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-move_to_group_dual .path2:before {
  content: "\ebc1";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-move_to_group_dual .path3:before {
  content: "\ebc2";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-my_request:before {
  content: "\ebc3";
  color: #e6eaf0; }

.darkTheme .snap-icon-my_request_dual .path1:before {
  content: "\ebc4";
  color: #e6eaf0; }

.darkTheme .snap-icon-my_request_dual .path2:before {
  content: "\ebc5";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-navigator:before {
  content: "\ebc6";
  color: #e6eaf0; }

.darkTheme .snap-icon-navigator_dual .path1:before {
  content: "\ebc7";
  color: #3884ff; }

.darkTheme .snap-icon-navigator_dual .path2:before {
  content: "\ebc8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-navigator_dual .path3:before {
  content: "\ebc9";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-navigator_dual .path4:before {
  content: "\ebca";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-navigator_dual .path5:before {
  content: "\ebcb";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-navigator_dual .path6:before {
  content: "\ebcc";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-navigator_dual .path7:before {
  content: "\ebcd";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-navigator_dual .path8:before {
  content: "\ebce";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-nfc:before {
  content: "\ebcf";
  color: #e6eaf0; }

.darkTheme .snap-icon-nfc_dual .path1:before {
  content: "\ebd0";
  color: #e6eaf0; }

.darkTheme .snap-icon-nfc_dual .path2:before {
  content: "\ebd1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-nfc_dual .path3:before {
  content: "\ebd2";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-number .path1:before {
  content: "\ebd3";
  color: #e6eaf0; }

.darkTheme .snap-icon-number .path2:before {
  content: "\ebd4";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-number .path3:before {
  content: "\ebd5";
  margin-left: -1em;
  color: white; }

.darkTheme .snap-icon-number .path4:before {
  content: "\ebd6";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-number_chart:before {
  content: "\ebd7"; }

.darkTheme .snap-icon-number_chart_dual:before {
  content: "\ebd8"; }

.darkTheme .snap-icon-number_dual .path1:before {
  content: "\ebd9";
  color: #e6eaf0; }

.darkTheme .snap-icon-number_dual .path2:before {
  content: "\ebda";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-number_dual .path3:before {
  content: "\ebdb";
  margin-left: -1em;
  color: white; }

.darkTheme .snap-icon-number_dual .path4:before {
  content: "\ebdc";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-on_click:before {
  content: "\ebdd";
  color: #e6eaf0; }

.darkTheme .snap-icon-on_page_load:before {
  content: "\ebde";
  color: #e6eaf0; }

.darkTheme .snap-icon-onedrive:before {
  content: "\ebdf";
  color: #e6eaf0; }

.darkTheme .snap-icon-onedrive_dual .path1:before {
  content: "\ebe0";
  color: #e6eaf0; }

.darkTheme .snap-icon-onedrive_dual .path2:before {
  content: "\ebe1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-operator_between:before {
  content: "\ebe2";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_between_not:before {
  content: "\ebe3";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_contains:before {
  content: "\ebe4";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_contains_not:before {
  content: "\ebe5";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_ends_with:before {
  content: "\ebe6";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_ends_withnot:before {
  content: "\ebe7";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_greater_than:before {
  content: "\ebe8";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_greater_than_equals_to:before {
  content: "\ebe9";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_in:before {
  content: "\ebea";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_is:before {
  content: "\ebeb";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_is_empty:before {
  content: "\ebec";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_is_not:before {
  content: "\ebed";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_is_not_empty:before {
  content: "\ebee";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_is_not_null:before {
  content: "\ebef";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_is_null:before {
  content: "\ebf0";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_less_than:before {
  content: "\ebf1";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_less_than_equals_to:before {
  content: "\ebf2";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_not_in:before {
  content: "\ebf3";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_preceeds:before {
  content: "\ebf4";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_starts_with:before {
  content: "\ebf5";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_starts_with_not:before {
  content: "\ebf6";
  color: #e6eaf0; }

.darkTheme .snap-icon-operator_succeeds:before {
  content: "\ebf7";
  color: #e6eaf0; }

.darkTheme .snap-icon-orientation:before {
  content: "\ebf8";
  color: #e6eaf0; }

.darkTheme .snap-icon-orientation_dual .path1:before {
  content: "\ebf9";
  color: #e6eaf0; }

.darkTheme .snap-icon-orientation_dual .path2:before {
  content: "\ebfa";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-padding:before {
  content: "\ebfb";
  color: #e6eaf0; }

.darkTheme .snap-icon-paid_badge .path1:before {
  content: "\ebfc";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-paid_badge .path2:before {
  content: "\ebfd";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-paid_badge .path3:before {
  content: "\ebfe";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-paid_badge_dual .path1:before {
  content: "\ebff";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-paid_badge_dual .path2:before {
  content: "\ec00";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-paid_badge_dual .path3:before {
  content: "\ec01";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-paintbrush:before {
  content: "\ec02";
  color: #e6eaf0; }

.darkTheme .snap-icon-paintbrush_dual .path1:before {
  content: "\ec03";
  color: #e6eaf0; }

.darkTheme .snap-icon-paintbrush_dual .path2:before {
  content: "\ec04";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-paragraph:before {
  content: "\ec05";
  color: #e6eaf0; }

.darkTheme .snap-icon-paragraph_dual .path1:before {
  content: "\ec06";
  color: #e6eaf0; }

.darkTheme .snap-icon-paragraph_dual .path2:before {
  content: "\ec07";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-password .path1:before {
  content: "\ec08";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-password .path2:before {
  content: "\ec09";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-password .path3:before {
  content: "\ec0a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-password_dual .path1:before {
  content: "\ec0b";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-password_dual .path2:before {
  content: "\ec0c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-password_dual .path3:before {
  content: "\ec0d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-password_dual .path4:before {
  content: "\ec0e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-pdf:before {
  content: "\ec0f";
  color: #e6eaf0; }

.darkTheme .snap-icon-pdf_dual .path1:before {
  content: "\ec10";
  color: #e6eaf0; }

.darkTheme .snap-icon-pdf_dual .path2:before {
  content: "\ec11";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-pdf_dual .path3:before {
  content: "\ec12";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-pdf_dual .path4:before {
  content: "\ec13";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-pdf_dual .path5:before {
  content: "\ec14";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-pdf_dual .path6:before {
  content: "\ec15";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-pdf_dual .path7:before {
  content: "\ec16";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-pending_color:before {
  content: "\ec17";
  color: #cf8a23; }

.darkTheme .snap-icon-phone:before {
  content: "\ec18";
  color: #e6eaf0; }

.darkTheme .snap-icon-pie_chart:before {
  content: "\ec19";
  color: #e6eaf0; }

.darkTheme .snap-icon-pie_chart_dual .path1:before {
  content: "\ec1a";
  color: #e6eaf0; }

.darkTheme .snap-icon-pie_chart_dual .path2:before {
  content: "\ec1b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-pin_filled .path1:before {
  content: "\ec1c";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-pin_filled .path2:before {
  content: "\ec1d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-pin_filled_color .path1:before {
  content: "\ec1e";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-pin_filled_color .path2:before {
  content: "\ec1f";
  margin-left: -1em;
  color: #cf8a23; }

.darkTheme .snap-icon-play_outline .path1:before {
  content: "\ec20";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-play_outline .path2:before {
  content: "\ec21";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-play_outline .path3:before {
  content: "\ec22";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-play_outline_dual .path1:before {
  content: "\ec23";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-play_outline_dual .path2:before {
  content: "\ec24";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-play_outline_dual .path3:before {
  content: "\ec25";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-popup:before {
  content: "\ec26";
  color: #e6eaf0; }

.darkTheme .snap-icon-popup_dual .path1:before {
  content: "\ec27";
  color: #e6eaf0; }

.darkTheme .snap-icon-popup_dual .path2:before {
  content: "\ec28";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-popup_dual .path3:before {
  content: "\ec29";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-popup_dual .path4:before {
  content: "\ec2a";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-position_center:before {
  content: "\ec2b";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_center_dual .path1:before {
  content: "\ec2c";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_center_dual .path2:before {
  content: "\ec2d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-position_left:before {
  content: "\ec2e";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_left_dual .path1:before {
  content: "\ec2f";
  color: #3884ff; }

.darkTheme .snap-icon-position_left_dual .path2:before {
  content: "\ec30";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-position_left_dual .path3:before {
  content: "\ec31";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-position_right:before {
  content: "\ec32";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_right_dual .path1:before {
  content: "\ec33";
  color: #3884ff; }

.darkTheme .snap-icon-position_right_dual .path2:before {
  content: "\ec34";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-position_right_dual .path3:before {
  content: "\ec35";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_bottom:before {
  content: "\ec36";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_bottom_dual .path1:before {
  content: "\ec37";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_bottom_dual .path2:before {
  content: "\ec38";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-position_vertical_bottom_dual .path3:before {
  content: "\ec39";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_center:before {
  content: "\ec3a";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_center_dual .path1:before {
  content: "\ec3b";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_center_dual .path2:before {
  content: "\ec3c";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-position_vertical_top:before {
  content: "\ec3d";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_top_dual .path1:before {
  content: "\ec3e";
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_top_dual .path2:before {
  content: "\ec3f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-position_vertical_top_dual .path3:before {
  content: "\ec40";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-Postion_middle:before {
  content: "\ec41";
  color: #e6eaf0; }

.darkTheme .snap-icon-Postion_top:before {
  content: "\ec42";
  color: #e6eaf0; }

.darkTheme .snap-icon-prefrences:before {
  content: "\ec43";
  color: #e6eaf0; }

.darkTheme .snap-icon-prefrences_dual .path1:before {
  content: "\ec44";
  color: #e6eaf0; }

.darkTheme .snap-icon-prefrences_dual .path2:before {
  content: "\ec45";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-prefrences_dual .path3:before {
  content: "\ec46";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-prefrences_dual .path4:before {
  content: "\ec47";
  margin-left: -1em;
  color: none; }

.darkTheme .snap-icon-preview:before {
  content: "\ec48";
  color: #e6eaf0; }

.darkTheme .snap-icon-preview_dual .path1:before {
  content: "\ec49";
  color: #e6eaf0; }

.darkTheme .snap-icon-preview_dual .path2:before {
  content: "\ec4a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-preview_dual .path3:before {
  content: "\ec4b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-published_color:before {
  content: "\ec4c";
  color: #5ab883; }

.darkTheme .snap-icon-QR-code:before {
  content: "\ec4d";
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path1:before {
  content: "\ec4e";
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path2:before {
  content: "\ec4f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path3:before {
  content: "\ec50";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path4:before {
  content: "\ec51";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path5:before {
  content: "\ec52";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path6:before {
  content: "\ec53";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path7:before {
  content: "\ec54";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path8:before {
  content: "\ec55";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path9:before {
  content: "\ec56";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path10:before {
  content: "\ec57";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path11:before {
  content: "\ec58";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path12:before {
  content: "\ec59";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path13:before {
  content: "\ec5a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path14:before {
  content: "\ec5b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-QR-code_dual .path15:before {
  content: "\ec5c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-QR-code_dual .path16:before {
  content: "\ec5d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-QR-code_dual .path17:before {
  content: "\ec5e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-QR-code_dual .path18:before {
  content: "\ec5f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-QR-code_dual .path19:before {
  content: "\ec60";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-QR-code_dual .path20:before {
  content: "\ec61";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-QR-code_dual .path21:before {
  content: "\ec62";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-QR-code_dual .path22:before {
  content: "\ec63";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-query_string:before {
  content: "\ec64";
  color: #e6eaf0; }

.darkTheme .snap-icon-query_string_dual .path1:before {
  content: "\ec65";
  color: #e6eaf0; }

.darkTheme .snap-icon-query_string_dual .path2:before {
  content: "\ec66";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-query_string_dual .path3:before {
  content: "\ec67";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-query_string_dual .path4:before {
  content: "\ec68";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-radio_button:before {
  content: "\ec69";
  color: #e6eaf0; }

.darkTheme .snap-icon-radio_button_dual .path1:before {
  content: "\ec6a";
  color: #e6eaf0; }

.darkTheme .snap-icon-radio_button_dual .path2:before {
  content: "\ec6b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-radio_button_dual .path3:before {
  content: "\ec6c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-radio_button_outline:before {
  content: "\ec6d";
  color: #e6eaf0; }

.darkTheme .snap-icon-radius_left_bottom:before {
  content: "\ec6e";
  color: #e6eaf0; }

.darkTheme .snap-icon-radius_left_top:before {
  content: "\ec6f";
  color: #e6eaf0; }

.darkTheme .snap-icon-radius_right_bottom:before {
  content: "\ec70";
  color: #e6eaf0; }

.darkTheme .snap-icon-radius_right_top:before {
  content: "\ec71";
  color: #e6eaf0; }

.darkTheme .snap-icon-rating:before {
  content: "\ec72";
  color: #e6eaf0; }

.darkTheme .snap-icon-rating_dual .path1:before {
  content: "\ec73";
  color: #e6eaf0; }

.darkTheme .snap-icon-rating_dual .path2:before {
  content: "\ec74";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-redo:before {
  content: "\ec75";
  color: #e6eaf0; }

.darkTheme .snap-icon-redo_dual .path1:before {
  content: "\ec76";
  color: #e6eaf0; }

.darkTheme .snap-icon-redo_dual .path2:before {
  content: "\ec77";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-refresh:before {
  content: "\ec78";
  color: #e6eaf0; }

.darkTheme .snap-icon-remove:before {
  content: "\ec79";
  color: #e6eaf0; }

.darkTheme .snap-icon-remove_dual .path1:before {
  content: "\ec7a";
  color: #e6eaf0; }

.darkTheme .snap-icon-remove_dual .path2:before {
  content: "\ec7b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-remove_user .path1:before {
  content: "\ec7c";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-remove_user .path2:before {
  content: "\ec7d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-remove_user .path3:before {
  content: "\ec7e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-remove_user .path4:before {
  content: "\ec7f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-remove_user_dual .path1:before {
  content: "\ec80";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-remove_user_dual .path2:before {
  content: "\ec81";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-remove_user_dual .path3:before {
  content: "\ec82";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-remove_user_dual .path4:before {
  content: "\ec83";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-repeater:before {
  content: "\ec84";
  color: #e6eaf0; }

.darkTheme .snap-icon-repeater_dual .path1:before {
  content: "\ec85";
  color: #e6eaf0; }

.darkTheme .snap-icon-repeater_dual .path2:before {
  content: "\ec86";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-report:before {
  content: "\ec87";
  color: #e6eaf0; }

.darkTheme .snap-icon-report_alt .path1:before {
  content: "\ec88";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-report_alt .path2:before {
  content: "\ec89";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-report_alt .path3:before {
  content: "\ec8a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-report_alt .path4:before {
  content: "\ec8b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-report_alt_dual .path1:before {
  content: "\ec8c";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-report_alt_dual .path2:before {
  content: "\ec8d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-report_alt_dual .path3:before {
  content: "\ec8e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-report_alt_dual .path4:before {
  content: "\ec8f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-report_dual .path1:before {
  content: "\ec90";
  color: #3884ff; }

.darkTheme .snap-icon-report_dual .path2:before {
  content: "\ec91";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-report_dual .path3:before {
  content: "\ec92";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-report_dual .path4:before {
  content: "\ec93";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-report_loading:before {
  content: "\ec94";
  color: #9fadbc; }

.darkTheme .snap-icon-rest_services:before {
  content: "\ec95";
  color: #e6eaf0; }

.darkTheme .snap-icon-rest_services_dual .path1:before {
  content: "\ec96";
  color: #3884ff; }

.darkTheme .snap-icon-rest_services_dual .path2:before {
  content: "\ec97";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rest_services_dual .path3:before {
  content: "\ec98";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rest_services_dual .path4:before {
  content: "\ec99";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rest_services_dual .path5:before {
  content: "\ec9a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration .path1:before {
  content: "\ec9b";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-roles_administration .path2:before {
  content: "\ec9c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration .path3:before {
  content: "\ec9d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration .path4:before {
  content: "\ec9e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration .path5:before {
  content: "\ec9f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration .path6:before {
  content: "\eca0";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration .path7:before {
  content: "\eca1";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration_dual .path1:before {
  content: "\eca2";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-roles_administration_dual .path2:before {
  content: "\eca3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration_dual .path3:before {
  content: "\eca4";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration_dual .path4:before {
  content: "\eca5";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration_dual .path5:before {
  content: "\eca6";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration_dual .path6:before {
  content: "\eca7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-roles_administration_dual .path7:before {
  content: "\eca8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_left:before {
  content: "\eca9";
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_left_dual .path1:before {
  content: "\ecaa";
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_left_dual .path2:before {
  content: "\ecab";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_left_dual .path3:before {
  content: "\ecac";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_left_dual .path4:before {
  content: "\ecad";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_left_dual .path5:before {
  content: "\ecae";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_left_dual .path6:before {
  content: "\ecaf";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_left_dual .path7:before {
  content: "\ecb0";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_left_dual .path8:before {
  content: "\ecb1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_left_dual .path9:before {
  content: "\ecb2";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_left_dual .path10:before {
  content: "\ecb3";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_left_dual .path11:before {
  content: "\ecb4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_right:before {
  content: "\ecb5";
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_right_dual .path1:before {
  content: "\ecb6";
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_right_dual .path2:before {
  content: "\ecb7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_right_dual .path3:before {
  content: "\ecb8";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_right_dual .path4:before {
  content: "\ecb9";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_right_dual .path5:before {
  content: "\ecba";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_right_dual .path6:before {
  content: "\ecbb";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-rotate_right_dual .path7:before {
  content: "\ecbc";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_right_dual .path8:before {
  content: "\ecbd";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_right_dual .path9:before {
  content: "\ecbe";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_right_dual .path10:before {
  content: "\ecbf";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-rotate_right_dual .path11:before {
  content: "\ecc0";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-Salesforce:before {
  content: "\ecc1";
  color: #fff; }

.darkTheme .snap-icon-save:before {
  content: "\ecc2";
  color: #e6eaf0; }

.darkTheme .snap-icon-save_dual .path1:before {
  content: "\ecc3";
  color: #3884ff; }

.darkTheme .snap-icon-save_dual .path2:before {
  content: "\ecc4";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-script:before {
  content: "\ecc5";
  color: #e6eaf0; }

.darkTheme .snap-icon-script_dual .path1:before {
  content: "\ecc6";
  color: #e6eaf0; }

.darkTheme .snap-icon-script_dual .path2:before {
  content: "\ecc7";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-script_dual .path3:before {
  content: "\ecc8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-search:before {
  content: "\ecc9";
  color: #e6eaf0; }

.darkTheme .snap-icon-search_dual .path1:before {
  content: "\ecca";
  color: #e6eaf0; }

.darkTheme .snap-icon-search_dual .path2:before {
  content: "\eccb";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-search_report:before {
  content: "\eccc";
  color: #e6eaf0; }

.darkTheme .snap-icon-section:before {
  content: "\eccd";
  color: #e6eaf0; }

.darkTheme .snap-icon-section_dual .path1:before {
  content: "\ecce";
  color: #e6eaf0; }

.darkTheme .snap-icon-section_dual .path2:before {
  content: "\eccf";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-send .path1:before {
  content: "\ecd0";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-send .path2:before {
  content: "\ecd1";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-send_dual .path1:before {
  content: "\ecd2";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-send_dual .path2:before {
  content: "\ecd3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-send_dual .path3:before {
  content: "\ecd4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-server_side_script:before {
  content: "\ecd5";
  color: #e6eaf0; }

.darkTheme .snap-icon-server_side_script_dual .path1:before {
  content: "\ecd6";
  color: #e6eaf0; }

.darkTheme .snap-icon-server_side_script_dual .path2:before {
  content: "\ecd7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-server_side_script_dual .path3:before {
  content: "\ecd8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-set_status:before {
  content: "\ecd9";
  color: #e6eaf0; }

.darkTheme .snap-icon-set_status_dual .path1:before {
  content: "\ecda";
  color: #3884ff; }

.darkTheme .snap-icon-set_status_dual .path2:before {
  content: "\ecdb";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-set_status_dual .path3:before {
  content: "\ecdc";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-set_value:before {
  content: "\ecdd";
  color: #e6eaf0; }

.darkTheme .snap-icon-set_value_dual .path1:before {
  content: "\ecde";
  color: #e6eaf0; }

.darkTheme .snap-icon-set_value_dual .path2:before {
  content: "\ecdf";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-set_value_dual .path3:before {
  content: "\ece0";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-share:before {
  content: "\ece1";
  color: #e6eaf0; }

.darkTheme .snap-icon-share_dual .path1:before {
  content: "\ece2";
  color: #e6eaf0; }

.darkTheme .snap-icon-share_dual .path2:before {
  content: "\ece3";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-share_dual .path3:before {
  content: "\ece4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-sharepoint .path1:before {
  content: "\ece5";
  color: #e6eaf0; }

.darkTheme .snap-icon-sharepoint .path2:before {
  content: "\ece6";
  margin-left: -1em;
  color: #16191d; }

.darkTheme .snap-icon-sharepoint .path3:before {
  content: "\ece7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sharepoint .path4:before {
  content: "\ece8";
  margin-left: -1em;
  color: #16191d; }

.darkTheme .snap-icon-sharepoint .path5:before {
  content: "\ece9";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sharepoint .path6:before {
  content: "\ecea";
  margin-left: -1em;
  color: #16191d; }

.darkTheme .snap-icon-sharepoint .path7:before {
  content: "\eceb";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sharepoint .path8:before {
  content: "\ecec";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sharepoint_dual .path1:before {
  content: "\eced";
  color: #3884ff; }

.darkTheme .snap-icon-sharepoint_dual .path2:before {
  content: "\ecee";
  margin-left: -1em;
  color: #16191d; }

.darkTheme .snap-icon-sharepoint_dual .path3:before {
  content: "\ecef";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-sharepoint_dual .path4:before {
  content: "\ecf0";
  margin-left: -1em;
  color: #16191d; }

.darkTheme .snap-icon-sharepoint_dual .path5:before {
  content: "\ecf1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-sharepoint_dual .path6:before {
  content: "\ecf2";
  margin-left: -1em;
  color: #16191d; }

.darkTheme .snap-icon-sharepoint_dual .path7:before {
  content: "\ecf3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sharepoint_dual .path8:before {
  content: "\ecf4";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sheet:before {
  content: "\ecf5";
  color: #e6eaf0; }

.darkTheme .snap-icon-sheet_dual .path1:before {
  content: "\ecf6";
  color: #e6eaf0; }

.darkTheme .snap-icon-sheet_dual .path2:before {
  content: "\ecf7";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-signature:before {
  content: "\ecf8";
  color: #e6eaf0; }

.darkTheme .snap-icon-signature_dual .path1:before {
  content: "\ecf9";
  color: #e6eaf0; }

.darkTheme .snap-icon-signature_dual .path2:before {
  content: "\ecfa";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-single_present:before {
  content: "\ecfb";
  color: #e6eaf0; }

.darkTheme .snap-icon-size_large:before {
  content: "\ecfc";
  color: #e6eaf0; }

.darkTheme .snap-icon-size_medium:before {
  content: "\ecfd";
  color: #e6eaf0; }

.darkTheme .snap-icon-size_small:before {
  content: "\ecfe";
  color: #e6eaf0; }

.darkTheme .snap-icon-skin_add:before {
  content: "\ecff";
  color: #e6eaf0; }

.darkTheme .snap-icon-skin_add_dual .path1:before {
  content: "\ed00";
  color: #e6eaf0; }

.darkTheme .snap-icon-skin_add_dual .path2:before {
  content: "\ed01";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-smiley:before {
  content: "\ed02";
  color: #e6eaf0; }

.darkTheme .snap-icon-smiley_filled:before {
  content: "\ed03";
  color: #e6eaf0; }

.darkTheme .snap-icon-SMS:before {
  content: "\ed04";
  color: #e6eaf0; }

.darkTheme .snap-icon-SMS_dual .path1:before {
  content: "\ed05";
  color: #e6eaf0; }

.darkTheme .snap-icon-SMS_dual .path2:before {
  content: "\ed06";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-SMS_dual .path3:before {
  content: "\ed07";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-Snap_IDE .path1:before {
  content: "\ed08";
  color: #454e59; }

.darkTheme .snap-icon-Snap_IDE .path2:before {
  content: "\ed09";
  margin-left: -1em;
  color: white; }

.darkTheme .snap-icon-Snap_IDE .path3:before {
  content: "\ed0a";
  margin-left: -1em;
  color: #ff5300; }

.darkTheme .snap-icon-Snap_logo_app_listing .path1:before {
  content: "\ed0b";
  color: #454e59; }

.darkTheme .snap-icon-Snap_logo_app_listing .path2:before {
  content: "\ed0c";
  margin-left: -1em;
  color: white; }

.darkTheme .snap-icon-Snap_logo_app_listing .path3:before {
  content: "\ed0d";
  margin-left: -1em;
  color: #ff5300; }

.darkTheme .snap-icon-soti_assist:before {
  content: "\ed0e";
  color: #e6eaf0; }

.darkTheme .snap-icon-SOTI_Connect:before {
  content: "\ed0f";
  color: #e6eaf0; }

.darkTheme .snap-icon-SOTI_identity:before {
  content: "\ed10";
  color: #e6eaf0; }

.darkTheme .snap-icon-SOTI_identity_accent .path1:before {
  content: "\ed11";
  color: #7445e3; }

.darkTheme .snap-icon-SOTI_identity_accent .path2:before {
  content: "\ed12";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-SOTI_Mobicontrol:before {
  content: "\ed13";
  color: #e6eaf0; }

.darkTheme .snap-icon-soti_one:before {
  content: "\ed14";
  color: #e6eaf0; }

.darkTheme .snap-icon-soti_one_dual .path1:before {
  content: "\ed15";
  color: #e6eaf0; }

.darkTheme .snap-icon-soti_one_dual .path2:before {
  content: "\ed16";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-soti_one_dual .path3:before {
  content: "\ed17";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-soti_one_dual .path4:before {
  content: "\ed18";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-soti_one_dual .path5:before {
  content: "\ed19";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-SOTI_XSIGHT:before {
  content: "\ed1a";
  color: #e6eaf0; }

.darkTheme .snap-icon-SOTI_XSIGHT_dual .path1:before {
  content: "\ed1b";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-SOTI_XSIGHT_dual .path2:before {
  content: "\ed1c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-SOTI_XSIGHT_dual .path3:before {
  content: "\ed1d";
  margin-left: -1em;
  color: #d70000; }

.darkTheme .snap-icon-SOTI_XSIGHT_dual .path4:before {
  content: "\ed1e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sound:before {
  content: "\ed1f";
  color: #e6eaf0; }

.darkTheme .snap-icon-sound_dual .path1:before {
  content: "\ed20";
  color: #e6eaf0; }

.darkTheme .snap-icon-sound_dual .path2:before {
  content: "\ed21";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-splash:before {
  content: "\ed22";
  color: #e6eaf0; }

.darkTheme .snap-icon-splash_dual .path1:before {
  content: "\ed23";
  color: #e6eaf0; }

.darkTheme .snap-icon-splash_dual .path2:before {
  content: "\ed24";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-standard_app .path1:before {
  content: "\ed25";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-standard_app .path2:before {
  content: "\ed26";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-standard_app .path3:before {
  content: "\ed27";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-standard_app .path4:before {
  content: "\ed28";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-standard_app .path5:before {
  content: "\ed29";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-standard_app_dual .path1:before {
  content: "\ed2a";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-standard_app_dual .path2:before {
  content: "\ed2b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-standard_app_dual .path3:before {
  content: "\ed2c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-standard_app_dual .path4:before {
  content: "\ed2d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-standard_app_dual .path5:before {
  content: "\ed2e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-star:before {
  content: "\ed2f";
  color: #e6eaf0; }

.darkTheme .snap-icon-star_filled:before {
  content: "\ed30";
  color: #e6eaf0; }

.darkTheme .snap-icon-storage:before {
  content: "\ed31";
  color: #e6eaf0; }

.darkTheme .snap-icon-storage_dual .path1:before {
  content: "\ed32";
  color: #e6eaf0; }

.darkTheme .snap-icon-storage_dual .path2:before {
  content: "\ed33";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-storage_dual .path3:before {
  content: "\ed34";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-storage_dual .path4:before {
  content: "\ed35";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-storage_dual .path5:before {
  content: "\ed36";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-storage_dual .path6:before {
  content: "\ed37";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sucess_filled_color .path1:before {
  content: "\ed38";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-sucess_filled_color .path2:before {
  content: "\ed39";
  margin-left: -1em;
  color: #5ab883; }

.darkTheme .snap-icon-summary:before {
  content: "\ed3a";
  color: #e6eaf0; }

.darkTheme .snap-icon-summary_dual .path1:before {
  content: "\ed3b";
  color: #e6eaf0; }

.darkTheme .snap-icon-summary_dual .path2:before {
  content: "\ed3c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-summary_dual .path3:before {
  content: "\ed3d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-summary_dual .path4:before {
  content: "\ed3e";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-summary_dual .path5:before {
  content: "\ed3f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-summary_dual .path6:before {
  content: "\ed40";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-summary_dual .path7:before {
  content: "\ed41";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-switch:before {
  content: "\ed42";
  color: #e6eaf0; }

.darkTheme .snap-icon-switch_dual .path1:before {
  content: "\ed43";
  color: #e6eaf0; }

.darkTheme .snap-icon-switch_dual .path2:before {
  content: "\ed44";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-sync_device:before {
  content: "\ed45";
  color: #e6eaf0; }

.darkTheme .snap-icon-sync_device_dual .path1:before {
  content: "\ed46";
  color: #3884ff; }

.darkTheme .snap-icon-sync_device_dual .path2:before {
  content: "\ed47";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-sync_device_dual .path3:before {
  content: "\ed48";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-sync_device_dual .path4:before {
  content: "\ed49";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-table:before {
  content: "\ed4a";
  color: #e6eaf0; }

.darkTheme .snap-icon-table_dual .path1:before {
  content: "\ed4b";
  color: #e6eaf0; }

.darkTheme .snap-icon-table_dual .path2:before {
  content: "\ed4c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-tablet_landscape:before {
  content: "\ed4d";
  color: #e6eaf0; }

.darkTheme .snap-icon-tablet_landscape_dual .path1:before {
  content: "\ed4e";
  color: #e6eaf0; }

.darkTheme .snap-icon-tablet_landscape_dual .path2:before {
  content: "\ed4f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-tablet_protrait:before {
  content: "\ed50";
  color: #e6eaf0; }

.darkTheme .snap-icon-tablet_protrait_dual .path1:before {
  content: "\ed51";
  color: #e6eaf0; }

.darkTheme .snap-icon-tablet_protrait_dual .path2:before {
  content: "\ed52";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-target .path1:before {
  content: "\ed53";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-target .path2:before {
  content: "\ed54";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-target_dual .path1:before {
  content: "\ed55";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-target_dual .path2:before {
  content: "\ed56";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-target_dual .path3:before {
  content: "\ed57";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-target_dual .path4:before {
  content: "\ed58";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-target_dual .path5:before {
  content: "\ed59";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-target_dual .path6:before {
  content: "\ed5a";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text .path1:before {
  content: "\ed5b";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-text .path2:before {
  content: "\ed5c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-text_bold:before {
  content: "\ed5d";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_bold_dual .path1:before {
  content: "\ed5e";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_bold_dual .path2:before {
  content: "\ed5f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_collapsed:before {
  content: "\ed60";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_collapsed_dual .path1:before {
  content: "\ed61";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_collapsed_dual .path2:before {
  content: "\ed62";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_color:before {
  content: "\ed63";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_color_dual .path1:before {
  content: "\ed64";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_color_dual .path2:before {
  content: "\ed65";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_dual .path1:before {
  content: "\ed66";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-text_dual .path2:before {
  content: "\ed67";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-text_dual .path3:before {
  content: "\ed68";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_highlight:before {
  content: "\ed69";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_highlight_dual .path1:before {
  content: "\ed6a";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_highlight_dual .path2:before {
  content: "\ed6b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-text_highlight_dual .path3:before {
  content: "\ed6c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_italic:before {
  content: "\ed6d";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_italic_dual .path1:before {
  content: "\ed6e";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_italic_dual .path2:before {
  content: "\ed6f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_line_spacing:before {
  content: "\ed70";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_line_spacing_dual .path1:before {
  content: "\ed71";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_line_spacing_dual .path2:before {
  content: "\ed72";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-text_line_spacing_dual .path3:before {
  content: "\ed73";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-text_line_spacing_dual .path4:before {
  content: "\ed74";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-text_line_spacing_dual .path5:before {
  content: "\ed75";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_line_spacing_dual .path6:before {
  content: "\ed76";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_small_caps:before {
  content: "\ed77";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_small_caps_dual .path1:before {
  content: "\ed78";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_small_caps_dual .path2:before {
  content: "\ed79";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_subscript:before {
  content: "\ed7a";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_subscript_alt:before {
  content: "\ed7b";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_subscript_alt_dual .path1:before {
  content: "\ed7c";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_subscript_alt_dual .path2:before {
  content: "\ed7d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_subscript_dual .path1:before {
  content: "\ed7e";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_subscript_dual .path2:before {
  content: "\ed7f";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-text_underline:before {
  content: "\ed80";
  color: #e6eaf0; }

.darkTheme .snap-icon-text_underline_dual .path1:before {
  content: "\ed81";
  color: #3884ff; }

.darkTheme .snap-icon-text_underline_dual .path2:before {
  content: "\ed82";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_center:before {
  content: "\ed83";
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_center_dual .path1:before {
  content: "\ed84";
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_center_dual .path2:before {
  content: "\ed85";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_center_dual .path3:before {
  content: "\ed86";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-textalign_center_dual .path4:before {
  content: "\ed87";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-textalign_justify:before {
  content: "\ed88";
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_justify_dual .path1:before {
  content: "\ed89";
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_justify_dual .path2:before {
  content: "\ed8a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_justify_dual .path3:before {
  content: "\ed8b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-textalign_justify_dual .path4:before {
  content: "\ed8c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-textalign_left:before {
  content: "\ed8d";
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_left_dual .path1:before {
  content: "\ed8e";
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_left_dual .path2:before {
  content: "\ed8f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_left_dual .path3:before {
  content: "\ed90";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-textalign_left_dual .path4:before {
  content: "\ed91";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-textalign_right:before {
  content: "\ed92";
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_right_dual .path1:before {
  content: "\ed93";
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_right_dual .path2:before {
  content: "\ed94";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-textalign_right_dual .path3:before {
  content: "\ed95";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-textalign_right_dual .path4:before {
  content: "\ed96";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-textbox:before {
  content: "\ed97";
  color: #e6eaf0; }

.darkTheme .snap-icon-textbox_dual .path1:before {
  content: "\ed98";
  color: #e6eaf0; }

.darkTheme .snap-icon-textbox_dual .path2:before {
  content: "\ed99";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-theme .path1:before {
  content: "\ed9a";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-theme .path2:before {
  content: "\ed9b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-theme .path3:before {
  content: "\ed9c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-theme .path4:before {
  content: "\ed9d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-theme .path5:before {
  content: "\ed9e";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-theme .path6:before {
  content: "\ed9f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-theme .path7:before {
  content: "\eda0";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-theme_dual .path1:before {
  content: "\eda1";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-theme_dual .path2:before {
  content: "\eda2";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-theme_dual .path3:before {
  content: "\eda3";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-theme_dual .path4:before {
  content: "\eda4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-theme_dual .path5:before {
  content: "\eda5";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-theme_dual .path6:before {
  content: "\eda6";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-theme_dual .path7:before {
  content: "\eda7";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-three_preset_1:before {
  content: "\eda8";
  color: #e6eaf0; }

.darkTheme .snap-icon-three_preset_2:before {
  content: "\eda9";
  color: #e6eaf0; }

.darkTheme .snap-icon-three_preset_3:before {
  content: "\edaa";
  color: #e6eaf0; }

.darkTheme .snap-icon-three_preset_4:before {
  content: "\edab";
  color: #e6eaf0; }

.darkTheme .snap-icon-three_preset_5:before {
  content: "\edac";
  color: #e6eaf0; }

.darkTheme .snap-icon-three_preset_6:before {
  content: "\edad";
  color: #e6eaf0; }

.darkTheme .snap-icon-three_preset_7:before {
  content: "\edae";
  color: #e6eaf0; }

.darkTheme .snap-icon-three_preset_8:before {
  content: "\edaf";
  color: #e6eaf0; }

.darkTheme .snap-icon-three_preset_9:before {
  content: "\edb0";
  color: #e6eaf0; }

.darkTheme .snap-icon-thumbs_up:before {
  content: "\edb1";
  color: #e6eaf0; }

.darkTheme .snap-icon-thumbs_up_filled:before {
  content: "\edb2";
  color: #e6eaf0; }

.darkTheme .snap-icon-time_picker:before {
  content: "\edb3";
  color: #e6eaf0; }

.darkTheme .snap-icon-time_picker_dual .path1:before {
  content: "\edb4";
  color: #e6eaf0; }

.darkTheme .snap-icon-time_picker_dual .path2:before {
  content: "\edb5";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-timer .path1:before {
  content: "\edb6";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-timer .path2:before {
  content: "\edb7";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-timer .path3:before {
  content: "\edb8";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-timer .path4:before {
  content: "\edb9";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-timer_dual .path1:before {
  content: "\edba";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-timer_dual .path2:before {
  content: "\edbb";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-timer_dual .path3:before {
  content: "\edbc";
  margin-left: -1.0419921875em;
  color: #e6eaf0; }

.darkTheme .snap-icon-timer_dual .path4:before {
  content: "\edbd";
  margin-left: -1.0419921875em;
  color: #3884ff; }

.darkTheme .snap-icon-timer-ide-icon .path1:before {
  content: "\edbe";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-timer-ide-icon .path2:before {
  content: "\edbf";
  margin-left: -1em;
  color: #092442; }

.darkTheme .snap-icon-timer-ide-icon .path3:before {
  content: "\edc0";
  margin-left: -1em;
  color: #092442; }

.darkTheme .snap-icon-timer-ide-icon .path4:before {
  content: "\edc1";
  margin-left: -1em;
  color: #092442; }

.darkTheme .snap-icon-track_request:before {
  content: "\edc2";
  color: #e6eaf0; }

.darkTheme .snap-icon-track_request_dual .path1:before {
  content: "\edc3";
  color: #e6eaf0; }

.darkTheme .snap-icon-track_request_dual .path2:before {
  content: "\edc4";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unchecked:before {
  content: "\edc5";
  color: #e6eaf0; }

.darkTheme .snap-icon-undo:before {
  content: "\edc6";
  color: #e6eaf0; }

.darkTheme .snap-icon-undo_dual .path1:before {
  content: "\edc7";
  color: #e6eaf0; }

.darkTheme .snap-icon-undo_dual .path2:before {
  content: "\edc8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unenroll .path1:before {
  content: "\edc9";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-unenroll .path2:before {
  content: "\edca";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-unenroll .path3:before {
  content: "\edcb";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-unenroll_color .path1:before {
  content: "\edcc";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-unenroll_color .path2:before {
  content: "\edcd";
  margin-left: -1em;
  color: #e06651; }

.darkTheme .snap-icon-unenroll_color .path3:before {
  content: "\edce";
  margin-left: -1em;
  color: #e06651; }

.darkTheme .snap-icon-unenroll_dual .path1:before {
  content: "\edcf";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-unenroll_dual .path2:before {
  content: "\edd0";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-unenroll_dual .path3:before {
  content: "\edd1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unlink:before {
  content: "\edd2";
  color: #e6eaf0; }

.darkTheme .snap-icon-unlink_dual .path1:before {
  content: "\edd3";
  color: #e6eaf0; }

.darkTheme .snap-icon-unlink_dual .path2:before {
  content: "\edd4";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-unlink_dual .path3:before {
  content: "\edd5";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unlink_dual .path4:before {
  content: "\edd6";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unlink_dual .path5:before {
  content: "\edd7";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unlink_dual .path6:before {
  content: "\edd8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unlink_dual .path7:before {
  content: "\edd9";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unlink_dual .path8:before {
  content: "\edda";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-unlocked:before {
  content: "\eddb";
  color: #e6eaf0; }

.darkTheme .snap-icon-user:before {
  content: "\eddc";
  color: #e6eaf0; }

.darkTheme .snap-icon-user_approval:before {
  content: "\eddd";
  color: #e6eaf0; }

.darkTheme .snap-icon-user_approval_dual .path1:before {
  content: "\edde";
  color: #e6eaf0; }

.darkTheme .snap-icon-user_approval_dual .path2:before {
  content: "\eddf";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-user_approval_dual .path3:before {
  content: "\ede0";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-user_avatar:before {
  content: "\ede1";
  color: #e6eaf0; }

.darkTheme .snap-icon-user_avatar_dual .path1:before {
  content: "\ede2";
  color: #3884ff; }

.darkTheme .snap-icon-user_avatar_dual .path2:before {
  content: "\ede3";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-user_dual .path1:before {
  content: "\ede4";
  color: #e6eaf0; }

.darkTheme .snap-icon-user_dual .path2:before {
  content: "\ede5";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-user_dual .path3:before {
  content: "\ede6";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-user_initial_select .path1:before {
  content: "\ede7";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-user_initial_select .path2:before {
  content: "\ede8";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-user_initial_select .path3:before {
  content: "\ede9";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-user_initial_selected .path1:before {
  content: "\edea";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-user_initial_selected .path2:before {
  content: "\edeb";
  margin-left: -1em;
  color: #2c333a; }

.darkTheme .snap-icon-user_initial_selected .path3:before {
  content: "\edec";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-user_list:before {
  content: "\eded";
  color: #e6eaf0; }

.darkTheme .snap-icon-user_list_dual .path1:before {
  content: "\edee";
  color: #e6eaf0; }

.darkTheme .snap-icon-user_list_dual .path2:before {
  content: "\edef";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-user_list_dual .path3:before {
  content: "\edf0";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-user_list_dual .path4:before {
  content: "\edf1";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-user_list_dual .path5:before {
  content: "\edf2";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-users_empty_large:before {
  content: "\edf3";
  color: #e6eaf0; }

.darkTheme .snap-icon-video:before {
  content: "\edf4";
  color: #e6eaf0; }

.darkTheme .snap-icon-video_dual .path1:before {
  content: "\edf5";
  color: #e6eaf0; }

.darkTheme .snap-icon-video_dual .path2:before {
  content: "\edf6";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-video_dual .path3:before {
  content: "\edf7";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-video_tutorial:before {
  content: "\edf8";
  color: #e6eaf0; }

.darkTheme .snap-icon-video_tutorial_dual .path1:before {
  content: "\edf9";
  color: #3884ff; }

.darkTheme .snap-icon-video_tutorial_dual .path2:before {
  content: "\edfa";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-view:before {
  content: "\edfb";
  color: #e6eaf0; }

.darkTheme .snap-icon-view_logs:before {
  content: "\edfc";
  color: #e6eaf0; }

.darkTheme .snap-icon-view_logs_dual .path1:before {
  content: "\edfd";
  color: #e6eaf0; }

.darkTheme .snap-icon-view_logs_dual .path2:before {
  content: "\edfe";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-view_logs_dual .path3:before {
  content: "\edff";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-view_logs_dual .path4:before {
  content: "\ee00";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-view_logs_dual .path5:before {
  content: "\ee01";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-view_logs_dual .path6:before {
  content: "\ee02";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-view_off:before {
  content: "\ee03";
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_active .path1:before {
  content: "\ee04";
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_active .path2:before {
  content: "\ee05";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_active .path3:before {
  content: "\ee06";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_active .path4:before {
  content: "\ee07";
  margin-left: -1em;
  color: #e87a66; }

.darkTheme .snap-icon-warning_active_dual .path1:before {
  content: "\ee08";
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_active_dual .path2:before {
  content: "\ee09";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_active_dual .path3:before {
  content: "\ee0a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_active_dual .path4:before {
  content: "\ee0b";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-warning_active_dual .path5:before {
  content: "\ee0c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-warning_active_dual .path6:before {
  content: "\ee0d";
  margin-left: -1em;
  color: #e87a66; }

.darkTheme .snap-icon-warning_alt_filled:before {
  content: "\ee0e";
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_default:before {
  content: "\ee0f";
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_default_dual .path1:before {
  content: "\ee10";
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_default_dual .path2:before {
  content: "\ee11";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_default_dual .path3:before {
  content: "\ee12";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_default_dual .path4:before {
  content: "\ee13";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-warning_default_dual .path5:before {
  content: "\ee14";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-warning_filled:before {
  content: "\ee15";
  color: #e6eaf0; }

.darkTheme .snap-icon-warning_filled_color .path1:before {
  content: "\ee16";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-warning_filled_color .path2:before {
  content: "\ee17";
  margin-left: -1em;
  color: #cf8a23; }

.darkTheme .snap-icon-warning_filled_color .path3:before {
  content: "\ee18";
  margin-left: -1em;
  color: #cf8a23; }

.darkTheme .snap-icon-webhook:before {
  content: "\ee19";
  color: #e6eaf0; }

.darkTheme .snap-icon-webhook_dual .path1:before {
  content: "\ee1a";
  color: #e6eaf0; }

.darkTheme .snap-icon-webhook_dual .path2:before {
  content: "\ee1b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-webhook_dual .path3:before {
  content: "\ee1c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-webhook_dual .path4:before {
  content: "\ee1d";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-webpage:before {
  content: "\ee1e";
  color: #e6eaf0; }

.darkTheme .snap-icon-webpage_dual .path1:before {
  content: "\ee1f";
  color: #3884ff; }

.darkTheme .snap-icon-webpage_dual .path2:before {
  content: "\ee20";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-webpage_dual .path3:before {
  content: "\ee21";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-webpage_dual .path4:before {
  content: "\ee22";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-webpage_dual .path5:before {
  content: "\ee23";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-wf_expand:before {
  content: "\ee24";
  color: #e6eaf0; }

.darkTheme .snap-icon-wf_expand_dual .path1:before {
  content: "\ee25";
  color: #3884ff; }

.darkTheme .snap-icon-wf_expand_dual .path2:before {
  content: "\ee26";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value .path1:before {
  content: "\ee27";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-widget_value .path2:before {
  content: "\ee28";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value .path3:before {
  content: "\ee29";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value .path4:before {
  content: "\ee2a";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value .path5:before {
  content: "\ee2b";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value .path6:before {
  content: "\ee2c";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value .path7:before {
  content: "\ee2d";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value_dual .path1:before {
  content: "\ee2e";
  color: white;
  opacity: 0.0100; }

.darkTheme .snap-icon-widget_value_dual .path2:before {
  content: "\ee2f";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value_dual .path3:before {
  content: "\ee30";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-widget_value_dual .path4:before {
  content: "\ee31";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-widget_value_dual .path5:before {
  content: "\ee32";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-widget_value_dual .path6:before {
  content: "\ee33";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-widget_value_dual .path7:before {
  content: "\ee34";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-work_filled:before {
  content: "\ee35";
  color: #e6eaf0; }

.darkTheme .snap-icon-work_filled_color:before {
  content: "\ee36";
  color: #5eafc1; }

.darkTheme .snap-icon-workflow:before {
  content: "\ee37";
  color: #e6eaf0; }

.darkTheme .snap-icon-workflow_dual .path1:before {
  content: "\ee38";
  color: #e6eaf0; }

.darkTheme .snap-icon-workflow_dual .path2:before {
  content: "\ee39";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-youtube:before {
  content: "\ee3a";
  color: #e6eaf0; }

.darkTheme .snap-icon-youtube_dual .path1:before {
  content: "\ee3b";
  color: #e6eaf0; }

.darkTheme .snap-icon-youtube_dual .path2:before {
  content: "\ee3c";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-Zapier:before {
  content: "\ee3d";
  color: #e6eaf0; }

.darkTheme .snap-icon-zip:before {
  content: "\ee3e";
  color: #e6eaf0; }

.darkTheme .snap-icon-zip_dual .path1:before {
  content: "\ee3f";
  color: #e6eaf0; }

.darkTheme .snap-icon-zip_dual .path2:before {
  content: "\ee40";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-zip_dual .path3:before {
  content: "\ee41";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-zip_dual .path4:before {
  content: "\ee42";
  margin-left: -1em;
  color: #3884ff; }

.darkTheme .snap-icon-zoom_in:before {
  content: "\ee43";
  color: #e6eaf0; }

.darkTheme .snap-icon-zoom_in_dual .path1:before {
  content: "\ee44";
  color: #3884ff; }

.darkTheme .snap-icon-zoom_in_dual .path2:before {
  content: "\ee45";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-Zoom_out:before {
  content: "\ee46";
  color: #e6eaf0; }

.darkTheme .snap-icon-Zoom_out_dual .path1:before {
  content: "\ee47";
  color: #e6eaf0; }

.darkTheme .snap-icon-Zoom_out_dual .path2:before {
  content: "\ee48";
  margin-left: -1em;
  color: #e6eaf0; }

.darkTheme .snap-icon-Zoom_out_dual .path3:before {
  content: "\ee49";
  margin-left: -1em;
  color: #3884ff; }

