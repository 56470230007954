.quill-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin-bottom: 10px;

  .quill {
    background-color: #2C333A;
  }
  .ql-toolbar.ql-snow {
    border-color: #2C333A;
    background-color: #21272B;
    flex-shrink: 0;
  }  
  .ql-container.ql-snow {
    border: none;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .ql-toolbar.ql-snow .ql-picker-label {
    color: #E6EAF0;
  }
  .ql-toolbar.ql-snow .ql-stroke {
    stroke: #E6EAF0;
  }
  .ql-toolbar.ql-snow .ql-fill {
    fill: #E6EAF0;
  }
  .ql-editor {
    color: #E6EAF0;
    flex: 1;
    overflow-y: auto;
  }
  .ql-editor.ql-blank::before {
    color: #E6EAF0;
    opacity: 0.6;
  }
  .ql-tooltip {
    background-color: #21272B; 
    color: #E6EAF0; 
  }
}

.cancel-button {
background-color: #16191D;
border: none;
color: #E6EAF0;
font-size: 14;
cursor: pointer;
}

.cancel-button:hover {
color: white;
}

.save-button {
background-color: #596573;
border: none;
border-radius: 3px;
color: #E6EAF0;
padding: 6px 15px;
font-size: 14;
cursor: pointer;
}

.save-button-saving {
background-color: #004ECC;
border: none;
border-radius: 3px;
color: #E6EAF0;
padding: 6px 15px;
font-size: 14;
cursor: pointer;
}

.save-button-saving:hover {
background-color: #0066FF;
}

.popup-modal {
  z-index: 1;
  align-items: center;
  justify-content: center;
  background-color: #16191D;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.container-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #E6EAF0;
  box-sizing: border-box;
}

.popup-header {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  padding: 15px;
}

.header-div {
  flex-shrink: 0;
  margin-left: 15px;
}

.close-icon {
  cursor: pointer;
  color: #E6EAF0;
  padding: 5px;
}

.quill-container-div {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: calc(100% - 60px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.react-quill {
  height: calc(100% - 42px);
  display: flex;
  flex-direction: column;
  cursor: text;
}

.label-div {
  display: flex;
  margin-top: 15px;
}

.popup-label {
  font-size: 14px;
  color: #E6EAF0;
  margin-top: 2px;
}

.editing-div {
  display: flex;
  margin-left: auto;
  gap: 8px;
}

.custom-toolbar {
  &:not(.has-selection) {
    .ql-link {
      display: none !important;
    }
  }
  
  &.has-selection {
    .ql-link {
      display: inline-block !important;
    }
  }
}
